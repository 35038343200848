import { createSlice } from "@reduxjs/toolkit";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { AdverseEventsFilter } from "../../types/frontendTypes";
import { Models } from "../../../localComponents/types/models";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { OrderDirection } from "../../../localComponents/types/enums";

export interface AdverseEventsState extends RemoteState<Models.AdverseEvent,AdverseEventsFilter> {
}

const initialState: AdverseEventsState = {
    ...defaultRemoteInitialState(),
    filter: {}
};
export const adverseEventsSlice = createSlice({
    name: 'adverseEvents',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

const adverseEventQueryBuilder = (state: RootState, sliceState: AdverseEventsState) => {
    const queryParams: { [key:string]: string } = {
        count: '20',
        orderBy: 'time',
        orderDirection: OrderDirection.Descending
    };
    return queryParams;
}
const adverseEventFilterComparer = (f1: AdverseEventsFilter | undefined, f2: AdverseEventsFilter | undefined) => {
    if(!f1 || !f2) {
        if(!f1 && !f2) {
            return true;
        }
        return false;
    }
    return f1?.searchText === f2?.searchText;
}
export const adverseEventsActions = {
    ...createRestApiActions(
        'adverseEvents',
        adverseEventsSlice.actions,
        state => state.adverseEvents,
        adverseEventQueryBuilder,
        adverseEventFilterComparer
    ),
};
export const adverseEventsSelectors = {

};