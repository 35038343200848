import { createSlice } from "@reduxjs/toolkit";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { postActionBuilder } from "../../../sharedHealthComponents/redux/helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultPersonDataReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createPersonDataApiActions } from "../../../sharedHealthComponents/redux/helpers/HealthRecordEntrySliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { PersonDataRemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { createPersonDataSelectors } from "../helpers/HealthRecordEntrySliceSelectors";

export interface PatientChecklistAnswersState extends PersonDataRemoteState<ViewModels.HealthRecordEntries.PatientChecklistAnswerViewModel, {}> {}
const initialState: PatientChecklistAnswersState = {
    ...defaultRemoteInitialState(),
}
export const patientChecklistAnswersSlice = createSlice({
    name: 'patientChecklistAnswers',
    initialState: initialState,
    reducers: {
        ...createDefaultPersonDataReducers(initialState),
    }
});

const patientChecklistAnswersQueryBuilder = (state: RootState, sliceState: PatientChecklistAnswersState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, patientChecklistAnswersFilterComparer);
    //const filter = sliceState.filter;
    return queryParams;
}
const patientChecklistAnswersFilterComparer = (f1?: {}, f2?: {}) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return true;
}
export interface DeployChecklistArguments {
    checklistId: string;
}
export const patientChecklistAnswersActions = {
    ...createPersonDataApiActions(
        'patientChecklistAnswers',
        patientChecklistAnswersSlice.actions,
        state => state.patientChecklistAnswers,
        patientChecklistAnswersQueryBuilder,
        patientChecklistAnswersFilterComparer
    ),
    deployForPatient: postActionBuilder(
        (args: DeployChecklistArguments) => `api/checklists/${args.checklistId}/deploy`,
        () => resolveText("Checklist_CouldNotDeploy"),
        patientChecklistAnswersSlice.actions.setIsSubmitting,
        (dispatch,response,_) => dispatch(patientChecklistAnswersSlice.actions.addOrUpdateItem(response))
    ),
};
export const patientChecklistAnswersSelectors = {
    ...createPersonDataSelectors(state => state.patientChecklistAnswers),
};
