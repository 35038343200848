import { createSelector, createSlice } from "@reduxjs/toolkit";
import { EventStatus, HealthRecordEntryType } from "../../../localComponents/types/enums";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { HealthRecordEventFilter } from "../../types/frontendTypes";
import { PersonDataRemoteState } from "../../types/reduxInterfaces";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { isAfter } from "date-fns";

interface MedicalProceduresState extends PersonDataRemoteState<ViewModels.HealthRecordEntries.MedicalProcedureViewModel,HealthRecordEventFilter> {
}

const initialState: MedicalProceduresState = {
    ...defaultRemoteInitialState(),
}

export const medicalProceduresSlice = createSlice({
    name: 'medicalProcedures',
    initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState),
    }
});

export interface ChangeProcedureStatusArgs {
    itemId: string;
    newStatus: EventStatus;
}
export const medicalProceduresActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.Procedure,
        'medicalProcedures', 
        medicalProceduresSlice.actions, 
        state => state.medicalProcedures,
        healthRecordEntryQueryBuilder,
        areHealthRecordEntryFiltersEqual
    ),
    changeStatus: postActionBuilder(
        (args: ChangeProcedureStatusArgs) => `api/medicalProcedures/${args.itemId}/status/${args.newStatus}`,
        () => resolveText("MedicalProcedure_CouldNotChangeStatus"),
        medicalProceduresSlice.actions.setIsSubmitting,
        (dispatch,response,_) => dispatch(medicalProceduresSlice.actions.addOrUpdateItem(response))
    ),
};
export interface SelectUpcomingMedicalProceduresArgs {
    states: EventStatus[];
    now: Date;
}
export const medicalProceduresSelectors = {
    ...createHealthRecordEntrySelectors(state => state.medicalProcedures),
    createSelectUpcoming: () => createSelector(
        (state: RootState) => state.medicalProcedures.items,
        (_: RootState, args: SelectUpcomingMedicalProceduresArgs) => args.states,
        (_: RootState, args: SelectUpcomingMedicalProceduresArgs) => args.now,
        (items, states, now) => items
            .filter(x => states.includes(x.status) || isAfter(new Date(x.timestamp), now))
    ),
};