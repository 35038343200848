import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../localComponents/redux/store/healthRecordStore";
import { defaultRemoteInitialState } from "../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../sharedHealthComponents/types/reduxInterfaces";
import { ChecklistFilter } from "../types/frontendTypes";
import { ViewModels } from "../../localComponents/types/viewModels";
import { createDefaultGenericItemSelectors } from "../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

export interface ChecklistsState extends RemoteState<ViewModels.ChecklistViewModel,ChecklistFilter> {
}

const initialState: ChecklistsState = {
    ...defaultRemoteInitialState(),
}
export const checklistsSlice = createSlice({
    name: 'checklists',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

const checklistsQueryBuilder = (state: RootState, sliceState: ChecklistsState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, checklistsFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.searchText) {
            queryParams.push({ key: 'searchText', value: filter.searchText });
        }
        if(filter.language) {
            queryParams.push({ key: 'language', value: filter.language });
        }
        if(filter.disease) {
            queryParams.push({ key: 'diseaseClassification', value: filter.disease.classification });
            queryParams.push({ key: 'diseaseConceptId', value: filter.disease.conceptId });
        }
    }
    return queryParams;
}
const checklistsFilterComparer = (f1?: ChecklistFilter, f2?: ChecklistFilter) => {
    if(!f1 && !f2) {
        return true;
    }
    if(!f1 || !f2) {
        return false;
    }
    return f1.searchText === f2.searchText
        && f1.language === f2.language
        && f1.disease?.classification === f2.disease?.classification
        && f1.disease?.conceptId === f2.disease?.conceptId;
}

export const checklistsActions = {
    ...createRestApiActions(
        'checklists',
        checklistsSlice.actions,
        state => state.checklists,
        checklistsQueryBuilder,
        checklistsFilterComparer
    ),
};
export const checklistSelectors = {
    ...createDefaultGenericItemSelectors(state => state.checklists),
}