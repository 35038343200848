import { createSlice } from "@reduxjs/toolkit";
import { HealthRecordEntryType } from "../../../localComponents/types/enums";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { HealthRecordEventFilter } from "../../types/frontendTypes";
import { PersonDataRemoteState } from "../../types/reduxInterfaces";
import { loadPersonDataActionBuilder } from "../helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { QueryParameter } from "../../../sharedCommonComponents/types/frontendTypes";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";

interface ObservationsState extends PersonDataRemoteState<ViewModels.HealthRecordEntries.QuantitativeDiagnosticTestResultViewModel,HealthRecordEventFilter> {
}
const initialState: ObservationsState = {
    ...defaultRemoteInitialState(),
}
export const observationsSlice = createSlice({
    name: 'observations',
    initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState),
    }
});

export const observationsActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.TestResult,
        'testResults', 
        observationsSlice.actions, 
        state => state.observations,
        healthRecordEntryQueryBuilder,
        areHealthRecordEntryFiltersEqual
    ),
    loadItems: loadPersonDataActionBuilder(
        args => `api/persons/${args!.personId}/observations`,
        state => {
            const observationLoincCodes = state.healthRecords.viewSettings.observationLoincCodes;
            const queryParameters: QueryParameter[] = observationLoincCodes.map(x => ({ key: 'loincCodes', value: x.conceptId }));
            return queryParameters;
        },
        areHealthRecordEntryFiltersEqual,
        () => resolveText("HealthRecordEntries_CouldNotLoad"),
        observationsSlice.actions.setCurrentPersonId,
        observationsSlice.actions.setIsLoading,
        observationsSlice.actions.setItems,
        observationsSlice.actions.addOrUpdateItems,
        observationsSlice.actions.setHasMoreItems,
        observationsSlice.actions.setLastUsedFilter,
        state => state.observations
    )
};
export const observationsSelectors = {
    ...createHealthRecordEntrySelectors(state => state.observations),
};