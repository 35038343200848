import { Nav, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import { ToolsDropdownMenu } from './ToolsDropdownMenu';

interface AdminMenuProps {}

export const AdminMenu = (props: AdminMenuProps) => {

    const navigate = useNavigate();
    return (
        <Nav>
            <NavDropdown title={resolveText("Menu_AdminActions")}>
                <NavDropdown.Item onClick={() => navigate(`/accounts`)}>{resolveText("Menu_Accounts")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/institutions`)}>{resolveText("Menu_Institutions")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/studies`)}>{resolveText("Menu_Studies")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/checklists`)}>{resolveText("Menu_Checklists")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/questionnaires")}>{resolveText("Menu_Questionnaires")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/equipmentmeasurements`)}>{resolveText("Menu_EquipmentMeasurements")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/drugs`)}>{resolveText("Menu_Drugs")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/print-templates`)}>{resolveText("Menu_PrintTemplates")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/publicdataapproval`)}>{resolveText("Menu_PublicDataApproval")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/isitbroken/configuration`)}>{resolveText("Menu_IsItBrokenConfiguration")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/feedback`)}>{resolveText("Menu_Feedback")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={resolveText("Menu_Billing")}>
                <NavDropdown.Item onClick={() => navigate(`/shop/orders`)}>{resolveText("Menu_Orders")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/shop/vouchers`)}>{resolveText("Menu_Vouchers")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/shop/items`)}>{resolveText("Menu_Shopitems")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/shop/shippingmethods`)}>{resolveText("Menu_ShippingMethods")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/emergencycard-qr-generator`)}>Emergency card QR generator</NavDropdown.Item>
            </NavDropdown>
            <ToolsDropdownMenu />
        </Nav>
    );

}