import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { Models } from "../../../localComponents/types/models";
import { PersonsFilter } from "../../types/frontendTypes";
import { RemoteState } from "../../types/reduxInterfaces";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../helpers/DefaultReducers";
import { createRestApiActions } from "../helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../helpers/QueryBuilders";
import { postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { createDefaultGenericItemSelectors } from "../helpers/GenericSliceSelectors";

interface PersonsState extends RemoteState<Models.Individuals.Person, PersonsFilter> {
}

const initialState: PersonsState = {
    ...defaultRemoteInitialState(),
};

export const personsSlice = createSlice({
    name: 'persons',
    initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

const personQueryParameterBuilder = (state: RootState, sliceState: PersonsState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, personFilterComparer);
    const filter = sliceState.filter;
    if(filter.searchText) {
        queryParams.push({ key: 'searchText', value: filter.searchText });
    }
    return queryParams;
}
const personFilterComparer = (f1?: PersonsFilter, f2?: PersonsFilter) => {
    if(!f1 && !f2) {
        return true;
    }
    if(!f1 || !f2) {
        return false;
    }
    return f1.searchText === f2.searchText;
}
interface DeclareDeceasedArgs {
    personId: string;
}
export const personsActions = {
    ...createRestApiActions(
        'persons',
        personsSlice.actions,
        state => state.persons,
        personQueryParameterBuilder,
        personFilterComparer
    ),
    declareDeceased: postActionBuilder(
        (args: DeclareDeceasedArgs) => `api/persons/${args.personId}/mark-deceased`,
        () => resolveText("Person_CouldNotDeclareDeceased"),
        personsSlice.actions.setIsSubmitting,
        (dispatch,response,_) => dispatch(personsSlice.actions.addOrUpdateItem(response))
    ),
};
export const personsSelectors = {
    ...createDefaultGenericItemSelectors(state => state.persons),
};