import { createSlice } from "@reduxjs/toolkit";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { Models } from "../../types/models";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { RootState } from "../store/healthRecordStore";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";

export interface FeedbackState extends RemoteState<Models.Feedback,{}> {}
const initialState: FeedbackState = {
    ...defaultRemoteInitialState(),
}
export const feedbackSlice = createSlice({
    name: 'feedback',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});
const feedbackFilterComparer = (f1?: {}, f2?: {}) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return true;
}
const feedbackQueryBuilder = (state: RootState, sliceState: FeedbackState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, feedbackFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        // TODO
    }
    return queryParams;
}
export const feedbackActions = {
    ...createRestApiActions(
        'feedback',
        feedbackSlice.actions,
        state => state.feedback,
        feedbackQueryBuilder,
        feedbackFilterComparer
    ),
}