import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { HealthRecordEntryType } from "../../../localComponents/types/enums";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { IsolationFilter } from "../../types/frontendTypes";
import { PersonDataRemoteState } from "../../types/reduxInterfaces";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";

export interface IsolationsState extends PersonDataRemoteState<ViewModels.HealthRecordEntries.IsolationViewModel,IsolationFilter> {
}
const initialState: IsolationsState = {
    ...defaultRemoteInitialState(),
}
export const isolationsSlice = createSlice({
    name: 'isolations',
    initialState: initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState),
    }
});

const isolationsQueryBuilder = (state: RootState, sliceState: IsolationsState) => {
    const queryParams = healthRecordEntryQueryBuilder(state, sliceState, isolationsFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        queryParams.push({ key: 'onlyActive', value: filter.onlyActive ? 'true' : 'false' });
    }
    return queryParams;
}
const isolationsFilterComparer = (f1?: IsolationFilter, f2?: IsolationFilter) => {
    if(!areHealthRecordEntryFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.onlyActive === f2!.onlyActive;
}
export const isolationsActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.Isolation,
        'isolations',
        isolationsSlice.actions,
        state => state.isolations,
        isolationsQueryBuilder,
        isolationsFilterComparer
    ),
};
export const isolationsSelectors = {
    ...createHealthRecordEntrySelectors(state => state.isolations),
};