import { useLocation } from "react-router-dom";
import { HealthRecordSearch } from "./HealthRecordSearch";

interface HealthRecordSearchSelfcontainedProps {}

export const HealthRecordSearchSelfcontained = (props: HealthRecordSearchSelfcontainedProps) => {

    const location = useLocation();
    const isHealthRecordPage = /^\/healthrecord\/[a-zA-Z0-9-]+$/.test(location.pathname);
    const personId = isHealthRecordPage ? /\/healthrecord\/([a-zA-Z0-9-]+)/.exec(location.pathname)![1] : undefined;

    if(!isHealthRecordPage) {
        return null;
    }

    return (<HealthRecordSearch
        personId={personId!}
    />);

}