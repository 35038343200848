import { PropsWithChildren, Suspense } from 'react';
import { Navbar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { LoadingAlert } from '../../sharedCommonComponents/components/LoadingAlert';
import { NoUserMenu } from './Menus/NoUserMenu';
import { TestSystemBanner } from './TestSystemBanner';

interface NoUserLayoutProps {
    isLoggingIn: boolean;
    onLogOut: () => void;
}

export const NoUserLayout = (props: PropsWithChildren<NoUserLayoutProps>) => {

    const navigate = useNavigate();
    return (
    <>
        <ToastContainer
            theme='colored'
        />
        <TestSystemBanner />
        <Navbar bg="secondary" variant="dark" expand="md">
            <Navbar.Brand className='ms-2 clickable' onClick={() => navigate('/')}>
                <img src="/img/shehrd-text-logo-white.png" alt="shEHRd logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <NoUserMenu
                    {...props}
                />
            </Navbar.Collapse>
        </Navbar>
        <Suspense fallback={<LoadingAlert />}>
            {props.children}
        </Suspense>
    </>
    );

}