import { lazy } from 'react';
import { Models } from '../../localComponents/types/models';
import { RouteDefinition } from '../../sharedCommonComponents/types/frontendTypes';
import { HealthRecordEntryType } from '../../localComponents/types/enums';


const CreateEditMedicalAlertPage = lazy(() => import('../pages/Patients/CreateEditMedicalAlertPage'));
const CreateEditHealthProfessionalOpinionPage = lazy(() => import('../pages/CreateEditHealthProfessionalOpinionPage'));
const DiagnosisWizardPage = lazy(() => import('../pages/Diagnoses/DiagnosisWizardPage'));
const AddImmunizationPage = lazy(() => import('../pages/Medication/AddImmunizationPage'));
const AddMedicationPage = lazy(() => import('../pages/Medication/AddMedicationPage'));
const CreatePatientObservationPage = lazy(() => import('../pages/Observations/CreatePatientObservationPage'));
const AnswerQuestionnairePage = lazy(() => import('../pages/Patients/AnswerQuestionnairePage'));
const AssignQuestionnairePage = lazy(() => import('../pages/Patients/AssignQuestionnairePage'));
const CreateAllergyPage = lazy(() => import('../pages/Patients/CreateAllergyPage'));
const CreateDiagnosisPage = lazy(() => import('../pages/Patients/CreateDiagnosisPage'));
const CreateEditEncounterPage = lazy(() => import('../pages/Patients/CreateEditEncounterPage'));
const CreateEditEquipmentPage = lazy(() => import('../pages/Patients/CreateEditEquipmentPage'));
const CreateEditSpecimenPage = lazy(() => import('../pages/Patients/CreateEditSpecimenPage'));
const CreateMedicalProcedurePage = lazy(() => import('../pages/Patients/CreateMedicalProcedurePage'));
const CreatePatientDocumentPage = lazy(() => import('../pages/Patients/CreatePatientDocumentPage'));
const CreatePatientNotePage = lazy(() => import('../pages/Notes/CreatePatientNotePage'));
const ComplexPatientNoteViewerPage = lazy(() => import('../pages/Notes/ComplexPatientNoteViewerPage'));
const ComplexPatientNoteComparisonPage = lazy(() => import('../pages/Notes/ComplexPatientNoteComparisonPage'));
const ImagingUploadPage = lazy(() => import('../pages/Patients/ImagingUploadPage'));
const PatientMedicationsPage = lazy(() => import('../pages/Patients/PatientMedicationsPage'));
const PatientTimelinePage = lazy(() => import('../pages/Patients/PatientTimelinePage'));
const CreatePatientTestResultPage = lazy(() => import('../pages/TestResults/CreatePatientTestResultPage'));
const EditTestResultPage = lazy(() => import('../pages/TestResults/EditTestResultPage'));
const GenomeExplorationPage = lazy(() => import('../pages/TestResults/GenomeExplorationPage'));
const GenomeUploadPage = lazy(() => import('../pages/TestResults/GenomeUploadPage'));
const ImagingExplorationPage = lazy(() => import('../pages/TestResults/ImagingExplorationPage'));
const CreateEditPregnancyPage = lazy(() => import('../pages/Patients/CreateEditPregnancyPage'));
const EditQuestionnaireAnswersRepetitionPage = lazy(() => import('../pages/Questionnaires/EditQuestionnaireAnswersRepetitionPage'));
const LooseQuestionsAskingPage = lazy(() => import('../pages/Questionnaires/LooseQuestionsAskingPage'));
const CreateEditMedicationSchedulePage = lazy(() => import('../pages/Patients/CreateEditMedicationSchedulePage'));
const CreateEditMedicationDispensionPage = lazy(() => import('../pages/Medication/CreateEditMedicationDispensionPage'));
const PrintHealthRecordPage = lazy(() => import('../pages/Patients/PrintHealthRecordPage'));
const CprPage = lazy(() => import('../pages/Patients/CprPage'));
const WorkupPage = lazy(() => import('../pages/TreatmentProcesses/WorkupPage'));
const SurgeryPage = lazy(() => import('../pages/TreatmentProcesses/SurgeryPage'));

export const HealthRecordRoutes = (features: Models.Configuration.HealthRecordFeatureSettings): RouteDefinition[] => {

    const createVerb = "create";
    const editVerb = "edit";

    const healthRecordFeatures = features;
    const routes: RouteDefinition[] = [
        { path: 'timeline', element: <PatientTimelinePage />},
        { path: 'print', usesCustomLayout: true, element: <PrintHealthRecordPage />},

        // Encounter
        { path: `${createVerb}/${HealthRecordEntryType.Encounter}`, element: <CreateEditEncounterPage />},
        { path: `${editVerb}/${HealthRecordEntryType.Encounter}/:id`, element: <CreateEditEncounterPage />},

        // CPR
        { path: 'cpr', usesCustomLayout: true, element: <CprPage />},
        { path: 'cpr/:id', usesCustomLayout: true, element: <CprPage />}
    ];
    if(healthRecordFeatures.enableDiagnoses) {
        const diagnosisRoutes: RouteDefinition[] = [
            { path: `wizard/${HealthRecordEntryType.Diagnosis}`, element: <DiagnosisWizardPage /> },

            { path: `${createVerb}/${HealthRecordEntryType.Diagnosis}`, element: <CreateDiagnosisPage />},
            { path: `${editVerb}/${HealthRecordEntryType.Diagnosis}/:id`, element: <CreateDiagnosisPage />},

            { path: `${createVerb}/${HealthRecordEntryType.Allergy}`, element: <CreateAllergyPage />},
            { path: `${editVerb}/${HealthRecordEntryType.Allergy}/:id`, element: <CreateAllergyPage />},

            { path: `${createVerb}/${HealthRecordEntryType.MedicalAlert}`, element: <CreateEditMedicalAlertPage />},
            { path: `${editVerb}/${HealthRecordEntryType.MedicalAlert}/:id`, element: <CreateEditMedicalAlertPage />},

            { path: `${createVerb}/workup`, element: <WorkupPage />},
            { path: `${editVerb}/workup/:id`, element: <WorkupPage />},

            { path: `${createVerb}/surgery`, element: <SurgeryPage />},
            { path: `${editVerb}/surgery/:id`, element: <SurgeryPage />},
        ]
        routes.push(...diagnosisRoutes);
    }
    if(healthRecordFeatures.enableDocuments) {
        const documentRoutes: RouteDefinition[] = [
            { path: `${createVerb}/${HealthRecordEntryType.Document}`, element: <CreatePatientDocumentPage />},
            { path: `${editVerb}/${HealthRecordEntryType.Document}/:id`, element: <CreatePatientDocumentPage />},
        ];
        routes.push(...documentRoutes);
    }
    if(healthRecordFeatures.enableEquipment) {
        const equipmentRoutes: RouteDefinition[] = [
            { path: `${createVerb}/${HealthRecordEntryType.Equipment}`, element: <CreateEditEquipmentPage />},
            { path: `${editVerb}/${HealthRecordEntryType.Equipment}/:id`, element: <CreateEditEquipmentPage />},
        ];
        routes.push(...equipmentRoutes);
    }
    if(healthRecordFeatures.enableMedication) {
        const medicationRoutes: RouteDefinition[] = [
            { path: 'medications', element: <PatientMedicationsPage />},

            { path: `${createVerb}/${HealthRecordEntryType.MedicationDispension}`, element: <AddMedicationPage />},
            { path: `${editVerb}/${HealthRecordEntryType.MedicationDispension}/:id`, element: <CreateEditMedicationDispensionPage />},

            { path: `${createVerb}/${HealthRecordEntryType.Immunization}`, element: <AddImmunizationPage />},
            { path: `${editVerb}/${HealthRecordEntryType.Immunization}/:id`, element: <AddImmunizationPage />},

            { path: `${createVerb}/${HealthRecordEntryType.MedicationSchedule}`, element: <CreateEditMedicationSchedulePage />},
            { path: `${editVerb}/${HealthRecordEntryType.MedicationSchedule}/:id`, element: <CreateEditMedicationSchedulePage />},
        ];
        routes.push(...medicationRoutes);
    }
    if(healthRecordFeatures.enableNotes) {
        const noteRoutes: RouteDefinition[] = [
            { path: `${createVerb}/${HealthRecordEntryType.Note}`, element: <CreatePatientNotePage />},
            { path: `${editVerb}/${HealthRecordEntryType.Note}/:id`, element: <CreatePatientNotePage />},
            { path: `${HealthRecordEntryType.Note}/:id`, element: <ComplexPatientNoteViewerPage />},
            { path: 'notes/compare', element: <ComplexPatientNoteComparisonPage />},
        ];
        routes.push(...noteRoutes);
    }
    if(healthRecordFeatures.enableObservations) {
        const observationRoutes: RouteDefinition[] = [
            { path: `${createVerb}/observation`, element: <CreatePatientObservationPage />},
            { path: `${editVerb}/observation/:id`, element: <EditTestResultPage />},
        ];
        routes.push(...observationRoutes);
    }
    if(healthRecordFeatures.enableOpinions) {
        const opinionRoutes: RouteDefinition[] = [
            { path: `${createVerb}/opinion/for/:entryType/:entryId`, element: <CreateEditHealthProfessionalOpinionPage />},
            { path: `${editVerb}/opinion/:opinionId`, element: <CreateEditHealthProfessionalOpinionPage />},
        ];
        routes.push(...opinionRoutes);
    }
    if(healthRecordFeatures.enablePregnancies) {
        const pregnancyRoutes: RouteDefinition[] = [
            { path: `${createVerb}/${HealthRecordEntryType.Pregnancy}`, element: <CreateEditPregnancyPage />},
            { path: `${editVerb}/${HealthRecordEntryType.Pregnancy}/:id`, element: <CreateEditPregnancyPage />},
        ];
        routes.push(...pregnancyRoutes);
    }
    if(healthRecordFeatures.enableProcedures) {
        const procedureRoutes: RouteDefinition[] = [
            { path: `${createVerb}/${HealthRecordEntryType.Procedure}`, element: <CreateMedicalProcedurePage />},
            { path: `${editVerb}/${HealthRecordEntryType.Procedure}/:id`, element: <CreateMedicalProcedurePage />},
        ];
        routes.push(...procedureRoutes);
    }
    if(healthRecordFeatures.enableQuestionnaires) {
        const questionnaireRoutes: RouteDefinition[] = [
            { path: 'questions', element: <LooseQuestionsAskingPage />},
            { path: `add/${HealthRecordEntryType.Questionnaire}`, element: <AssignQuestionnairePage />},
            { path: `${HealthRecordEntryType.Questionnaire}/:questionnaireId/answer`, element: <AnswerQuestionnairePage />},
            { path: `${HealthRecordEntryType.Questionnaire}/:questionnaireId/answer/:answerId`, element: <AnswerQuestionnairePage />},
            { path: `${HealthRecordEntryType.Questionnaire}/:questionnaireId/repetition`, element: <EditQuestionnaireAnswersRepetitionPage />},
            { path: `${HealthRecordEntryType.Questionnaire}/:questionnaireId/repetition/:seriesId`, element: <EditQuestionnaireAnswersRepetitionPage />},
        ];
        routes.push(...questionnaireRoutes);
    }
    if(healthRecordFeatures.enableSpecimens) {
        const specimenRoutes: RouteDefinition[] = [
            { path: `${createVerb}/${HealthRecordEntryType.Specimen}`, element: <CreateEditSpecimenPage />},
            { path: `${editVerb}/${HealthRecordEntryType.Specimen}/:id`, element: <CreateEditSpecimenPage />},
        ];
        routes.push(...specimenRoutes);
    }
    if(healthRecordFeatures.enableTestResults) {
        const testResultRoutes: RouteDefinition[] = [
            { path: `${createVerb}/${HealthRecordEntryType.TestResult}`, element: <CreatePatientTestResultPage />},
            { path: `${editVerb}/${HealthRecordEntryType.TestResult}/:id`, element: <EditTestResultPage />},

            // Special test results
            { path: 'upload/imaging', element: <ImagingUploadPage />},
            { path: 'imaging/:dicomStudyId', element: <ImagingExplorationPage />},
            
            { path: 'upload/genome', element: <GenomeUploadPage />},
            { path: 'genome', element: <GenomeExplorationPage />},
        ];
        routes.push(...testResultRoutes);
    }
    return routes;
}
export default HealthRecordRoutes;