import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccessPermissions } from "../../../localComponents/types/enums";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { loadObject } from "../../../sharedCommonComponents/helpers/LoadingHelpers";
import { PersonPermissions } from "../../types/frontendTypes";
import { PersonDataArgs, SliceStateBase } from "../../types/reduxInterfaces";
import { ApiGetActionCreator } from "../../types/reduxTypes";
import { createDefaultReducers } from "../helpers/DefaultReducers";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";

interface PermissionsState extends SliceStateBase {
    items: PersonPermissions[];
}

const initialState: PermissionsState = {
    isLoading: false,
    isSubmitting: false,
    items: []
};

export const permissionsSlice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        ...createDefaultReducers(initialState),
        addOrUpdatePermission: (state, action: PayloadAction<PersonPermissions>) => {
            const item = action.payload;
            if(state.items.some(x => x.personId === item.personId)) {
                state.items = state.items.map(x => x.personId === item.personId ? item : x);
            } else {
                state.items.push(item);
            }
        }
    }
});

export const loadPermissionsForPerson: ApiGetActionCreator<PersonDataArgs, AccessPermissions[]> = ({
    args,
    onSuccess,
    onFailure,
    onFinally
}) => {
    return async (dispatch) => {
        dispatch(permissionsSlice.actions.setIsLoading(true));
        await loadObject<AccessPermissions[]>(
            `api/persons/${args!.personId}/permissions`,
            {},
            resolveText("Permissions_CouldNotLoad"),
            permissions => {
                dispatch(permissionsSlice.actions.addOrUpdatePermission({
                    personId: args!.personId,
                    permissions: permissions
                }));
                if(onSuccess) {
                    onSuccess(permissions);
                }
            },
            async () => {
                if(onFailure) {
                    onFailure();
                }
            },
            () => {
                dispatch(permissionsSlice.actions.setIsLoading(true));
                if(onFinally) {
                    onFinally();
                }
            }
        );
    }
}

export const permissionsSelectors = {
    createSelectForPerson: () => createSelector(
        (state: RootState) => state.permissions.items,
        (state: RootState, args: { personId: string | undefined }) => args.personId,
        (permissions, personId) => personId ? permissions.find(x => x.personId === personId)?.permissions ?? [] : []
    )
}