import { createSlice } from "@reduxjs/toolkit";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { HealthRecordEventFilter } from "../../types/frontendTypes";
import { PersonDataRemoteState } from "../../types/reduxInterfaces";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { HealthRecordEntryType } from "../../../localComponents/types/enums";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";

interface CustomHealthRecordSummaryState extends PersonDataRemoteState<ViewModels.HealthRecordEntries.CustomHealthRecordSummaryViewModel,HealthRecordEventFilter> {
}
const initialState: CustomHealthRecordSummaryState = {
    ...defaultRemoteInitialState(),
}
export const customHealthRecordSummarySlice = createSlice({
    name: 'customHealthRecordSummaries',
    initialState: initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState)
    }
});

export const customHealthRecordSummaryActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.HealthRecordSummary,
        'healthRecordSummary',
        customHealthRecordSummarySlice.actions, 
        state => state.customHealthRecordSummaries,
        healthRecordEntryQueryBuilder,
        areHealthRecordEntryFiltersEqual
    ),
};

export const customHealthRecordSummarySelectors = {
    ...createHealthRecordEntrySelectors(state => state.customHealthRecordSummaries),
}