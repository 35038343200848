import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { RemoteState } from "../../types/reduxInterfaces";
import { createRestApiActions } from "../helpers/GenericSliceActions";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { defaultQueryBuilder } from "../helpers/QueryBuilders";
import { areFiltersEqual } from "../../helpers/FilterHelpers";
import { RepresentationPermissionFilter } from "../../types/frontendTypes";
import { deleteActionBuilder, postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { createDefaultGenericItemReducers } from "../helpers/DefaultReducers";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { ViewModels } from "../../../localComponents/types/viewModels";

export interface RepresentationPermissionsState extends RemoteState<ViewModels.RepresentationPermissionViewModel, RepresentationPermissionFilter> {
}

const initialState: RepresentationPermissionsState = {
    ...defaultRemoteInitialState(),
}

export const representationPermissionsSlice = createSlice({
    name: 'representationPermissions',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
        revoke: (state, action: PayloadAction<RevokeRepresentationPermissionArgs>) => {
            state.items = state.items.filter(x => x.permissionReceiverPersonId !== action.payload.receiverPersonId);
        }
    }
});

const representationPermissionsQueryBuilder = (state: RootState, sliceState: RepresentationPermissionsState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, representationPermissionsFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.searchText) {
            queryParams.push({ key: 'searchText', value: filter.searchText });
        }
    }
    return queryParams;
}

const representationPermissionsFilterComparer = (f1?: RepresentationPermissionFilter, f2?: RepresentationPermissionFilter) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.searchText === f2!.searchText;
}

export interface GrantRepresentationPermissionToArgs {
    receiverPersonId: string;
}
export interface RevokeRepresentationPermissionArgs {
    receiverPersonId: string;
}
export const representationPermissionsActions = {
    ...createRestApiActions(
        'representationPermissions',
        representationPermissionsSlice.actions,
        state => state.representationPermissions,
        representationPermissionsQueryBuilder,
        representationPermissionsFilterComparer
    ),
    grantTo: postActionBuilder(
        (args: GrantRepresentationPermissionToArgs) => `api/representationPermissions/grant-to/${args.receiverPersonId}`,
        () => resolveText("RepresentationPermission_CouldNotGrant"),
        representationPermissionsSlice.actions.setIsSubmitting,
        (dispatch,response,args) => dispatch(representationPermissionsSlice.actions.addOrUpdateItem(response))
    ),
    revoke: deleteActionBuilder(
        (args: RevokeRepresentationPermissionArgs) => `api/representationPermissions/granted-to/${args.receiverPersonId}`,
        () => resolveText("RepresentationPermission_SuccessfullyDeleted"),
        () => resolveText("RepresentationPermission_CouldNotDelete"),
        (dispatch,args) => dispatch(representationPermissionsSlice.actions.revoke(args))
    )
};
export const representationPermissionsSelectors = {

};