import { createSlice } from "@reduxjs/toolkit";
import { Models } from "../../../localComponents/types/models";
import { RemoteState } from "../../types/reduxInterfaces";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createRestApiActions } from "../helpers/GenericSliceActions";
import { createDefaultGenericItemReducers } from "../helpers/DefaultReducers";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { defaultQueryBuilder } from "../helpers/QueryBuilders";
import { areFiltersEqual } from "../../helpers/FilterHelpers";

interface MedicalTextTemplatesState extends RemoteState<Models.MedicalTextEditor.MedicalTextTemplate, {}> {
}
const initialState: MedicalTextTemplatesState = {
    ...defaultRemoteInitialState(),
};
export const medicalTextTemplatesSlice = createSlice({
    name: 'medicalTextTemplates',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});
export const medicalTextTemplatesFilterComparer = (f1?: {}, f2?: {}) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return true;
}
export const medicalTextTemplatesQueryBuilder = (state: RootState, sliceState: MedicalTextTemplatesState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, medicalTextTemplatesFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        // TODO
    }
    return queryParams;
}
export const medicalTextTemplatesActions = {
    ...createRestApiActions(
        'medicalTextTemplates', 
        medicalTextTemplatesSlice.actions,
        state => state.medicalTextTemplates,
        medicalTextTemplatesQueryBuilder,
        medicalTextTemplatesFilterComparer
    ),
};
export const medicalTextTemplatesSelectors = {

};