import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HealthRecordEntryType, MedicationDispensionState } from "../../../localComponents/types/enums";
import { Models } from "../../../localComponents/types/models";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { MedicationDispensionsFilter } from "../../types/frontendTypes";
import { PersonDataRemoteState, PersonDataArgs, LoadItemArgs } from "../../types/reduxInterfaces";
import { loadItemActionBuilder, loadItemsActionBuilder, loadPersonDataActionBuilder, postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { medicationSchedulesSlice } from "./medicationSchedulesSlice";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { areEquivalent } from "../../../sharedCommonComponents/helpers/CollectionHelpers";

interface MedicationDispensionsState 
    extends PersonDataRemoteState<
        ViewModels.HealthRecordEntries.MedicationDispensionViewModel,
        MedicationDispensionsFilter
    > {
    pastMedicationSummary?: Models.Medication.MedicationDispensionSummary[];
}

const initialState: MedicationDispensionsState = {
    ...defaultRemoteInitialState(),
}

export const medicationDispensionsSlice = createSlice({
    name: 'medicationDispensions',
    initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState),
        setSummary: (state, action: PayloadAction<Models.Medication.MedicationDispensionSummary[]>) => {
            state.pastMedicationSummary = action.payload;
        }
    }
});

export const medicationDispensionFilterComparer = (f1?: MedicationDispensionsFilter, f2?: MedicationDispensionsFilter) => {
    if(!areHealthRecordEntryFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.scheduleId === f2!.scheduleId
        && areEquivalent(f1!.dispensionStates, f2!.dispensionStates);
}
export const medicationDispensionQueryBuilder = (state: RootState, sliceState: MedicationDispensionsState) => {
    const queryParams = healthRecordEntryQueryBuilder(state, sliceState, medicationDispensionFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.scheduleId) {
            queryParams.push({ key: 'ScheduleId', value: filter.scheduleId });
        }
        if(filter.dispensionStates && filter.dispensionStates.length > 0) {
            for (const dispensionState of filter.dispensionStates) {
                queryParams.push({ key: 'DispensionStates', value: dispensionState });
            }
        }
    }
    return queryParams;
}

export interface LoadMedicationDispensionsForDrugArgs extends PersonDataArgs {
    drugId: string;
}
export interface LoadDispensionsForMedicationScheduleArgs {
    scheduleId: string;
}
export const medicationDispensionsActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.MedicationDispension,
        'medicationDispensions', 
        medicationDispensionsSlice.actions, 
        state => state.medicationDispensions,
        medicationDispensionQueryBuilder,
        medicationDispensionFilterComparer
    ),
    loadItemsForDrug: loadPersonDataActionBuilder(
        (args: LoadMedicationDispensionsForDrugArgs) => `api/persons/${args!.personId}/medicationDispensions/drugs/${args!.drugId}`,
        medicationDispensionQueryBuilder,
        medicationDispensionFilterComparer,
        () => resolveText("MedicationDispensions_CouldNotLoad"),
        medicationDispensionsSlice.actions.setCurrentPersonId,
        medicationDispensionsSlice.actions.setIsLoading,
        medicationDispensionsSlice.actions.setItems,
        medicationDispensionsSlice.actions.addOrUpdateItems,
        medicationDispensionsSlice.actions.setHasMoreItems,
        medicationDispensionsSlice.actions.setLastUsedFilter,
        state => state.medicationDispensions
    ),
    loadDispensionsForMedicationSchedule: loadItemsActionBuilder(
        (args: LoadDispensionsForMedicationScheduleArgs) => `api/medicationSchedules/${args!.scheduleId}/dispensions`,
        medicationDispensionQueryBuilder,
        medicationDispensionFilterComparer,
        () => resolveText("MedicationDispensions_CouldNotLoad"),
        medicationDispensionsSlice.actions.setIsLoading,
        medicationDispensionsSlice.actions.setItems,
        medicationDispensionsSlice.actions.addOrUpdateItems,
        medicationDispensionsSlice.actions.setHasMoreItems,
        medicationDispensionsSlice.actions.setLastUsedFilter,
        state => state.medicationDispensions
    ),
    loadPastMedicationSummary: loadItemActionBuilder(
        (args: LoadItemArgs) => `api/persons/${args!.itemId}/medicationDispensions/summary`,
        state => {
            const queryParams: { [key:string]: string } = {};
            const filter = state.healthRecords.filter;
            if(filter?.timeRange?.start) {
                queryParams['timeRangeStart'] = filter.timeRange.start as any;
            }
            if(filter?.timeRange?.end) {
                queryParams['timeRangeEnd'] = filter.timeRange.end as any;
            }
            return queryParams;
        },
        () => resolveText("MedicationDispensions_CouldNotLoad"),
        medicationDispensionsSlice.actions.setIsLoading,
        medicationDispensionsSlice.actions.setSummary
    ),
    pauseInfusion: postActionBuilder(
        (args: string) => `api/medicationDispensions/${args}/pause`,
        () => resolveText("Infusion_CouldNotPause"),
        medicationDispensionsSlice.actions.setIsSubmitting,
        (dispatch,response) => dispatch(medicationDispensionsSlice.actions.addOrUpdateItem(response))
    ),
    changeInfusionRate: postActionBuilder<string,Models.Medication.InfusionRate>(
        args => `api/medicationDispensions/${args}/infusionRates`,
        () => resolveText("Infusion_CouldNotChangeRate"),
        medicationDispensionsSlice.actions.setIsSubmitting,
        (dispatch,response) => dispatch(medicationDispensionsSlice.actions.addOrUpdateItem(response))
    ),
    endInfusion: postActionBuilder(
        (args: string) => `api/medicationDispensions/${args}/stop`,
        () => resolveText("Infusion_CouldNotStop"),
        medicationDispensionsSlice.actions.setIsSubmitting,
        (dispatch,response) => dispatch(medicationDispensionsSlice.actions.addOrUpdateItem(response))
    ),
    moveDispensionBackToSchedule: postActionBuilder<{ dispensionId: string},never>(
        args => `api/medicationdispensions/${args.dispensionId}/back-to-schedule`,
        () => resolveText("MedicationDispension_CouldNotMoveToSchedule"),
        medicationDispensionsSlice.actions.setIsSubmitting,
        (dispatch,response: Models.Medication.MedicationDispensionBackToScheduleResponseBody,_) => {
            dispatch(medicationSchedulesSlice.actions.addOrUpdateItem(response.medicationSchedule));
            dispatch(medicationDispensionsSlice.actions.addOrUpdateItem(response.medicationDispension as any));
        }
    ),
    dispenseMedication: postActionBuilder(
        _ => 'api/medicationSchedules/dispense',
        () => resolveText("MedicationSchedule_CouldNotDispense"),
        medicationSchedulesSlice.actions.setIsSubmitting,
        (dispatch,response,_) => dispatch(medicationDispensionsSlice.actions.addOrUpdateItem(response))
    ),
    storeDispensionsForSchedule: postActionBuilder<{ scheduleId: string }, Models.Medication.MedicationDispension[]>(
        args => `api/medicationSchedules/${args.scheduleId}/dispensions`,
        () => resolveText("MedicationDispensions_CouldNotStore"),
        medicationDispensionsSlice.actions.setIsSubmitting,
        (dispatch, response: ViewModels.HealthRecordEntries.MedicationDispensionViewModel[], _) => dispatch(
            medicationDispensionsSlice.actions.addOrUpdateItems(response)
        )
    )
};
export interface SelectMedicationDispensionsForScheduleArgs {
    medicationScheduleId: string;
    includeDispensionsNotLinkedToSchedule?: boolean;
    dispensionStates?: MedicationDispensionState[];
}
export interface SelectMedicationDispensionsForPersonAndDrugArgs {
    drugId?: string;
    personId?: string;
}
export const medicationDispensionsSelectors = {
    ...createHealthRecordEntrySelectors(state => state.medicationDispensions),
    createSelectPastDispensionsSummaryOrDefault: () => createSelector(
        (state: RootState) => state.medicationDispensions.pastMedicationSummary,
        (summary) => summary ?? []
    ),
    createSelectForSchedule: () => createSelector(
        (state: RootState) => state.medicationDispensions.items,
        (_: RootState, args: SelectMedicationDispensionsForScheduleArgs) => args.medicationScheduleId,
        (_: RootState, args: SelectMedicationDispensionsForScheduleArgs) => args.includeDispensionsNotLinkedToSchedule,
        (_: RootState, args: SelectMedicationDispensionsForScheduleArgs) => args.dispensionStates,
        (items, medicationScheduleId, includeDispensionsNotLinkedToSchedule, dispensionStates) => {
            const scheduleDispensions = includeDispensionsNotLinkedToSchedule
                ? items.filter(x => x.scheduleId === medicationScheduleId || !x.scheduleId)
                : items.filter(x => x.scheduleId === medicationScheduleId);
            if(dispensionStates) {
                return scheduleDispensions.filter(x => dispensionStates.includes(x.state));
            }
            return scheduleDispensions;
        }
    ),
    createSelectForDrug: () => createSelector(
        (state: RootState) => state.medicationDispensions.items,
        (_: RootState, args: SelectMedicationDispensionsForPersonAndDrugArgs) => args.drugId,
        (_: RootState, args: SelectMedicationDispensionsForPersonAndDrugArgs) => args.personId,
        (items, drugId, personId) => drugId && personId 
            ? items.filter(x => x.personId === personId && x.drug.id === drugId)
            : []
    ),
};
