import { Navbar, Nav, NavItem } from 'react-bootstrap';

interface TestSystemBannerProps {}

export const TestSystemBanner = (props: TestSystemBannerProps) => {

    if(window.location.hostname === 'localhost' || window.location.hostname.startsWith('test.')) {
        return (
            <Navbar bg='warning' id="testsystem-banner" className='justify-content-center'>
                <Nav>
                    <NavItem>This is a test system</NavItem>
                </Nav>
            </Navbar>
        );
    }
    return null;
}