import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { Models } from "../../types/models";
import { AddressRole, Country, DeductionType, PaymentMethod } from "../../types/enums";
import { uuid } from "../../../sharedCommonComponents/helpers/uuid";
import { postActionBuilder } from "../../../sharedHealthComponents/redux/helpers/ActionCreatorBuilder";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { RootState } from "../store/healthRecordStore";

interface ShoppingCartState {
    items: Models.Billing.OrderItem[];
    
    deductions: Models.Billing.Deduction[];
    recipient: Models.Individuals.Contact;
    useDeviatingBillingContact: boolean;
    billingContact: Models.Individuals.Contact;
    billingAddress: Models.Address;
    useDeviatingShippingAddress: boolean;
    shippingAddress: Models.Address;

    shippingMethod?: Models.Billing.ShippingMethod;
    paymentMethod?: PaymentMethod;

    isPlacingOrder: boolean;
}
export interface SetItemMultiplierArgs {
    itemId: string;
    multiplier: number;
}

const initialState: ShoppingCartState = {
    items: [],
    deductions: [],
    recipient: {
        id: uuid(),
        name: ''
    },
    useDeviatingBillingContact: false,
    billingContact: {
        id: uuid(),
        name: ''
    },
    billingAddress: {
        street: '',
        houseNumber: '',
        postalCode: '',
        city: '',
        country: Country.de,
        role: AddressRole.Temporary
    },
    useDeviatingShippingAddress: false,
    shippingAddress: {
        street: '',
        houseNumber: '',
        postalCode: '',
        city: '',
        country: Country.de,
        role: AddressRole.Temporary
    },
    isPlacingOrder: false
}
export const shoppingCartSlice = createSlice({
    name: 'shoppingCart',
    initialState: initialState,
    reducers: {
        clear: (state) => {
            state = initialState;
        },
        addItem: (state, action: PayloadAction<Models.Billing.OrderItem>) => {
            if(state.items.some(x => x.shopItem.id === action.payload.shopItem.id)) {
                return;
            }
            state.items.push(action.payload);
        },
        removeItem: (state, action: PayloadAction<string>) => {
            state.items = state.items.filter(x => x.shopItem.id !== action.payload);
        },
        setItemMultiplier: (state, action: PayloadAction<SetItemMultiplierArgs>) => {
            if(action.payload.multiplier < 0) {
                return;
            }
            const matchingItem = state.items.find(x => x.shopItem.id === action.payload.itemId);
            if(matchingItem) {
                matchingItem.multiplier = action.payload.multiplier;
            }
        },
        setRecipient: (state, action: PayloadAction<Models.Individuals.Contact>) => {
            state.recipient = action.payload;
        },
        setUseDeviatingBillingContact: (state, action: PayloadAction<boolean>) => {
            state.useDeviatingBillingContact = action.payload;
        },
        setBillingContact: (state, action: PayloadAction<Models.Individuals.Contact>) => {
            state.billingContact = action.payload;
        },
        setBillingAddress: (state, action: PayloadAction<Models.Address>) => {
            state.billingAddress = action.payload;
        },
        setUseDeviatingShippingAddress: (state, action: PayloadAction<boolean>) => {
            state.useDeviatingShippingAddress = action.payload;
        },
        setShippingAddress: (state, action: PayloadAction<Models.Address>) => {
            state.shippingAddress = action.payload;
        },
        setShippingMethod: (state, action: PayloadAction<Models.Billing.ShippingMethod>) => {
            state.shippingMethod = action.payload;
        },
        setPaymentMethod: (state, action: PayloadAction<PaymentMethod>) => {
            state.paymentMethod = action.payload;
        },
        setIsPlacingOrder: (state, action: PayloadAction<boolean>) => {
            state.isPlacingOrder = action.payload;
        },
        addDeduction: (state, action: PayloadAction<Models.Billing.Deduction>) => {
            const deduction = action.payload;
            if(deduction.type === DeductionType.Voucher) {
                const voucherDeduction = deduction as Models.Billing.VoucherDeduction;
                const existingDeduction = state.deductions.find(x => x.type === DeductionType.Voucher 
                    && (x as Models.Billing.VoucherDeduction).voucher.id === voucherDeduction.voucher.id);
                if(existingDeduction) {
                    return;
                }
            }
            state.deductions.push(deduction);
        },
        removeDeduction: (state, action: PayloadAction<string>) => {
            state.deductions = state.deductions.filter(x => x.type !== DeductionType.Voucher
                || (x as Models.Billing.VoucherDeduction).voucher.id !== action.payload);
        }
    }
});

export interface PlaceOrderArgs {

}
export const shoppingCartActions = {
    placeOrder: postActionBuilder(
        args => 'api/orders',
        () => resolveText("Order_CouldNotPlace"),
        shoppingCartSlice.actions.setIsPlacingOrder,
        (dispatch,response,args) => dispatch(shoppingCartSlice.actions.clear())
    ),
};
export const shoppingCartSelectors = {
    createSelectIsPhysicalOrder: () => createSelector(
        (state: RootState) => state.shoppingCart.items,
        (items) => items.some(x => x.shopItem.isPhysicalObject)
    ),
    createSelectShopItems: () => createSelector(
        (state: RootState) => state.shoppingCart.items,
        (items) => items.map(x => x.shopItem)
    ),
};