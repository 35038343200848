import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { Models } from "../../../localComponents/types/models";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { ApiGetActionCreator } from "../../types/reduxTypes";
import { loadObject } from "../../../sharedCommonComponents/helpers/LoadingHelpers";
import { icdCategoryToClassificationReference, loincConceptToClassificationReference, snomedCtViewModelToClassificationReference } from "../../helpers/ClassificationReferenceHelpers";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { ClassificationType } from "../../../localComponents/types/enums";
import { ViewModels } from "../../../localComponents/types/viewModels";

interface ClassificationsSliceState {
    loincCodes: { [code:string]: Models.ClassificationReference };
    snomedCtConcepts: { [conceptId:string]: Models.ClassificationReference };
    icdCategories: { [conceptId:string]: Models.ClassificationReference };
}
const initialState: ClassificationsSliceState = {
    loincCodes: {},
    snomedCtConcepts: {},
    icdCategories: {}
};
export const classificationsSlice = createSlice({
    name: 'classifications',
    initialState: initialState,
    reducers: {
        addLoincCode: (state, action: PayloadAction<Models.ClassificationReference>) => {
            state.loincCodes[action.payload.conceptId] = action.payload;
        },
        addLoincCodes: (state, action: PayloadAction<Models.ClassificationReference[]>) => {
            for (const loincConcept of action.payload) {
                state.loincCodes[loincConcept.conceptId] = loincConcept;
            }
        },
        clearLoincCodes: (state) => {
            state.loincCodes = {};
        },
        addSnomedCtConcept: (state, action: PayloadAction<Models.ClassificationReference>) => {
            state.snomedCtConcepts[action.payload.conceptId] = action.payload;
        },
        addSnomedCtConcepts: (state, action: PayloadAction<Models.ClassificationReference[]>) => {
            for (const snomedCtConcept of action.payload) {
                state.snomedCtConcepts[snomedCtConcept.conceptId] = snomedCtConcept;
            }
        },
        clearSnomedCtConcepts: (state) => {
            state.snomedCtConcepts = {};
        },
        addIcdCategory: (state, action: PayloadAction<Models.ClassificationReference>) => {
            state.icdCategories[action.payload.conceptId] = action.payload;
        },
        addIcdCategories: (state, action: PayloadAction<Models.ClassificationReference[]>) => {
            for (const icdCategory of action.payload) {
                state.icdCategories[icdCategory.conceptId] = icdCategory;
            }
        },
        clearIcdCategories: (state) => {
            state.icdCategories = {};
        }
    }
});

interface LoadConceptsArgs {
    conceptIds: string[];
}
export const classificationsActions = {
    loadLoincConceptsIfNotLoaded: (({ args }) => {
        return async (dispatch, getState) => {
            const existingConceptIds = Object.values(getState().classifications.loincCodes).map(x => x.conceptId)
            const missingConcepts = args!.conceptIds.filter(conceptId => !existingConceptIds.includes(conceptId));
            if(missingConcepts.length === 0) {
                return;
            }
            await loadObject<Models.Classifications.LoincConcept[]>(
                'api/classifications/loinc', 
                missingConcepts.map(conceptId => ({ key: 'loincCodes', value: conceptId })),
                resolveText("LoincConcepts_CouldNotLoad"),
                loincConcepts => {
                    const classificationReferences = loincConcepts.map(concept => loincConceptToClassificationReference(concept)!);
                    dispatch(classificationsSlice.actions.addLoincCodes(classificationReferences));
                }
            );
        }
    }) as ApiGetActionCreator<LoadConceptsArgs,Models.ClassificationReference[]>,
    loadSnomedCtConceptsIfNotLoaded: (({ args}) => {
        return async (dispatch, getState) => {
            const existingConceptIds = Object.values(getState().classifications.snomedCtConcepts).map(x => x.conceptId)
            const missingConcepts = args!.conceptIds.filter(conceptId => !existingConceptIds.includes(conceptId));
            if(missingConcepts.length === 0) {
                return;
            }
            await loadObject<ViewModels.SnomedCtConceptViewModel[]>(
                'api/classifications/snomedct', 
                missingConcepts.map(conceptId => ({ key: 'conceptIds', value: conceptId })),
                resolveText("SnomedCtComponents_CouldNotLoad"),
                snomedCtConcepts => {
                    const classificationReferences = snomedCtConcepts.map(concept => snomedCtViewModelToClassificationReference(concept)!);
                    dispatch(classificationsSlice.actions.addSnomedCtConcepts(classificationReferences));
                }
            );
        }
    }) as ApiGetActionCreator<LoadConceptsArgs,Models.ClassificationReference[]>,
    loadIcdCategoriesIfNotLoaded: (({ args }) => {
        return async (dispatch, getState) => {
            const existingConceptIds = Object.values(getState().classifications.icdCategories).map(x => x.conceptId)
            const missingConcepts = args!.conceptIds.filter(conceptId => !existingConceptIds.includes(conceptId));
            if(missingConcepts.length === 0) {
                return;
            }
            await loadObject<Models.Classifications.Icd.IcdCategory[]>(
                'api/classifications/icd11', 
                missingConcepts.map(conceptId => ({ key: 'conceptIds', value: conceptId })),
                resolveText("IcdCategory_CouldNotLoad"),
                icdCategories => {
                    const classificationReferences = icdCategories.map(concept => icdCategoryToClassificationReference(concept)!);
                    dispatch(classificationsSlice.actions.addIcdCategories(classificationReferences));
                }
            );
        }
    }) as ApiGetActionCreator<LoadConceptsArgs,Models.ClassificationReference[]>,
};
export const classificationsSelectors = {
    getLoincConceptOrDefault: () => createSelector(
        (state: RootState) => state.classifications.loincCodes,
        (_: RootState, args: { conceptId: string }) => args.conceptId,
        (loincCodes, conceptId) => loincCodes[conceptId] 
        ?? { 
            classification: ClassificationType.Loinc, 
            conceptId: conceptId, 
            displayName: ''
        }
    ),
    createSelectSnomedCtConcepts: () => createSelector(
        (state: RootState) => state.classifications.snomedCtConcepts,
        (_: RootState, args: { ids: string[] }) => args.ids,
        (concepts, ids) => ids.map(conceptId => concepts[conceptId]).filter(x => !!x)
    ),
    createSelectIcdCategories: () => createSelector(
        (state: RootState) => state.classifications.icdCategories,
        (_: RootState, args: { ids: string[] }) => args.ids,
        (concepts, ids) => ids.map(conceptId => concepts[conceptId]).filter(x => !!x)
    ),
    createSelectLoincConcepts: () => createSelector(
        (state: RootState) => state.classifications.loincCodes,
        (_: RootState, args: { ids: string[] }) => args.ids,
        (concepts, ids) => ids.map(conceptId => concepts[conceptId]).filter(x => !!x)
    ),
};