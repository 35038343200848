import { createSlice } from "@reduxjs/toolkit";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { PersonDataRemoteState } from "../../types/reduxInterfaces";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { MedicationRequestFilter } from "../../types/frontendTypes";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { HealthRecordEntryType } from "../../../localComponents/types/enums";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";

export interface MedicationRequestsState extends PersonDataRemoteState<ViewModels.HealthRecordEntries.MedicationRequestViewModel,MedicationRequestFilter> {}
const initialState: MedicationRequestsState = {
    ...defaultRemoteInitialState(),
}
export const medicationRequestsSlice = createSlice({
    name: 'medicationRequests',
    initialState: initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState),
    }
});

const medicationRequestQueryBuilder = (state: RootState, sliceState: MedicationRequestsState) => {
    const queryParams = healthRecordEntryQueryBuilder(state, sliceState, medicationRequestFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        
    }
    return queryParams;
}
const medicationRequestFilterComparer = (f1?: MedicationRequestFilter, f2?: MedicationRequestFilter) => {
    if(!areHealthRecordEntryFiltersEqual(f1, f2)) {
        return false;
    }
    return true;
}
export const medicationRequestsActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.Prescription,
        'medicationRequests',
        medicationRequestsSlice.actions,
        state => state.medicationRequests,
        medicationRequestQueryBuilder,
        medicationRequestFilterComparer
    ),
};
export const medicationRequestsSelectors = {
    ...createHealthRecordEntrySelectors(state => state.medicationRequests),
};
