import { createSelector, createSlice } from "@reduxjs/toolkit";
import { HealthRecordEntryType } from "../../../localComponents/types/enums";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { HealthRecordEventFilter } from "../../types/frontendTypes";
import { PersonDataRemoteState } from "../../types/reduxInterfaces";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";

interface QuestionnairesState extends PersonDataRemoteState<ViewModels.HealthRecordEntries.QuestionnaireAnswersViewModel,HealthRecordEventFilter> {
}

const initialState: QuestionnairesState = {
    ...defaultRemoteInitialState(),
}

export const questionnaireAnswersSlice = createSlice({
    name: 'questionnaireAnswers',
    initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState),
    }
});

export const questionnaireAnswersActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.Questionnaire,
        'questionnaireAnswers', 
        questionnaireAnswersSlice.actions, 
        state => state.questionnaireAnswers,
        healthRecordEntryQueryBuilder,
        areHealthRecordEntryFiltersEqual
    ),
    addItem: postActionBuilder(
        _ => 'api/questionnaireAnswers/store-vm',
        () => resolveText("QuestionnaireAnswers_CouldNotStore"),
        questionnaireAnswersSlice.actions.setIsSubmitting,
        (dispatch,response,_) => dispatch(questionnaireAnswersSlice.actions.addOrUpdateItem(response))
    )
};
export const questionnaireAnswersSelectors = {
    ...createHealthRecordEntrySelectors(state => state.questionnaireAnswers),
    createSelectUnanswered: () => createSelector(
        (state: RootState) => state.questionnaireAnswers.items,
        (items) => items.filter(x => !x.hasAnswered)
    ),
};