import React, { PropsWithChildren, Suspense, useContext } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AdminMenu } from './Menus/AdminMenu';
import { HealthProfessionalMenu } from './Menus/HealthProfessionalMenu';
import { LoggedInUser } from './Menus/LoggedInUser';
import { ResearcherMenu } from './Menus/ResearcherMenu';
import { SharerMenu } from './Menus/SharerMenu';
import UserContext from '../contexts/UserContext';
import { AccountType } from '../types/enums';
import { EmergencyGuestMenu } from './Menus/EmergencyGuestMenu';
import { ToastContainer } from 'react-toastify';
import { TestSystemBanner } from './TestSystemBanner';
import { LoadingAlert } from '../../sharedCommonComponents/components/LoadingAlert';
import PharmacyMenu from './Menus/PharmacyMenu';
import { HealthRecordSearchSelfcontained } from '../../sharedHealthComponents/components/Patients/HealthRecordSearchSelfcontained';
import { PublicHealthAgencyMenu } from './Menus/PublicHealthAgencyMenu';
import { FeedbackIcon } from '../../sharedCommonComponents/components/FeedbackIcon';
import { AboutMenu } from './Menus/AboutMenu';

interface LayoutProps extends React.PropsWithChildren<{}> {
    onLogOut: () => Promise<void>;
}

export const Layout = (props: PropsWithChildren<LayoutProps>) => {

    const user = useContext(UserContext)!;
    const navigate = useNavigate();

    let userTypeMenus = null;
    switch(user?.accountType) {
        case AccountType.Admin:
            userTypeMenus = (<AdminMenu />);
            break;
        case AccountType.HealthProfessional:
            userTypeMenus = (<HealthProfessionalMenu />);
            break;
        case AccountType.Researcher:
            userTypeMenus = (<ResearcherMenu />);
            break;
        case AccountType.Pharmacy:
            userTypeMenus = (<PharmacyMenu />);
            break;
        case AccountType.Sharer:
            userTypeMenus = (<SharerMenu />);
            break;
        case AccountType.PublicHealthAgency:
            userTypeMenus = (<PublicHealthAgencyMenu />);
            break;
        case AccountType.EmergencyGuest:
            userTypeMenus = (<EmergencyGuestMenu />);
            break;
    }

    return (
        <>
            <ToastContainer
                theme='colored'
            />
            <TestSystemBanner />
            <HealthRecordSearchSelfcontained  />
            <Navbar bg="light" expand="xl" sticky="top" id="main-navbar" className='text-nowrap'>
                <Navbar.Brand className="ms-2 clickable" onClick={() => navigate('/')}>
                    <img src="/img/shehrd-text-logo-green-black.png" alt="shEHRd logo"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end no-print">
                    {userTypeMenus}
                    <Nav>
                        <AboutMenu />
                    </Nav>
                    <Nav className='me-auto'></Nav>
                    <LoggedInUser onLogOut={props.onLogOut} />
                </Navbar.Collapse>
            </Navbar>
            <Suspense fallback={<LoadingAlert />}>
                {props.children}
            </Suspense>
            <FeedbackIcon />
        </>
    );

}