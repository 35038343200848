import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { HealthRecordEntryType } from "../../../localComponents/types/enums";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { PatientNoteFilter } from "../../types/frontendTypes";
import { PersonDataRemoteState } from "../../types/reduxInterfaces";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { areEquivalent } from "../../../sharedCommonComponents/helpers/CollectionHelpers";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";

interface NotesState extends PersonDataRemoteState<ViewModels.HealthRecordEntries.IPatientNoteViewModel,PatientNoteFilter> {
}

const initialState: NotesState = {
    ...defaultRemoteInitialState(),
}

export const notesSlice = createSlice({
    name: 'notes',
    initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState),
    }
});

const notesFilterComparer = (f1?: PatientNoteFilter, f2?: PatientNoteFilter) => {
    if(!areHealthRecordEntryFiltersEqual(f1,f2)) {
        return false;
    }
    return areEquivalent(f1!.tags, f2!.tags)
        && f1!.noteType === f2!.noteType;
}
const patientNoteQueryBuilder = (state: RootState, sliceState: NotesState) => {
    const queryParams = healthRecordEntryQueryBuilder(state, sliceState, notesFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.tags && filter.tags.length > 0) {
            for (const tag of filter.tags) {
                queryParams.push({ key: 'tags', value: tag});
            }
        }
        if(filter.noteType) {
            queryParams.push({ key: 'noteType', value: filter.noteType });
        }
    }
    return queryParams;
}
export const noteActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.Note,
        'notes', 
        notesSlice.actions, 
        state => state.notes,
        patientNoteQueryBuilder,
        notesFilterComparer
    ),
};
export const notesSelectors = {
    ...createHealthRecordEntrySelectors(state => state.notes),
};