import { createSlice } from "@reduxjs/toolkit";
import { createDefaultGenericItemReducers } from "../helpers/DefaultReducers";
import { createRestApiActions } from "../helpers/GenericSliceActions";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { areFiltersEqual } from "../../helpers/FilterHelpers";
import { TimeslotGeneratorFilter } from "../../types/frontendTypes";
import { defaultQueryBuilder } from "../helpers/QueryBuilders";
import { RemoteState } from "../../types/reduxInterfaces";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";

interface TimeslotGeneratorsState extends RemoteState<ViewModels.ITimeslotGeneratorViewModel,TimeslotGeneratorFilter> {

}
const initialState: TimeslotGeneratorsState = {
    ...defaultRemoteInitialState(),
}
export const timeslotGeneratorsSlice = createSlice({
    name: 'timeslotGenerators',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

const timeslotGeneratorQueryBuilder = (state: RootState, sliceState: TimeslotGeneratorsState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, timeslotGeneratorFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.institutionId) {
            queryParams.push({ key: 'institutionId', value: filter.institutionId });
        }
        if(filter.departmentId) {
            queryParams.push({ key: 'departmentId', value: filter.departmentId });
        }
    }
    return queryParams;
}
const timeslotGeneratorFilterComparer = (f1?: TimeslotGeneratorFilter, f2?: TimeslotGeneratorFilter) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.institutionId === f2!.institutionId
        && f1!.departmentId === f2!.departmentId;
}

export const timeslotGeneratorsActions = {
    ...createRestApiActions(
        'timeslotGenerators',
        timeslotGeneratorsSlice.actions,
        state => state.timeslotGenerators,
        timeslotGeneratorQueryBuilder,
        timeslotGeneratorFilterComparer
    ),
};
export const timeslotGeneratorsSelectors = {

};