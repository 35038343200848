import { createSelector, createSlice } from "@reduxjs/toolkit";
import { HealthRecordEntryType } from "../../../localComponents/types/enums";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { HealthRecordEventFilter } from "../../types/frontendTypes";
import { LoadItemArgs, PersonDataRemoteState } from "../../types/reduxInterfaces";
import { loadItemActionBuilder } from "../helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { Models } from "../../../localComponents/types/models";

export interface OpinionsState extends PersonDataRemoteState<ViewModels.HealthRecordEntries.HealthProfessionalOpinionViewModel,HealthRecordEventFilter> {
}
const initialState: OpinionsState = {
    ...defaultRemoteInitialState(),
}
export const opinionsSlice = createSlice({
    name: 'opinions',
    initialState: initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState),
    }
});
export interface LoadOpinionsForHealthRecordEntryArguments extends LoadItemArgs {
    controllerName: string;
}
export const opinionsActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.HealthProfessionalOpinion,
        'opinions', 
        opinionsSlice.actions, 
        state => state.opinions,
        healthRecordEntryQueryBuilder,
        areHealthRecordEntryFiltersEqual
    ),
    loadForHealthRecordEntry: loadItemActionBuilder(
        (args: LoadOpinionsForHealthRecordEntryArguments) => `api/${args!.controllerName}/${args!.itemId}/opinions`,
        _ => ({}),
        () => resolveText("HealthProfessionalOpinion_CouldNotLoad"),
        opinionsSlice.actions.setIsLoading,
        opinionsSlice.actions.setItems
    )
};
export const opinionsSelectors = {
    ...createHealthRecordEntrySelectors(state => state.opinions),
    createSelectForEntryReference: () => createSelector(
        (state: RootState) => state.opinions.items,
        (_: RootState, args: { entryReference: Models.HealthRecordEntryReference | undefined }) => args.entryReference,
        (opinions, entryReference) => entryReference 
            ? opinions.filter(opinion => opinion.healthRecordEntryReference.entryType === entryReference.entryType 
                && opinion.healthRecordEntryReference.entryId === entryReference.entryId)
            : []
    ),
};