import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { LoadItemArgs, RemoteState } from "../../types/reduxInterfaces";
import { loadItemActionBuilder } from "../helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultReducers } from "../helpers/DefaultReducers";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { Models } from "../../../localComponents/types/models";

interface PersonDataChangesState extends RemoteState<ViewModels.PersonDataChangeViewModel, {}> {
}
const initialState: PersonDataChangesState = {
    ...defaultRemoteInitialState(),
};
export const personDataChangesSlice = createSlice({
    name: 'personDataChange',
    initialState: initialState,
    reducers: {
        ...createDefaultReducers(initialState),
        setItems: (state, action: PayloadAction<ViewModels.PersonDataChangeViewModel[]>) => {
            state.items = action.payload;
        }
    }
});
export interface LoadChangeLogArguments extends LoadItemArgs {
    controllerName: string;
}
export const personDataChangesActions = {
    loadChangeLogForHealthRecordEntry: loadItemActionBuilder(
        (args: LoadChangeLogArguments) => `api/${args!.controllerName}/${args!.itemId}/changelog`,
        _ => ({}),
        () => resolveText("PersonDataChange_CouldNotLoad"),
        personDataChangesSlice.actions.setIsLoading,
        personDataChangesSlice.actions.setItems
    )
};
export const personDataChangesSelectors = {
    createSelectForEntryReference: () => createSelector(
        (state: RootState) => state.personDataChanges.items,
        (_: RootState, args: { entryReference: Models.HealthRecordEntryReference | undefined }) => args.entryReference,
        (items, entryReference) => entryReference 
            ? items.filter(x => /* TODO Apply type filter */ x.entryId === entryReference.entryId)
            : []
    ),
};