import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { postActionBuilder } from "../../../sharedHealthComponents/redux/helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { StudyEnrollementState } from "../../types/enums";
import { ViewModels } from "../../types/viewModels";
import { StudyFilter } from "../../types/frontendTypes";
import { RootState } from "../store/healthRecordStore";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

export interface StudiesState extends RemoteState<ViewModels.Research.StudyViewModel, StudyFilter> {
}
const initialState: StudiesState = {
    ...defaultRemoteInitialState(),
}
export const studiesSlice = createSlice({
    name: 'studies',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
        changeEnrollmentState: (state, action: PayloadAction<ChangeStudyEnrollmentArgs>) => {
            const study = state.items.find(x => x.id === action.payload.studyId);
            if(!study?.myEnrollment) {
                return;
            }
            study.myEnrollment.state = action.payload.enrollmentState;
        }
    }
});

export interface ChangeStudyEnrollmentArgs {
    studyId: string;
    enrollmentState: StudyEnrollementState;
}
const studyQueryBuilder = (state: RootState, sliceState: StudiesState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, studyFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.searchText) {
            queryParams.push({ key: 'searchText', value: filter.searchText });
        }
    }
    return queryParams;
}
const studyFilterComparer = (f1?: StudyFilter, f2?: StudyFilter) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.searchText === f2!.searchText;
}

export const studiesActions = {
    ...createRestApiActions(
        'studies',
        studiesSlice.actions,
        state => state.studies,
        studyQueryBuilder,
        studyFilterComparer
    ),
    changeEnrollmentState: postActionBuilder(
        (args: ChangeStudyEnrollmentArgs) => {
            switch(args.enrollmentState) {
                case StudyEnrollementState.Left:
                    return `api/studies/${args.studyId}/leave`;
                case StudyEnrollementState.Enrolled:
                    return `api/studies/${args.studyId}/accept`;
                default:
                    throw new Error(`Invalid enrollment state change '${args.enrollmentState}'`);
            }
        },
        () => resolveText("StudyEnrollment_CouldNotChangeEnrollment"),
        studiesSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(studiesSlice.actions.changeEnrollmentState(args))
    )
};
export const studiesSelectors = {
    ...createDefaultGenericItemSelectors(state => state.studies),
};