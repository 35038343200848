import { createSlice } from "@reduxjs/toolkit";
import { PersonDataRemoteState } from "../../types/reduxInterfaces";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { HealthRecordEntryType } from "../../../localComponents/types/enums";
import { postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { HealthRecordEventFilter } from "../../types/frontendTypes";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { Models } from "../../../localComponents/types/models";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";

export interface QuestionAnswersFilter extends HealthRecordEventFilter {}
export interface QuestionAnswersState extends PersonDataRemoteState<ViewModels.HealthRecordEntries.QuestionAnswerViewModel, QuestionAnswersFilter> {};
const initialState: QuestionAnswersState = {
    ...defaultRemoteInitialState(),
}
export const questionAnswersSlice = createSlice({
    name: 'questionAnswers',
    initialState: initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState),
    }
});

const questionAnswerQueryBuilder = (state: RootState, sliceState: QuestionAnswersState) => {
    const queryParams = healthRecordEntryQueryBuilder(state, sliceState, questionAnswerFilterComparer);
    const filter = sliceState.filter;
    if(filter) {

    }
    return queryParams;
}
const questionAnswerFilterComparer = (f1?: QuestionAnswersFilter, f2?: QuestionAnswersFilter) => {
    if(!areHealthRecordEntryFiltersEqual(f1, f2)) {
        return false;
    }
    return true;
}

export const questionAnswersActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.Question,
        'questionAnswers',
        questionAnswersSlice.actions,
        state => state.questionAnswers,
        questionAnswerQueryBuilder,
        questionAnswerFilterComparer
    ),
    storeAll: postActionBuilder<any,Models.Interview.QuestionAnswer[]>(
        _ => 'api/questionAnswers/multiple',
        () => resolveText("QuestionAnswers_CouldNotStore"),
        questionAnswersSlice.actions.setIsSubmitting,
        (dispatch,response,_) => dispatch(questionAnswersSlice.actions.addOrUpdateItems(response))
    ),
};
export const questionAnswersSelectors = {
    ...createHealthRecordEntrySelectors(state => state.questionAnswers),
};