import { lazy } from 'react';
import { Models } from '../types/models';
import { ViewModels } from '../types/viewModels';
import { RouteDefinition } from '../../sharedCommonComponents/types/frontendTypes';
import { ChecklistRoutes } from '../../checklist/navigation/ChecklistRoutes';
import ToolsRoutes from './ToolsRoutes';

const AnonymousFormAnswerPage = lazy(() => import('../pages/AnonymousForms/AnonymousFormAnswerPage'));
const AnonymousUploadPage = lazy(() => import('../pages/Sharer/AnonymousUploadPage'));
const AccountSelectionPage = lazy(() => import('../pages/UserManagement/AccountSelectionPage'));
const LoginRedirectPage = lazy(() => import('../pages/UserManagement/LoginRedirectPage'));
const TermsAndConditionsPage = lazy(() => import('../pages/Public/TermsAndConditionsPage'));
const EpidemiologyDashboardPage = lazy(() => import('../pages/Epidemiology/EpidemiologyDashboardPage'));
const RequestPasswordResetPage = lazy(() => import('../pages/UserManagement/RequestPasswordResetPage'));
const ResetPasswordPage = lazy(() => import('../pages/UserManagement/ResetPasswordPage'));
const VerifyEmailPage = lazy(() => import('../pages/UserManagement/VerifyEmailPage'));
const AccountContactEmailVerificationPage = lazy(() => import('../pages/UserManagement/AccountContactEmailVerificationPage'));
const EmergencyPage = lazy(() => import('../pages/EmergencyPage'));
const FrequentlyAskedQuestionsPage = lazy(() => import('../pages/Public/FrequentlyAskedQuestionsPage'));
const NotActivatedAccountPage = lazy(() => import('../pages/UserManagement/NotActivatedAccountPage'));
const ProductsPage = lazy(() => import('../pages/Public/ProductsPage'));
const ProductShehrdPage = lazy(() => import('../pages/Public/ProductShehrdPage'));
const ProductLocalShehrdPage = lazy(() => import('../pages/Public/ProductLocalShehrdPage'));
const EuropeanHealthDataSpacePage = lazy(() => import('../pages/Public/EuropeanHealthDataSpacePage'));
const LoginPage = lazy(() => import('../pages/UserManagement/LoginPage'));
const RegisterAccountPage = lazy(() => import('../pages/UserManagement/RegisterAccountPage'));
const HomePage = lazy(() => import('../pages/Public/HomePage'));
const ContactPage = lazy(() => import('../pages/Public/ContactPage'));
const PrivacyPage = lazy(() => import('../pages/Public/PrivacyPage'));
const RedirectToLoginPage = lazy(() => import('../pages/RedirectToLoginPage'));

interface NoUserRoutesProps {
    onNewAuthenticationResult: (authenticationResult: Models.AccessControl.AuthenticationResult) => void;
    onLoggedIn: (userViewModel: ViewModels.IUserViewModel | null, redirectUrl?: string) => void;
    onLogOut: () => Promise<void>;
    features: Models.Configuration.FeatureSettings;
}
export const NoUserRoutes = (props: NoUserRoutesProps) => {

    const { onNewAuthenticationResult, onLoggedIn, onLogOut, features } = props;
    const routes: RouteDefinition[] = [
        // Anonymous forms
        { path: '/form/:formId', element: <AnonymousFormAnswerPage /> },

        // Health record access
        { path: "/emergency/:emergencyToken", element: <EmergencyPage onNewAccessToken={onNewAuthenticationResult} onGuestLogin={onLoggedIn} /> },
        { path: "/upload/:uploadCode", element: <AnonymousUploadPage /> },

        // Account
        { path: "/login", element: <LoginPage onNewAccessToken={onNewAuthenticationResult} /> },
        { path: "/login/redirect", element: <LoginRedirectPage /> },
        { path: "/accountselection", element: <AccountSelectionPage onNewAccessTokenReceived={onNewAuthenticationResult} onLoggedIn={onLoggedIn} /> },
        { path: "/error/notactivated", element: <NotActivatedAccountPage onLogOut={onLogOut} /> },
        { path: "/register", element: <RegisterAccountPage onNewAccessToken={onNewAuthenticationResult} onLoggedIn={onLoggedIn} /> },
        { path: "/register/:accountType", element: <RegisterAccountPage onNewAccessToken={onNewAuthenticationResult} onLoggedIn={onLoggedIn} /> },
        { path: "/verify-email", element: <VerifyEmailPage /> },
        { path: "/reset-password", element: <ResetPasswordPage onNewAccessToken={onNewAuthenticationResult} /> },
        { path: "/request-password-reset", element: <RequestPasswordResetPage /> },
        { path: "/verify-contact-email", element: <AccountContactEmailVerificationPage /> },

        // About shEHRd
        { path: "/faq", element: <FrequentlyAskedQuestionsPage /> },
        { path: "/about/ehds", element: <EuropeanHealthDataSpacePage /> },
        { path: "/privacy", element: <PrivacyPage /> },
        { path: "/terms-of-service", element: <TermsAndConditionsPage /> },
        { path: "/contact", element: <ContactPage /> },
        { path: "/epidemiology/dashboard", element: <EpidemiologyDashboardPage /> },
        { path: "/", element: <HomePage /> },

        // Business
        { path: "/products", element: <ProductsPage /> },
        { path: "/product/shehrd", element: <ProductShehrdPage /> },
        { path: "/product/local-shehrd", element: <ProductLocalShehrdPage /> },

        // Tools
        ...ToolsRoutes(features, onNewAuthenticationResult, onLogOut),
        ...ChecklistRoutes(),

        { path: '*', element: <RedirectToLoginPage /> },
    ];

    return routes;

}
export default NoUserRoutes;