import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../localComponents/redux/store/healthRecordStore";
import { Models } from "../../localComponents/types/models";
import { resolveText } from "../../sharedCommonComponents/helpers/Globalizer";
import { postActionBuilder } from "../../sharedHealthComponents/redux/helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../sharedHealthComponents/types/reduxInterfaces";
import { ChecklistAnswersFilter } from "../types/frontendTypes";
import { ViewModels } from "../../localComponents/types/viewModels";
import { todolistSlice } from "../../sharedHealthComponents/redux/slices/todolistSlice";
import { createDefaultGenericItemSelectors } from "../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

export interface ChecklistAnswersState extends RemoteState<ViewModels.ChecklistAnswerViewModel,ChecklistAnswersFilter> {}
const initialState: ChecklistAnswersState = {
    ...defaultRemoteInitialState(),
}
export interface UpdateItemAnswerPayload  {
    checklistAnswerId: string;
    checklistItemId: string;
    updatedItemAnswer: Models.Checklists.ChecklistItemAnswer;
}
export const checklistAnswersSlice = createSlice({
    name: 'checklistAnswers',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
        updateItemAnswer: (state, action: PayloadAction<UpdateItemAnswerPayload>) => {
            const matchingAnswer = state.items.find(x => x.id === action.payload.checklistAnswerId);
            if(!matchingAnswer) {
                return;
            }
            if(matchingAnswer.answers.some(x => x.item.id === action.payload.checklistItemId)) {
                matchingAnswer.answers = matchingAnswer.answers.map(answer => 
                    answer.item.id === action.payload.checklistItemId ? action.payload.updatedItemAnswer : answer
                );
            } else {
                matchingAnswer.answers.push(action.payload.updatedItemAnswer);
            }
        },
        addTaskReference: (state, action: PayloadAction<Models.Checklists.ChecklistAnswerTaskLink>) => {
            const matchingAnswer = state.items.find(x => x.id === action.payload.checklistAnswerId);
            if(!matchingAnswer) {
                return;
            }
            if(matchingAnswer.relatedTaskReferences.some(x => x.taskId === action.payload.taskId)) {
                return;
            }
            matchingAnswer.relatedTaskReferences.push(action.payload);
        }
    }
});

const checklistAnswersQueryBuilder = (state: RootState, sliceState: ChecklistAnswersState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, checklistAnswersFilterComparer);
    const filter = sliceState.filter;
    if(filter?.searchText) {
        queryParams.push({ key: 'searchText', value: filter.searchText });
    }
    if(filter?.checklistId) {
        queryParams.push({ key: 'checklistId', value: filter.checklistId });
    }
    return queryParams;
}
const checklistAnswersFilterComparer = (f1?: ChecklistAnswersFilter, f2?: ChecklistAnswersFilter) => {
    if(!f1 && !f2) {
        return true;
    }
    if(!f1 || !f2) {
        return false;
    }
    return f1.searchText === f2.searchText
        && f1.checklistId === f2.checklistId;
}
export interface SetItemAnswerArgs {
    checklistAnswerId: string;
    checklistId: string;
    checklistItemId: string;
}
export interface CreateTaskFromChecklistItemArgs {
    checklistAnswerId: string;
    checklistId: string;
    checklistItemId: string;
}

export const checklistAnswersActions = {
    ...createRestApiActions(
        'checklistAnswers',
        checklistAnswersSlice.actions,
        state => state.checklistAnswers,
        checklistAnswersQueryBuilder,
        checklistAnswersFilterComparer
    ),
    setItemAnswer: postActionBuilder<SetItemAnswerArgs,Models.Checklists.ChecklistItemAnswer>(
        args => `api/checklistanswers/${args.checklistAnswerId}/checklists/${args.checklistId}/items/${args.checklistItemId}/answers`,
        () => resolveText("ChecklistAnswer_CouldNotStore"),
        checklistAnswersSlice.actions.setIsSubmitting,
        (dispatch,response,args) => dispatch(checklistAnswersSlice.actions.updateItemAnswer({
            ...args,
            updatedItemAnswer: response
        }))
    ),
    createTaskFromChecklistItem: postActionBuilder<CreateTaskFromChecklistItemArgs,undefined>(
        args => `api/checklistAnswers/${args.checklistAnswerId}/checklists/${args.checklistId}/items/${args.checklistItemId}/to-task`,
        () => resolveText("ChecklistAnswer_CouldNotCreateTask"),
        checklistAnswersSlice.actions.setIsSubmitting,
        (dispatch,response,_) => {
            const task = response as ViewModels.TodoListItemViewModel;
            dispatch(todolistSlice.actions.addOrUpdateItem(task));
            if(task.relatedChecklistAnswerItem) {
                dispatch(checklistAnswersSlice.actions.addTaskReference(task.relatedChecklistAnswerItem));
            }
        }
    ),
};
export const checklistAnswersSelectors = {
    ...createDefaultGenericItemSelectors(state => state.checklistAnswers),
}