import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RemoteState } from "../../types/reduxInterfaces";
import { Models } from "../../../localComponents/types/models";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../helpers/DefaultReducers";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { getActionBuilder, postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { getControllerName } from "../../helpers/HealthRecordEntryHelpers";
import { areHealthRecordEntryReferencesEqual } from "../../helpers/HealthRecordEntryReferenceHelpers";

export interface HealthRecordEntryEditLockState extends RemoteState<Models.Collaboration.HealthRecordEntryEditLock, {}> {
}
const initialState: HealthRecordEntryEditLockState = {
    ...defaultRemoteInitialState(),
}
export const healthRecordEntryEditLockSlice = createSlice({
    name: 'healthRecordEntryEditLocks',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
        releaseEditLock: (state, action: PayloadAction<Models.HealthRecordEntryReference>) => {
            state.items = state.items.filter(x => !areHealthRecordEntryReferencesEqual(x.entry, action.payload));
        }
    }
});
export interface LoadEditLocksArgs {
    personId: string;
}
export interface EstablishEditLockArgs extends Models.HealthRecordEntryReference {
}
export interface ReleaseEditLockArgs extends Models.HealthRecordEntryReference {
}
export const healthRecordEntryEditLockActions = {
    loadEditLocks: getActionBuilder<LoadEditLocksArgs,Models.Collaboration.HealthRecordEntryEditLock[]>(
        args => `api/persons/${args!.personId}/healthrecord/locks`,
        () => resolveText("HealthRecord_CouldNotLoadEditLocks"),
        healthRecordEntryEditLockSlice.actions.setIsLoading,
        (dispatch, response, _) => dispatch(healthRecordEntryEditLockSlice.actions.setItems(response))
    ),
    establishLock: postActionBuilder<EstablishEditLockArgs,unknown>(
        args => `api/${getControllerName(args.entryType)}/${args.entryId}/lock`,
        () => resolveText("HealthRecordEntryEditLock_CouldNotLock"),
        healthRecordEntryEditLockSlice.actions.setIsSubmitting,
        (dispatch,response,_) => dispatch(healthRecordEntryEditLockSlice.actions.addOrUpdateItem(response))
    ),
    releaseEditLock: postActionBuilder<ReleaseEditLockArgs,unknown>(
        args => `api/${getControllerName(args.entryType)}/${args.entryId}/unlock`,
        () => resolveText("HealthRecordEntryEditLock_CouldNotUnlock"),
        healthRecordEntryEditLockSlice.actions.setIsSubmitting,
        (dispatch, _, args) => dispatch(healthRecordEntryEditLockSlice.actions.releaseEditLock(args))
    ),
};
export const healthRecordEntryEditLockSelectors = {
    getEditLockForEntry: () => createSelector(
        (state: RootState) => state.healthRecordEntryEditLocks.items,
        (_: RootState, args: Models.HealthRecordEntryReference | undefined) => args,
        (editLocks, entry) => {
            if(!entry) {
                return undefined;
            }
            return editLocks.find(x => areHealthRecordEntryReferencesEqual(x.entry, entry));
        }
    ),
}