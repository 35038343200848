import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Models } from "../../../localComponents/types/models";
import { ModalState } from "../../types/frontendTypes";

interface HealthRecordSummaryModalParams {
    personId: string;
}
interface AdditionalPersonInformationModalParams {
    personId: string;
}
interface FileSharingModalParams {
    document: Models.PatientDocument;
}
interface ChangeLogModalParams {
    entryReference: Models.HealthRecordEntryReference;
}
interface RelatedEntriesModalParams {
    personId: string;
    entryReference: Models.HealthRecordEntryReference;
}
interface HealthProfessionalOpinionsModalParams {
    personId: string;
    entryReference: Models.HealthRecordEntryReference;
}
interface MedicalAlertsModalParams {
    personId: string;
}
interface DocumentViewerModalParams {
    documentId: string;
}
interface SpecimenMeasurementOrdersModalParams {
    specimenId: string;
}
interface HealthRecordModalsState {
    healthRecordSummary: ModalState<HealthRecordSummaryModalParams>;
    additionalPersonInformation: ModalState<AdditionalPersonInformationModalParams>;
    fileSharing: ModalState<FileSharingModalParams>;
    changeLog: ModalState<ChangeLogModalParams>;
    relatedEntries: ModalState<RelatedEntriesModalParams>;
    opinions: ModalState<HealthProfessionalOpinionsModalParams>;
    medicalAlerts: ModalState<MedicalAlertsModalParams>;
    documentViewer: ModalState<DocumentViewerModalParams>;
    specimenMeasurementOrders: ModalState<SpecimenMeasurementOrdersModalParams>;
}

const initialState: HealthRecordModalsState = {
    healthRecordSummary: {
        show: false
    },
    additionalPersonInformation: {
        show: false,
    },
    fileSharing: {
        show: false
    },
    changeLog: {
        show: false
    },
    relatedEntries: {
        show: false
    },
    opinions: {
        show: false
    },
    medicalAlerts: {
        show: false
    },
    documentViewer: {
        show: false
    },
    specimenMeasurementOrders: {
        show: false
    },
}

export const healthRecordModalsSlice = createSlice({
    name: 'healthRecordModals',
    initialState: initialState,
    reducers: {
        openHealthRecordSummaryModal: (state, action: PayloadAction<HealthRecordSummaryModalParams>) => {
            state.healthRecordSummary = {
                show: true,
                parameters: action.payload
            };
        },
        closeHealthRecordSummaryModal: (state) => {
            state.healthRecordSummary.show = false;
        },
        openAdditionalPersonInformationModal: (state, action: PayloadAction<AdditionalPersonInformationModalParams>) => {
            state.additionalPersonInformation = {
                show: true,
                parameters: action.payload
            };
        },
        closeAdditionalPersonInformationModal: (state) => {
            state.additionalPersonInformation.show = false;
        },
        openFileSharingModal: (state, action: PayloadAction<FileSharingModalParams>) => {
            state.fileSharing = {
                show: true,
                parameters: action.payload
            };
        },
        closeFileSharingModal: (state) => {
            state.fileSharing.show = false;
        },
        openChangeLogModal: (state, action: PayloadAction<ChangeLogModalParams>) => {
            state.changeLog = {
                show: true,
                parameters: action.payload
            };
        },
        closeChangeLogModal: (state) => {
            state.changeLog.show = false;
        },
        openRelatedEntriesModal: (state, action: PayloadAction<RelatedEntriesModalParams>) => {
            state.relatedEntries = {
                show: true,
                parameters: action.payload
            };
        },
        closeRelatedEntriesModal: (state) => {
            state.relatedEntries.show = false;
        },
        openOpinionsModal: (state, action: PayloadAction<HealthProfessionalOpinionsModalParams>) => {
            state.opinions = {
                show: true,
                parameters: action.payload
            };
        },
        closeOpinionsModal: (state) => {
            state.opinions.show = false;
        },
        openMedicalAlertsModal: (state, action: PayloadAction<MedicalAlertsModalParams>) => {
            state.medicalAlerts = {
                show: true,
                parameters: action.payload
            }
        },
        closeMedicalAlertsModal: (state) => {
            state.medicalAlerts.show = false;
        },
        openDocumentViewerModal: (state, action: PayloadAction<DocumentViewerModalParams>) => {
            state.documentViewer = {
                show: true,
                parameters: action.payload
            }
        },
        closeDocumentViewerModal: (state) => {
            state.documentViewer.show = false;
        },
        openSpecimenMeasurementOrdersModal: (state, action: PayloadAction<SpecimenMeasurementOrdersModalParams>) => {
            state.specimenMeasurementOrders = {
                show: true,
                parameters: action.payload
            }
        },
        closeSpecimenMeasurementOrdersModal: (state) => {
            state.specimenMeasurementOrders.show = false;
        },
    }
});
export const healthRecordModalsActions = {

};
export const healthRecordModalsSelectors = {

};