import { createSlice } from "@reduxjs/toolkit";
import { Models } from "../../../localComponents/types/models";
import { PersonDataRemoteState } from "../../types/reduxInterfaces";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../helpers/DefaultReducers";
import { createPersonDataApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { defaultQueryBuilder } from "../helpers/QueryBuilders";
import { createPersonDataSelectors } from "../helpers/HealthRecordEntrySliceSelectors";

export interface QuestionnaireAnswersSeriesState extends PersonDataRemoteState<Models.Interview.QuestionnaireAnswersSeries, {}> {}
const initialState: QuestionnaireAnswersSeriesState = {
    ...defaultRemoteInitialState(),
}
export const questionnaireAnswersSeriesSlice = createSlice({
    name: 'questionnaireAnswersSeries',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});
export const questionnaireAnswersSeriesActions = {
    ...createPersonDataApiActions(
        'questionnaireAnswersSeries', 
        questionnaireAnswersSeriesSlice.actions, 
        state => state.questionnaireAnswersSeries,
        defaultQueryBuilder,
        _ => true
    ),
};
export const questionnaireAnswersSeriesSelectors = {
    ...createPersonDataSelectors(state => state.questionnaireAnswersSeries),
};