import { FormEvent, useContext, useState } from 'react';
import { Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { AsyncButton } from '../../../sharedCommonComponents/components/AsyncButton';
import { showSuccessAlert } from '../../../sharedCommonComponents/helpers/AlertHelpers';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import { Models } from '../../types/models';
import PasswordFormControl from '../../../sharedCommonComponents/components/FormControls/PasswordFormControl';
import { sendPostRequest } from '../../../sharedCommonComponents/helpers/StoringHelpers';
import UserContext from '../../contexts/UserContext';

interface ChangePasswordFormProps {
    loginId: string;
    onPasswordChanged?: () => void;
}

export const ChangePasswordForm = (props: ChangePasswordFormProps) => {

    const { loginId, onPasswordChanged } = props;

    const user = useContext(UserContext)!;
    const [ currentPassword, setCurrentPassword ] = useState<string>('');
    const [ newPassword, setNewPassword ] = useState<string>('');
    const [ newPasswordRepeat, setNewPasswordRepeat ] = useState<string>('');
    const [ isSubmitting, setIsSubmitting ] = useState<boolean>(false);

    const submit = async (e?: FormEvent) => {
        e?.preventDefault();
        setIsSubmitting(true);
        const body: Models.RequestBodies.ChangePasswordRequest = {
            currentPassword: currentPassword,
            newPassword: newPassword
        };
        await sendPostRequest(
            `api/logins/${loginId}/changepassword`, {},
            resolveText("Account_CouldNotChangePassword"),
            body,
            async () => {
                showSuccessAlert(resolveText("Account_SuccessfullyChangedPassword"));
                if(onPasswordChanged) {
                    onPasswordChanged();
                }
            },
            undefined,
            () => setIsSubmitting(false)
        );
    };

    return (
        <Form validated={user.userSettings.forms.alwaysShowValidation} onSubmit={submit}>
            <FormGroup>
                <FormLabel>{resolveText("Account_CurrentPassword")}</FormLabel>
                <PasswordFormControl required
                    value={currentPassword}
                    onChange={(e:any) => setCurrentPassword(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <FormLabel>{resolveText("Account_NewPassword")}</FormLabel>
                <PasswordFormControl required
                    value={newPassword}
                    onChange={(e:any) => setNewPassword(e.target.value)}
                    minLength={8}
                />
            </FormGroup>
            <FormGroup>
                <FormLabel>{resolveText("Account_NewPasswordRepeat")}</FormLabel>
                <PasswordFormControl required
                    value={newPasswordRepeat}
                    onChange={(e:any) => setNewPasswordRepeat(e.target.value)}
                    isInvalid={!!newPassword && newPasswordRepeat !== newPassword}
                />
                <FormControl.Feedback type='invalid'>{resolveText("Register_PasswordsDontMatch")}</FormControl.Feedback>
            </FormGroup>
            <AsyncButton
                type='submit'
                isExecuting={isSubmitting}
                activeText={resolveText("Submit")}
                executingText={resolveText("Submitting...")}
                disabled={!currentPassword || !newPassword || newPasswordRepeat !== newPassword}
                className="m-3"
            />
        </Form>
    );

}