import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { postActionBuilder } from "../../../sharedHealthComponents/redux/helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { OrderDirection, SharedAccessType } from "../../types/enums";
import { Models } from "../../types/models";
import { ViewModels } from "../../types/viewModels";
import { RootState } from "../store/healthRecordStore";

export interface AccessesState extends RemoteState<ViewModels.AccessViewModel,Models.RequestBodies.SharedAccessRequestParameters> {}
const initialState: AccessesState = {
    ...defaultRemoteInitialState()
}
export const accessesSlice = createSlice({
    name: 'accesses',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
        revokeAccess: (state, action: PayloadAction<RevokeAccessArgs>) => {
            state.items = state.items.map(item => {
                if(item.id !== action.payload.itemId) {
                    return item;
                }
                return {
                    ...item,
                    isRevoked: true,
                    accessEndTimestamp: new Date().toISOString() as unknown as Date
                };
            });
        },
        markAsSeen: (state, action: PayloadAction<MarkAccessAsSeenArgs>) => {
            state.items = state.items.map(item => {
                if(item.id !== action.payload.itemId) {
                    return item;
                }
                return {
                    ...item,
                    hasBeenSeenBySharer: true
                };
            });
        },
    }
})

const accessesQueryBuilder = (state: RootState, sliceState: AccessesState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, accessesFilterComparer);
    const filter = sliceState.filter;
    if(filter.searchText) {
        queryParams.push({ key: 'searchText', value: filter.searchText });
    }
    if(filter.startTime) {
        queryParams.push({ key: 'startTime', value: filter.startTime as any });
    }
    if(filter.endTime) {
        queryParams.push({ key: 'endTime', value: filter.endTime as any });
    }
    if(filter.onlyActive) {
        queryParams.push({ key: 'onlyActive', value: filter.onlyActive ? 'true' : 'false' });
    }
    queryParams.push({ key: 'orderBy', value: 'time' });
    queryParams.push({ key: 'orderDirection', value: OrderDirection.Descending });
    return queryParams;
}
const accessesFilterComparer = (f1?: Models.RequestBodies.SharedAccessRequestParameters, f2?: Models.RequestBodies.SharedAccessRequestParameters) => {
    if(!f1 && !f2) {
        return true;
    }
    if(!f1 || !f2) {
        return false;
    }
    return f1.onlyActive === f2.onlyActive
        && f1.startTime === f2.startTime
        && f1.endTime === f2.endTime
        && f1.searchText === f2.searchText;
}
export interface RevokeAccessArgs {
    accessType: SharedAccessType;
    itemId: string;
}
export interface MarkAccessAsSeenArgs {
    accessType: SharedAccessType;
    itemId: string;
}
export const accessesActions = {
    ...createRestApiActions(
        'accesses',
        accessesSlice.actions,
        state => state.accesses,
        accessesQueryBuilder,
        accessesFilterComparer
    ),
    revokeAccess: postActionBuilder<RevokeAccessArgs,null>(
        args => `api/accesses/${args.accessType}/${args.itemId}/revoke`,
        () => resolveText("RevokeButton_CouldNotRevoke"),
        accessesSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(accessesSlice.actions.revokeAccess(args))
    ),
    markAsSeen: postActionBuilder<MarkAccessAsSeenArgs,null>(
        args => `api/accesses/${args.accessType}/${args.itemId}/markasseen`,
        () => resolveText("ISharedAccess_CouldNotMarkAsSeen"),
        accessesSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(accessesSlice.actions.markAsSeen(args))
    ),
};
export const accessesSelectors = {

};