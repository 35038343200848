import './localComponents/styles/App.css';
import './sharedCommonComponents/styles/common.css';
import './sharedHealthComponents/styles/healthrecord.css';

import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from './localComponents/components/Layout';
import UserContext from './localComponents/contexts/UserContext';
import { ViewModels } from './localComponents/types/viewModels';
import { apiClient } from './sharedCommonComponents/communication/ApiClient';
import { isAfter } from 'date-fns';
import { NoUserLayout } from './localComponents/components/NoUserLayout';
import { Models } from './localComponents/types/models';
import { reset, useAppDispatch, useAppSelector } from './localComponents/redux/store/healthRecordStore';
import { userSessionStorageKey, csrfTokenSessionStorageKey, featuresSessionStorageKey, accessTokenSessionStorageKey } from './sharedCommonComponents/helpers/Constants';
import FeatureContext, { noFeatures } from './localComponents/contexts/FeatureContext';
import { resolveText, setLanguage } from './sharedCommonComponents/helpers/Globalizer';
import { buildLoadObjectFunc } from './sharedCommonComponents/helpers/LoadingHelpers';
import RoutesBuilder from './sharedCommonComponents/navigation/RoutesBuilder';
import NoUserRoutes from './localComponents/navigation/NoUserRoutes';
import RegisteredUserRoutes from './localComponents/navigation/RegisteredUserRoutes';
import { AccountType } from './localComponents/types/enums';
import PageContainer from './sharedCommonComponents/components/PageContainer';
import { PasswordChangeModal } from './localComponents/modals/PasswordChangeModal';
import { ToastContainer } from 'react-toastify';
import { showErrorAlert } from './sharedCommonComponents/helpers/AlertHelpers';
import { getLocalStorageItem, getSessionStorageItem } from './sharedCommonComponents/helpers/BrowserStorageHelpers';
import { sessionSlice } from './localComponents/redux/slices/sessionSlice';
import { Session } from './localComponents/types/frontendTypes';

interface AppProps {}
export const App = (props: AppProps) => {

    const [ features, setFeatures ] = useState<Models.Configuration.FeatureSettings>(
        getSessionStorageItem<Models.Configuration.FeatureSettings>(featuresSessionStorageKey, {
            ...noFeatures,
            enableTaskList: true
        })!);
    const [ hasLoadedSession, setHasLoadedSession ] = useState<boolean>(false);
    const session = useAppSelector(state => state.session);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onNewAuthenticationResult = useCallback((authenticationResult: Models.AccessControl.AuthenticationResult) => {
        dispatch(sessionSlice.actions.setIsLoggedIn(authenticationResult.isAuthenticated));
        dispatch(sessionSlice.actions.setIsPasswordChangeRequired(authenticationResult.isPasswordChangeRequired));
        dispatch(sessionSlice.actions.setLoginId(authenticationResult.loginId));
        dispatch(sessionSlice.actions.setExpirationTime(authenticationResult.jwt?.expires));
        if (authenticationResult.isAuthenticated) {
            apiClient.instance!.isLoggedIn = true;
            apiClient.instance!.csrfToken = authenticationResult.csrfToken;
            apiClient.instance!.accessToken = authenticationResult.jwt!.rawAccessToken;
            apiClient.instance!.loginExpirationTime = authenticationResult.jwt!.expires;
            sessionStorage.setItem(csrfTokenSessionStorageKey, authenticationResult.csrfToken!);
            sessionStorage.setItem(accessTokenSessionStorageKey, authenticationResult.jwt!.rawAccessToken);
        }
    }, [ dispatch ]);

    const onAccountSelected = useCallback((userViewModel: ViewModels.IUserViewModel | null, redirectUrl?: string) => {
        if(userViewModel) {
            dispatch(sessionSlice.actions.setLoggedInUser(userViewModel));
        }
        navigate(redirectUrl ?? "/");
    }, [ dispatch, navigate ]);

    const onLogOut = useCallback(async () => {
        try {
            await apiClient.instance!.logOut();
        } catch {
            // Ignore
        } finally {
            localStorage.removeItem(userSessionStorageKey);
            dispatch(reset());
            navigate("/login");
        }
    }, [ dispatch, navigate ]);

    useEffect(() => {
        if(!session.loggedInUser) {
            return;
        }
        setLanguage(session.loggedInUser.userSettings.preferredLanguage);
        const loadFeatures = buildLoadObjectFunc(
            'api/configuration/features', {},
            resolveText("Features_CouldNotLoad"),
            setFeatures
        );
        loadFeatures();
    }, [ session ]);
    
    useEffect(() => {
        if(hasLoadedSession) {
            return;
        }
        const storedSession = getLocalStorageItem<Session>(userSessionStorageKey);
        if(storedSession) {
            dispatch(sessionSlice.actions.setSession(storedSession));
        }
        setHasLoadedSession(true);
    }, [ hasLoadedSession, dispatch ]);

    useEffect(() => {
        if(hasLoadedSession) {
            localStorage.setItem(userSessionStorageKey, JSON.stringify(session));
        }
    }, [ session ]);

    useEffect(() => {
        if(apiClient.instance!.isLoggedIn && apiClient.instance!.csrfToken !== undefined) {
            return;
        }
        const checkLoginStatusAndGetCsrfToken = async () => {
            if(!apiClient.instance!.isLoggedIn) {
                await apiClient.instance!.checkLoginStatus();
            }
            dispatch(sessionSlice.actions.setIsLoggedIn(apiClient.instance!.isLoggedIn));
            if(apiClient.instance!.isLoggedIn) {
                try {
                    const response = await apiClient.instance!.get('api/csrf');
                    if(response.ok) {
                        const csrfToken = await response.text();
                        apiClient.instance!.csrfToken = csrfToken;
                    }
                } catch {
                    showErrorAlert(resolveText("CSRF_CouldNotLoadCsrfToken"));
                }
            }
        };
        checkLoginStatusAndGetCsrfToken();
    }, []);
    

    if(session.expires && isAfter(new Date(), new Date(session.expires))) {
        onLogOut();
    }

    if(session.loginId && session.isPasswordChangeRequired) {
        return (<>
            <PasswordChangeModal
                loginId={session.loginId}
                onPasswordChanged={onLogOut}
            />
            <ToastContainer theme='colored' />
        </>);
    }

    if (!session.loggedInUser || !session.loggedInUser.accountId) {
        const routes = NoUserRoutes({ onNewAuthenticationResult, onLoggedIn: onAccountSelected, onLogOut, features });
        return (
            <NoUserLayout 
                isLoggingIn={session.isLoggedIn}
                onLogOut={onLogOut}
            >
                <RoutesBuilder
                    routeDefinitions={routes}
                    containerBuilder={children => <PageContainer
                        className='mt-3'
                    >
                        {children}
                    </PageContainer>}
                />
            </NoUserLayout>
        );
    }

    const routes = RegisteredUserRoutes({ user: session.loggedInUser!, features, onNewAuthenticationResult, onLogOut })
        .filter(route => !route.audience || route.audience.length === 0 || route.audience.includes(session.loggedInUser!.accountType as AccountType));
    

    return (
    <FeatureContext.Provider value={features}>
        <UserContext.Provider value={session.loggedInUser}>
            <Layout onLogOut={onLogOut}>
                <RoutesBuilder
                    routeDefinitions={routes}
                    containerBuilder={children => <PageContainer
                        className='mt-3'
                    >
                        {children}
                    </PageContainer>}
                />
            </Layout>
        </UserContext.Provider>
    </FeatureContext.Provider>);
}