import { UnknownAction, configureStore, combineReducers, ActionCreator, Action, Reducer } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { personsSlice } from '../../../sharedHealthComponents/redux/slices/personsSlice';
import { diagnosesSlice } from '../../../sharedHealthComponents/redux/slices/diagnosesSlice';
import { observationsSlice } from '../../../sharedHealthComponents/redux/slices/observationsSlice';
import { testResultsSlice } from '../../../sharedHealthComponents/redux/slices/testResultsSlice';
import { medicationSchedulesSlice } from '../../../sharedHealthComponents/redux/slices/medicationSchedulesSlice';
import { medicationDispensionsSlice } from '../../../sharedHealthComponents/redux/slices/medicationDispensionsSlice';
import { notesSlice } from '../../../sharedHealthComponents/redux/slices/notesSlice';
import { documentsSlice } from '../../../sharedHealthComponents/redux/slices/documentsSlice';
import { medicalProceduresSlice } from '../../../sharedHealthComponents/redux/slices/medicalProceduresSlice';
import { questionnaireAnswersSlice } from '../../../sharedHealthComponents/redux/slices/questionnaireAnswersSlice';
import { healthRecordsSlice } from '../../../sharedHealthComponents/redux/slices/healthRecordsSlice';
import { immunizationsSlice } from '../../../sharedHealthComponents/redux/slices/immunizationsSlice';
import { permissionsSlice } from '../../../sharedHealthComponents/redux/slices/permissionsSlice';
import { allergiesSlice } from '../../../sharedHealthComponents/redux/slices/allergiesSlice';
import { abbreviationsSlice } from '../../../sharedHealthComponents/redux/slices/abbreviationsSlice';
import { medicalTextTemplatesSlice } from '../../../sharedHealthComponents/redux/slices/medicalTextTemplatesSlice';
import { equipmentsSlice } from '../../../sharedHealthComponents/redux/slices/equipmentsSlice';
import { encountersSlice } from '../../../sharedHealthComponents/redux/slices/encountersSlice';
import { appointmentsSlice } from '../../../sharedHealthComponents/redux/slices/appointmentsSlice';
import { institutionsSlice } from '../slices/institutionsSlice';
import { institutionMembersSlice } from '../slices/institutionMembersSlice';
import { practitionersSlice } from '../../../sharedHealthComponents/redux/slices/practitionersSlice';
import { departmentsSlice } from '../slices/departmentsSlice';
import { specimensSlice } from '../../../sharedHealthComponents/redux/slices/specimensSlice';
import { opinionsSlice } from '../../../sharedHealthComponents/redux/slices/opinionsSlice';
import { personDataChangesSlice } from '../../../sharedHealthComponents/redux/slices/personDataChangesSlice';
import { relatedEntriesSlice } from '../../../sharedHealthComponents/redux/slices/relatedHealthRecordEntriesSlice';
import { drugsSlice } from '../../../sharedHealthComponents/redux/slices/drugsSlice';
import { studiesSlice } from '../slices/studiesSlice';
import { questionnairesSlice } from '../../../sharedHealthComponents/redux/slices/questionnairesSlice';
import { testPackagesSlice } from '../../../sharedHealthComponents/redux/slices/testPackagesSlice';
import { pregnanciesSlice } from '../../../sharedHealthComponents/redux/slices/pregnanciesSlice';
import { questionnaireAnswersSeriesSlice } from '../../../sharedHealthComponents/redux/slices/questionnaireAnswersSeriesSlice';
import { uploadCodesSlice } from '../slices/uploadCodesSlice';
import { tagsSlice } from '../../../sharedHealthComponents/redux/slices/tagsSlice';
import { accessesSlice } from '../slices/accessesSlice';
import { isolationsSlice } from '../../../sharedHealthComponents/redux/slices/isolationsSlice';
import { ordersSlice } from '../slices/ordersSlice';
import { shoppingCartSlice } from '../slices/shoppingCartSlice';
import { vouchersSlice } from '../slices/vouchersSlice';
import { shopItemsSlice } from '../slices/shopItemsSlice';
import { shippingMethodsSlice } from '../slices/shippingMethodsSlice';
import { healthRecordModalsSlice } from '../../../sharedHealthComponents/redux/slices/healthRecordModalsSlice';
import { accountsSlice } from '../slices/accountsSlice';
import { medicationRequestsSlice } from '../../../sharedHealthComponents/redux/slices/medicationRequestsSlice';
import { equipmentMeasurementsSlice } from '../../../sharedHealthComponents/redux/slices/equipmentMeasurementsSlice';
import { cprSlice } from '../../../sharedHealthComponents/redux/slices/cprSlice';
import { pharmacyOrdersSlice } from '../../../sharedHealthComponents/redux/slices/pharmacyOrdersSlice';
import { checklistAnswersSlice } from '../../../checklist/redux/checklistAnswersSlice';
import { checklistsSlice } from '../../../checklist/redux/checklistsSlice';
import { patientChecklistAnswersSlice } from '../../../sharedHealthComponents/redux/slices/patientChecklistAnswersSlice';
import { taskPackagesSlice } from '../../../sharedHealthComponents/redux/slices/taskPackagesSlice';
import { todolistSlice } from '../../../sharedHealthComponents/redux/slices/todolistSlice';
import { adverseEventsSlice } from '../../../sharedHealthComponents/redux/slices/adverseEventsSlice';
import { printTemplatesSlice } from '../../../sharedHealthComponents/redux/slices/printTemplatesSlice';
import { timeslotGeneratorsSlice } from '../../../sharedHealthComponents/redux/slices/timeslotGeneratorsSlice';
import { representationPermissionsSlice } from '../../../sharedHealthComponents/redux/slices/representationPermissionsSlice';
import { screenLockSlice } from '../../../sharedHealthComponents/redux/slices/screenLockSlice';
import { treatmentProcessesSlice } from '../../../sharedHealthComponents/redux/slices/treatmentProcessesSlice';
import { medicalAlertsSlice } from '../../../sharedHealthComponents/redux/slices/medicalAlertsSlice';
import { autocompleteItemsSlice } from '../../../sharedHealthComponents/redux/slices/autocompleteItemsSlice';
import { questionAnswersSlice } from '../../../sharedHealthComponents/redux/slices/questionAnswersSlice';
import { classificationsSlice } from '../../../sharedHealthComponents/redux/slices/classificationsSlice';
import { specimenMeasurementOrdersSlice } from '../../../sharedHealthComponents/redux/slices/specimenMeasurementOrdersSlice';
import { isItBrokenCasesSlice } from '../slices/isItBrokenCasesSlice';
import { sessionSlice } from '../slices/sessionSlice';
import { feedbackSlice } from '../slices/feedbackSlice';
import { healthRecordEntryEditLockSlice } from '../../../sharedHealthComponents/redux/slices/healthRecordEntryEditLockSlice';
import { customHealthRecordSummarySlice } from '../../../sharedHealthComponents/redux/slices/customHealthRecordSummarySlice';

const appReducer = combineReducers({
    session: sessionSlice.reducer,

    persons: personsSlice.reducer,
    practitioners: practitionersSlice.reducer,
    allergies: allergiesSlice.reducer,
    diagnoses: diagnosesSlice.reducer,
    medicalAlerts: medicalAlertsSlice.reducer,
    equipments: equipmentsSlice.reducer,
    observations: observationsSlice.reducer,
    specimens: specimensSlice.reducer,
    specimenMeasurementOrders: specimenMeasurementOrdersSlice.reducer,
    testResults: testResultsSlice.reducer,
    medicationSchedules: medicationSchedulesSlice.reducer,
    medicationDispensions: medicationDispensionsSlice.reducer,
    medicationRequests: medicationRequestsSlice.reducer,
    pharmacyOrders: pharmacyOrdersSlice.reducer,
    immunizations: immunizationsSlice.reducer,
    isolations: isolationsSlice.reducer,
    drugs: drugsSlice.reducer,
    notes: notesSlice.reducer,
    documents: documentsSlice.reducer,
    medicalProcedures: medicalProceduresSlice.reducer,
    questionnaires: questionnairesSlice.reducer,
    questionAnswers: questionAnswersSlice.reducer,
    questionnaireAnswers: questionnaireAnswersSlice.reducer,
    questionnaireAnswersSeries: questionnaireAnswersSeriesSlice.reducer,
    pregnancies: pregnanciesSlice.reducer,
    opinions: opinionsSlice.reducer,
    healthRecords: healthRecordsSlice.reducer,
    personDataChanges: personDataChangesSlice.reducer,
    permissions: permissionsSlice.reducer,
    relatedEntries: relatedEntriesSlice.reducer,
    testPackages: testPackagesSlice.reducer,
    treatmentProcesses: treatmentProcessesSlice.reducer,
    uploadCodes: uploadCodesSlice.reducer,
    tags: tagsSlice.reducer,
    customHealthRecordSummaries: customHealthRecordSummarySlice.reducer,
    healthRecordModals: healthRecordModalsSlice.reducer,
    healthRecordEntryEditLocks: healthRecordEntryEditLockSlice.reducer,
    cpr: cprSlice.reducer,
    isItBrokenCases: isItBrokenCasesSlice.reducer,

    classifications: classificationsSlice.reducer,
    abbreviations: abbreviationsSlice.reducer,
    medicalTextTemplates: medicalTextTemplatesSlice.reducer,
    encounters: encountersSlice.reducer,
    appointments: appointmentsSlice.reducer,
    accesses: accessesSlice.reducer,
    equipmentMeasurements: equipmentMeasurementsSlice.reducer,

    institutions: institutionsSlice.reducer,
    institutionMembers: institutionMembersSlice.reducer,
    departments: departmentsSlice.reducer,
    timeslotGenerators: timeslotGeneratorsSlice.reducer,
    adverseEvents: adverseEventsSlice.reducer,
    todoList: todolistSlice.reducer,
    tasksPackages: taskPackagesSlice.reducer,
    checklists: checklistsSlice.reducer,
    checklistAnswers: checklistAnswersSlice.reducer,
    patientChecklistAnswers: patientChecklistAnswersSlice.reducer,
    printTemplates: printTemplatesSlice.reducer,
    autocompleteItems: autocompleteItemsSlice.reducer,

    studies: studiesSlice.reducer,

    orders: ordersSlice.reducer,
    shoppingCart: shoppingCartSlice.reducer,
    vouchers: vouchersSlice.reducer,
    shopItems: shopItemsSlice.reducer,
    shipppingMethods: shippingMethodsSlice.reducer,

    accounts: accountsSlice.reducer,
    representationPermissions: representationPermissionsSlice.reducer,
    screenLock: screenLockSlice.reducer,
    feedback: feedbackSlice.reducer,
});
const rootReducer: Reducer<ReturnType<typeof appReducer>>  = (state, action) => {
    if(action.type === resetActionType) {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};
const resetActionType = "root/reset";
export const reset: ActionCreator<Action> = () => {
    return {
        type: resetActionType
    };
}


const healthRecordStore = configureStore({
    reducer: rootReducer
});

export type RootState = ReturnType<typeof healthRecordStore.getState>;
export type AppDispatch = ThunkDispatch<RootState, void, UnknownAction>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default healthRecordStore;