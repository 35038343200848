import { lazy } from 'react';
import { ChecklistRouteDefinition } from '../types/frontendTypes';

const ChecklistsListPage = lazy(() => import('../pages/ChecklistsListPage'));
const AnswerChecklistPage = lazy(() => import('../pages/AnswerChecklistPage'));
const CreateEditChecklistPage = lazy(() => import('../pages/CreateEditChecklistPage'));
const ChecklistAnswerListPage = lazy(() => import('../pages/ChecklistAnswerListPage'));

export const ChecklistRoutes = () => {

    const routes: ChecklistRouteDefinition[] = [
        { path: '/checklists', element: <ChecklistsListPage /> },
        { path: '/create/checklist', element: <CreateEditChecklistPage /> },
        { path: '/edit/checklist/:id', element: <CreateEditChecklistPage /> },
        { path: '/checklist/:checklistId', element: <AnswerChecklistPage /> },
        { path: '/checklist/:checklistId/answer', element: <AnswerChecklistPage /> },
        { path: '/checklist/:checklistId/answer/:answerId', element: <AnswerChecklistPage /> },
        { path: '/checklist/:checklistId/answers', element: <ChecklistAnswerListPage /> },
    ];

    return routes;
}
export default ChecklistRoutes;