import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { RemoteState } from "../../types/reduxInterfaces";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../helpers/DefaultReducers";
import { createRestApiActions } from "../helpers/GenericSliceActions";
import { QueryParameter } from "../../../sharedCommonComponents/types/frontendTypes";

export interface DiagnosticTestsPackagesFilter {
    searchText: string;
    includeMine: boolean;
    includePublic: boolean;
}
export interface DiagnosticTestsPackagesState extends RemoteState<ViewModels.DiagnosticTestsPackageViewModel, DiagnosticTestsPackagesFilter> {

}
const initialState: DiagnosticTestsPackagesState = {
    ...defaultRemoteInitialState(),
    filter: {
        searchText: '',
        includeMine: true,
        includePublic: false
    }
}
export const testPackagesSlice = createSlice({
    name: 'testPackages',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

const testPackagesQueryBuilder = (state: RootState, sliceState: DiagnosticTestsPackagesState) => {
    const queryParams: QueryParameter[] = [];
    const filter = sliceState.filter;
    queryParams.push({ key: 'count', value: '20'});
    queryParams.push({ key: 'IncludeOnlyMine', value: filter.includeMine && !filter.includePublic ? 'true' : 'false' });
    queryParams.push({ key: 'ExcludeMine', value: !filter.includeMine ? 'true' : 'false' });
    if(filter.searchText) {
        queryParams.push({ key: 'searchText', value: filter.searchText });
    }
    return queryParams;
}
export const testPackagesFilterComparer = (f1?: DiagnosticTestsPackagesFilter, f2?: DiagnosticTestsPackagesFilter) => {
    return f1?.includeMine === f2?.includeMine
        && f1?.includePublic === f2?.includePublic
        && f1?.searchText === f2?.searchText;
}
export const testPackagesActions = {
    ...createRestApiActions(
        'diagnosticTestsPackages',
        testPackagesSlice.actions,
        state => state.testPackages,
        testPackagesQueryBuilder,
        testPackagesFilterComparer
    ),
};
export const testPackagesSelectors = {

};