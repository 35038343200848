import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HealthRecordEntryType } from "../../../localComponents/types/enums";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { HealthRecordEventFilter } from "../../types/frontendTypes";
import { PersonDataRemoteState } from "../../types/reduxInterfaces";
import { postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";

interface EquipmentsState extends PersonDataRemoteState<ViewModels.HealthRecordEntries.AttachedEquipmentViewModel,HealthRecordEventFilter> {    
}
const initialState: EquipmentsState = {
    ...defaultRemoteInitialState(),
};
export const equipmentsSlice = createSlice({
    name: 'equipments',
    initialState: initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState),
        markEquipmentAsDetached: (state, action: PayloadAction<string>) => {
            const matchingItem = state.items.find(x => x.id === action.payload);
            if(matchingItem) {
                matchingItem.isDetached = true;
                matchingItem.detachmentTime = new Date().toISOString() as any;
            }
        }
    }
});

export const equipmentsActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.Equipment,
        'equipments', 
        equipmentsSlice.actions, 
        state => state.equipments,
        healthRecordEntryQueryBuilder,
        areHealthRecordEntryFiltersEqual
    ),
    markEquipmentAsDetached: postActionBuilder(
        (equipmentId: string) => `api/equipments/${equipmentId}/detach`,
        () => resolveText("Equipment_CouldNotMarkAsDetached"),
        equipmentsSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(equipmentsSlice.actions.markEquipmentAsDetached(args))
    )
}
export const equipmentsSelectors = {
    ...createHealthRecordEntrySelectors(state => state.equipments),
    createSelectAttachedForPerson: () => createSelector(
        (state: RootState) => state.equipments.items,
        (_: RootState, args: { personId: string | undefined }) => args.personId,
        (items, personId) => personId
            ? items
                .filter(x => x.personId === personId && !x.isDetached)
                .sort((a,b) => a.equipmentType.conceptId.localeCompare(b.equipmentType.conceptId))
            : []
    )
};