import { NavDropdown } from "react-bootstrap";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../../contexts/UserContext";

interface ToolsDropdownMenuProps {}

export const ToolsDropdownMenu = (props: ToolsDropdownMenuProps) => {

    const user = useContext(UserContext);
    const navigate = useNavigate();

    return (
        <NavDropdown title={resolveText("Tools")}>
            <NavDropdown.Item onClick={() => navigate("/tools/cpr")}>{resolveText("CPR")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigate("/tools/chat")}>{resolveText("TranslatedChat")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigate("/tools/checklists")}>{resolveText("Checklists")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigate(user? "/isitbroken/cases" : "/isitbroken")}>{resolveText("IsItBroken")}</NavDropdown.Item>
        </NavDropdown>
    );

}
export default ToolsDropdownMenu;