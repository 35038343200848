import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HealthRecordEntryType } from "../../../localComponents/types/enums";
import { Models } from "../../../localComponents/types/models";
import { showErrorAlert } from "../../../sharedCommonComponents/helpers/AlertHelpers";
import { deleteObject } from "../../../sharedCommonComponents/helpers/DeleteHelpers";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { sendPostRequest } from "../../../sharedCommonComponents/helpers/StoringHelpers";
import { getControllerName } from "../../helpers/HealthRecordEntryHelpers";
import { isHealthRecordEntryReferenceEqual } from "../../helpers/RelatedEntriesHelpers";
import { HealthRecordEntryLinkingSession, SliceStateBase } from "../../types/reduxInterfaces";
import { ApiPostActionCreator } from "../../types/reduxTypes";
import { createDefaultReducers } from "../helpers/DefaultReducers";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";

export interface RelatedHealthRecordEntriesState extends SliceStateBase {
    linkingSession?: HealthRecordEntryLinkingSession;
    linkedItems: Models.HealthRecordEntryReference[];
}
const initialState: RelatedHealthRecordEntriesState = {
    isLoading: false,
    isSubmitting: false,
    linkedItems: []
};
export const relatedEntriesSlice = createSlice({
    name: 'relatedEntries',
    initialState: initialState,
    reducers: {
        ...createDefaultReducers(initialState),
        startLinkingSession: (state, action: PayloadAction<StartLinkingSessionPayload>) => {
            state.linkingSession = action.payload.linkingSession;
            state.linkedItems = action.payload.existingRelatedItems;
        },
        addLinkedEntry: (state, action: PayloadAction<Models.HealthRecordEntryReference>) => {
            const newLink = action.payload;
            if(state.linkedItems.some(x => isHealthRecordEntryReferenceEqual(x, newLink))) {
                return;
            }
            state.linkedItems.push(newLink);
        },
        removedLinkedEntry: (state, action: PayloadAction<Models.HealthRecordEntryReference>) => {
            const brokenLink = action.payload;
            state.linkedItems = state.linkedItems.filter(x => !isHealthRecordEntryReferenceEqual(x, brokenLink))
        },
        endLinkingSession: (state) => {
            state.linkingSession = undefined;
        }
    }
});
export interface LoadRelatedEntriesForEntryArguments {
    entryType: HealthRecordEntryType;
    entryId: string;
}
export interface StartLinkingSessionPayload {
    linkingSession: HealthRecordEntryLinkingSession;
    existingRelatedItems: Models.HealthRecordEntryReference[];
}
export interface LinkHealthRecordEntriesArgs {
    personId: string;
    targetEntryType: HealthRecordEntryType;
    targetEntryId: string;
    sourceEntryType?: HealthRecordEntryType;
    sourceEntryId?: string;
}
export interface UnlinkHealthRecordEntriesArgs {
    personId: string;
    targetEntryType: HealthRecordEntryType;
    targetEntryId: string;
    sourceEntryType?: HealthRecordEntryType;
    sourceEntryId?: string;
}
export const relatedEntriesActions = {
    addLink: (({ args, onSuccess, onFailure, onFinally }) => {
        return async (dispatch, getState) => {
            const state = getState();
            const linkingSession = state.relatedEntries.linkingSession;
            const sourceEntryType = args.sourceEntryType ?? linkingSession?.sourceEntryType;
            const sourceEntryId = args.sourceEntryId ?? linkingSession?.sourceEntryId;
            if(!sourceEntryType || !sourceEntryId) {
                showErrorAlert(resolveText("RelatedEntries_NoLinkingSessionInProgess"));
                return;
            }
            dispatch(relatedEntriesSlice.actions.setIsSubmitting(true));
            const controllerName = getControllerName(sourceEntryType);
            await sendPostRequest(
                `api/${controllerName}/${sourceEntryId}/link-to/${args.targetEntryType}/${args.targetEntryId}`, {},
                resolveText("RelatedEntries_CouldNotLink"),
                null,
                async () => {
                    dispatch(relatedEntriesSlice.actions.addLinkedEntry({
                        entryType: args.targetEntryType,
                        entryId: args.targetEntryId
                    }));
                    if(onSuccess) {
                        onSuccess();
                    }
                },
                async response => {
                    const serverErrorText = response ? await response.text() : undefined;
                    showErrorAlert(resolveText("RelatedEntries_CouldNotLink"), serverErrorText);
                    if(onFailure) {
                        onFailure();
                    }
                },
                () => {
                    dispatch(relatedEntriesSlice.actions.setIsSubmitting(false));
                    if(onFinally) {
                        onFinally();
                    }
                }
            );
        }
    }) as ApiPostActionCreator<LinkHealthRecordEntriesArgs,never>,
    removeLink: (({ args, onSuccess, onFailure, onFinally }) => {
        return async (dispatch, getState) => {
            const state = getState();
            const linkingSession = state.relatedEntries.linkingSession;
            const sourceEntryType = args.sourceEntryType ?? linkingSession?.sourceEntryType;
            const sourceEntryId = args.sourceEntryId ?? linkingSession?.sourceEntryId;
            if(!sourceEntryType || !sourceEntryId) {
                showErrorAlert(resolveText("RelatedEntries_NoLinkingSessionInProgess"));
                return;
            }
            dispatch(relatedEntriesSlice.actions.setIsSubmitting(true));
            const controllerName = getControllerName(sourceEntryType);
            await deleteObject(
                `api/${controllerName}/${sourceEntryId}/link-to/${args.targetEntryType}/${args.targetEntryId}`, {},
                resolveText("RelatedEntries_SuccessfullyUnlinked"),
                resolveText("RelatedEntries_CouldNotUnlink"),
                () => {
                    dispatch(relatedEntriesSlice.actions.removedLinkedEntry({
                        entryType: args.targetEntryType,
                        entryId: args.targetEntryId
                    }));
                    if(onSuccess) {
                        onSuccess();
                    }
                },
                onFailure,
                () => {
                    dispatch(relatedEntriesSlice.actions.setIsSubmitting(false));
                    if(onFinally) {
                        onFinally();
                    }
                }
            );
        }
    }) as ApiPostActionCreator<UnlinkHealthRecordEntriesArgs,never>,
};
export const relatedEntriesSelectors = {
    createSelectIsLinked: () => createSelector(
        (state: RootState) => state.relatedEntries.linkedItems,
        (_: RootState, args: { entryReference: Models.HealthRecordEntryReference }) => args.entryReference,
        (linkedItems, entryReference) => linkedItems.some(x => isHealthRecordEntryReferenceEqual(x, entryReference))
    ),
};