import { createSelector, createSlice } from "@reduxjs/toolkit";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { ViewModels } from "../../types/viewModels";
import { DepartmentsFilter } from "../../../sharedHealthComponents/types/frontendTypes";
import { areEquivalent } from "../../../sharedCommonComponents/helpers/CollectionHelpers";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { RootState } from "../store/healthRecordStore";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

interface DepartmentsState extends RemoteState<ViewModels.DepartmentViewModel, DepartmentsFilter> {}
const initialState: DepartmentsState = {
    ...defaultRemoteInitialState(),
};
export const departmentsSlice = createSlice({
    name: 'departments',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

const departmentQueryBuilder = (state: RootState, sliceState: RemoteState<ViewModels.DepartmentViewModel,DepartmentsFilter>) => {
    const queryParams = defaultQueryBuilder(state, sliceState, departmentFilterComparar);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.searchText) {
            queryParams.push({ key: 'searchText', value: filter.searchText });
        }
        if(filter.institutionId) {
            queryParams.push({ key: 'institutionId', value: filter.institutionId });
        }
        if(filter.departmentTypes) {
            for (const departmentType of filter.departmentTypes) {
                queryParams.push({ key: 'departmentTypes', value: departmentType });
            }
        }
    }
    return queryParams;
}

export const departmentFilterComparar = (f1?: DepartmentsFilter, f2?: DepartmentsFilter) => {
    if(!areFiltersEqual(f1,f2)) {
        return false;
    }
    return f1!.searchText === f2!.searchText
        && f1!.institutionId === f2!.institutionId
        && areEquivalent(f1!.departmentTypes, f2!.departmentTypes);
}

export const departmentsActions = {
    ...createRestApiActions(
        'departments',
        departmentsSlice.actions,
        state => state.departments,
        departmentQueryBuilder,
        departmentFilterComparar
    ),
};
export const departmentsSelectors = {
    ...createDefaultGenericItemSelectors(state => state.departments),
    createForInstitution: () => createSelector(
        (state: RootState) => state.departments.items,
        (_: RootState, args: { institutionId?: string}) => args.institutionId,
        (items, institutionId) => institutionId 
            ? items.filter(x => x.institutionId === institutionId)
            : items
    ),
};