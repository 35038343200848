import { createSlice } from "@reduxjs/toolkit";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { HealthRecordEntryType } from "../../../localComponents/types/enums";
import { PersonDataRemoteState } from "../../types/reduxInterfaces";
import { HealthRecordEventFilter, MedicalTreatmentProcessViewModels } from "../../types/frontendTypes";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { Models } from "../../../localComponents/types/models";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";

interface TreatmentProcessesState extends PersonDataRemoteState<MedicalTreatmentProcessViewModels,HealthRecordEventFilter> {
}

const initialState: TreatmentProcessesState = {
    ...defaultRemoteInitialState(),
}

export const treatmentProcessesSlice = createSlice({
    name: 'treatmentProcesses',
    initialState: initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState)
    }
});

const treatmentProcessFilterComparer = (f1?: HealthRecordEventFilter, f2?: HealthRecordEventFilter) => {
    if(!areHealthRecordEntryFiltersEqual(f1, f2)) {
        return false;
    }
    return true;
}
const treatmentProcessQueryBuilder = (state: RootState, sliceState: TreatmentProcessesState) => {
    const queryParams = healthRecordEntryQueryBuilder(state, sliceState, treatmentProcessFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        // TODO
    }
    return queryParams;
}
interface WorkupFinalizeArgs {
    workupId: string;
}
export const treatmentProcessesActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.Process,
        'treatmentProcesses',
        treatmentProcessesSlice.actions,
        state => state.treatmentProcesses,
        treatmentProcessQueryBuilder,
        treatmentProcessFilterComparer
    ),
    finalizeWorkup: postActionBuilder<WorkupFinalizeArgs,Models.RequestBodies.WorkupFinalizationRequest>(
        (args: WorkupFinalizeArgs) => `api/workup/${args.workupId}/finalize`,
        () => resolveText("Workup_CouldNotFinalize"),
        treatmentProcessesSlice.actions.setIsSubmitting,
        (dispatch,response,args) => dispatch(treatmentProcessesSlice.actions.addOrUpdateItem(response))
    )
};
export const treatmentProcessesSelectors = {
    ...createHealthRecordEntrySelectors(state => state.treatmentProcesses),
};