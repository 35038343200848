import { lazy } from 'react';
import HealthRecordRoutes from '../../sharedHealthComponents/navigation/HealthRecordRoutes';
import { AccountType } from '../types/enums';
import { Models } from '../types/models';
import { RouteDefinition } from '../../sharedCommonComponents/types/frontendTypes';
import { ShehrdRouteDefinition } from '../types/frontendTypes';

const HealthRecordPage = lazy(() => import('../../localComponents/pages/Sharer/HealthRecordPage'));
const AddTaskOrChecklistPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/AddTaskOrChecklistPage'));

export const ShehrdHealthRecordRoutes = (features: Models.Configuration.FeatureSettings) => {

    const healthRecordRoutes = HealthRecordRoutes(features.healthRecord);
    const routes: ShehrdRouteDefinition[] = [
        ...healthRecordRoutes.map(route => ({
            ...route,
            audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.EmergencyGuest, AccountType.Researcher ]
        }) as RouteDefinition),
        { path: '/', usesCustomLayout: true, element: <HealthRecordPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.EmergencyGuest, AccountType.Researcher ]},
    ];
    if(features.enableTaskList) {
        const taskRoutes: ShehrdRouteDefinition[] = [
            { path: 'add/task', element: <AddTaskOrChecklistPage />, audience: [ AccountType.HealthProfessional ]},
        ];
        routes.push(...taskRoutes);
    }

    return routes;
}
export default ShehrdHealthRecordRoutes;