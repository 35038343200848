import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { loadItemsActionBuilder, postActionBuilder } from "../../../sharedHealthComponents/redux/helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultPersonDataReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { areFiltersEqual } from "../../helpers/FilterHelpers";
import { AppointmentFilter } from "../../types/frontendTypes";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { AppointmentStatus } from "../../../localComponents/types/enums";
import { createPersonDataApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { encountersSlice } from "./encountersSlice";
import { createDefaultGenericItemSelectors } from "../helpers/GenericSliceSelectors";

interface AppointmentsState extends RemoteState<ViewModels.AppointmentViewModel, AppointmentFilter> {}
interface ChangeAppointmentStatusArgs {
    appointmentId: string;
    newStatus: AppointmentStatus;
}
const initialState: AppointmentsState = {
    ...defaultRemoteInitialState(),
};
export const appointmentsSlice = createSlice({
    name: 'appointments',
    initialState: initialState,
    reducers: {
        ...createDefaultPersonDataReducers(initialState),
        setItemsWithoutPersonFilter: (state, action: PayloadAction<ViewModels.AppointmentViewModel[]>) => {
            state.items = action.payload;
        },
        addOrUpdateItemsWithoutPersonFilter: (state, action: PayloadAction<ViewModels.AppointmentViewModel[]>) => {
            const items = action.payload;
            const existingIds = state.items.map(x => x.id);
            const newItems = items.filter(item => !existingIds.includes(item.id));
            const existingUpdatedItems = state.items.map(existingItem => {
                const updatedItem = items.find(x => x.id === existingItem.id);
                return updatedItem ?? existingItem;
            });
            state.items = existingUpdatedItems.concat(newItems);
        },
        changeStatus: (state, action: PayloadAction<ChangeAppointmentStatusArgs>) => {
            const encounter = state.items.find(x => x.id === action.payload.appointmentId);
            if(!encounter) {
                return;
            }
            encounter.status = action.payload.newStatus;
        },
    }
});

const appointmentQueryBuilder = (state: RootState, sliceState: AppointmentsState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, appointmentFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.searchText) {
            queryParams.push({ key: 'searchText', value: filter.searchText });
        }
        if(filter.institutionId) {
            queryParams.push({ key: 'institutionId', value: filter.institutionId });
        }
        if(filter.departmentId) {
            queryParams.push({ key: 'departmentId', value: filter.departmentId });
        }
        if(filter.status) {
            queryParams.push({ key: 'status', value: filter.status });
        }
        if(filter.timeRange) {
            if(filter.timeRange.start) {
                queryParams.push({ key: 'timeRangeStart', value: filter.timeRange.start as any });
            }
            if(filter.timeRange.end) {
                queryParams.push({ key: 'timeRangeEnd', value: filter.timeRange.end as any });
            }
        }
    }
    return queryParams;
}
const appointmentFilterComparer = (f1?: AppointmentFilter, f2?: AppointmentFilter) => {
    if(!areFiltersEqual(f1,f2)) {
        return false;
    }
    return f1!.searchText === f2!.searchText
        && f1!.institutionId === f2!.institutionId
        && f1!.departmentId === f2!.departmentId
        && f1!.status === f2!.status
        && f1!.timeRange?.start === f2!.timeRange?.start
        && f1!.timeRange?.end === f2!.timeRange?.end;
}

export const appointmentsActions = {
    ...createPersonDataApiActions(
        'appointments',
        appointmentsSlice.actions,
        state => state.appointments,
        appointmentQueryBuilder,
        appointmentFilterComparer
    ),
    loadForInstitution: loadItemsActionBuilder(
        (args?: { institutionId: string}) => `api/institutions/${args!.institutionId}/appointments`,
        appointmentQueryBuilder,
        appointmentFilterComparer,
        () => resolveText("Appointments_CouldNotLoad"),
        appointmentsSlice.actions.setIsLoading,
        appointmentsSlice.actions.setItemsWithoutPersonFilter,
        appointmentsSlice.actions.addOrUpdateItemsWithoutPersonFilter,
        appointmentsSlice.actions.setHasMoreItems,
        appointmentsSlice.actions.setLastUsedFilter,
        state => state.appointments
    ),
    changeStatus: postActionBuilder(
        (args: ChangeAppointmentStatusArgs) => `api/appointments/${args!.appointmentId}/changestatus`,
        () => resolveText('Appointment_CouldNotChangeStatus'),
        appointmentsSlice.actions.setIsSubmitting,
        (dispatch,response,args) => dispatch(appointmentsSlice.actions.addOrUpdateItem(response))
    ),
    createEncounter: postActionBuilder(
        (args: { appointmentId: string}) => `api/appointments/${args.appointmentId}/create-encounter`,
        () => resolveText("Appointment_CouldNotCreateEncounter"),
        appointmentsSlice.actions.setIsSubmitting,
        (dispatch,response,_) => {
            if(response) {
                dispatch(encountersSlice.actions.addOrUpdateItem(response));
            }
        }
    )
};
export const appointmentsSelectors = {
    ...createDefaultGenericItemSelectors(state => state.appointments),
};