import { createSelector, createSlice } from "@reduxjs/toolkit";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { getActionBuilder, postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../helpers/DefaultReducers";
import { createRestApiActions } from "../helpers/GenericSliceActions";
import { RemoteState } from "../../types/reduxInterfaces";
import { OrderDirection } from "../../../localComponents/types/enums";
import { Models } from "../../../localComponents/types/models";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { TodoListFilter } from "../../types/frontendTypes";
import { createDefaultGenericItemSelectors } from "../helpers/GenericSliceSelectors";
import { areFiltersEqual } from "../../helpers/FilterHelpers";

export interface TodoListState extends RemoteState<ViewModels.TodoListItemViewModel,TodoListFilter> {
}
const initialState: TodoListState = {
    ...defaultRemoteInitialState(),
    filter: {
        orderBy: 'priority',
        orderDirection: OrderDirection.Descending,
        onlyUnfinished: true
    }
}
export const todolistSlice = createSlice({
    name: 'todolist',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
        sortByPriorityDescending: (state) => {
            state.items.sort((a,b) => (b.priority ?? 0) - (a.priority ?? 0));
        }
    }
});

export const todoListQueryBuilder = (state: RootState, sliceState: TodoListState) => {
    const filter = sliceState.filter;
    const queryParams: { [key:string]: string } = {
        'count': '20',
        'orderBy': filter.orderBy ?? 'priority',
        'orderDirection': filter.orderDirection ?? OrderDirection.Descending
    };
    if(filter.patientId) {
        queryParams.patientId = filter.patientId;
    }
    if(filter.departmentId) {
        queryParams.departmentId = filter.departmentId;
    }
    if(filter.searchText) {
        queryParams.searchText = filter.searchText;
    }
    if(filter.assignee) {
        queryParams.assigneeType = filter.assignee.type;
        queryParams.assigneeEntityId = filter.assignee.entityId;
    }
    if(filter.status) {
        queryParams.status = filter.status;
    }
    if(filter.onlyUnfinished) {
        queryParams.onlyUnfinished = 'true';
    }
    if(filter.onlyMine) {
        queryParams.onlyMine = 'true';
    }
    if(filter.onlyUnassigned) {
        queryParams.onlyUnassigned = 'true';
    }
    return queryParams;
}
export const todoListFilterComparer = (f1?: TodoListFilter, f2?: TodoListFilter) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.patientId === f2!.patientId
        && f1!.departmentId === f2!.departmentId
        && f1!.searchText === f2!.searchText
        && f1!.status === f2!.status
        && f1!.assignee?.type === f2!.assignee?.type
        && f1!.assignee?.entityId === f2!.assignee?.entityId
        && f1!.onlyMine === f2!.onlyMine
        && f1!.onlyUnfinished === f2!.onlyUnfinished
        && f1!.onlyUnassigned === f2!.onlyUnassigned;
}
export const todoListActions = {
    ...createRestApiActions(
        'todolist',
        todolistSlice.actions,
        state => state.todoList,
        todoListQueryBuilder,
        todoListFilterComparer
    ),
    loadSubTasks: getActionBuilder<{ taskId: string; },ViewModels.TodoListItemViewModel[]>(
        args => `api/todolist/tasks/${args!.taskId}/subtasks`,
        () => resolveText("TodoListItem_CouldNotLoadSubtasks"),
        todolistSlice.actions.setIsSubmitting,
        (dispatch,items,args) => dispatch(todolistSlice.actions.addOrUpdateItems(items))
    ),
    changeStatus: postActionBuilder<string,Models.RequestBodies.ChangeTodoListItemStatusBody>(
        (args: string) => `api/todolist/${args}/status`,
        () => resolveText("TodoList_CouldNotChangeStatus"),
        todolistSlice.actions.setIsSubmitting,
        (dispatch,response,_) => dispatch(todolistSlice.actions.addOrUpdateItem(response))
    ),
    changePriority: postActionBuilder<string,Models.RequestBodies.ChangeTodoListItemPriorityBody>(
        (args: string) => `api/todolist/${args}/priority`,
        () => resolveText("TodoList_CouldNotChangePriority"),
        todolistSlice.actions.setIsSubmitting,
        (dispatch,response,_) => {
            dispatch(todolistSlice.actions.addOrUpdateItem(response));
            dispatch(todolistSlice.actions.sortByPriorityDescending());
        }
    ),
    assign: postActionBuilder<string,Models.RequestBodies.AssignTodoListItemBody>(
        args => `api/todolist/${args}/assign`,
        () => resolveText("TodoListItem_CouldNotAssign"),
        todolistSlice.actions.setIsSubmitting,
        (dispatch,response,_) => dispatch(todolistSlice.actions.addOrUpdateItem(response))
    )
};
export const todoListSelectors = {
    ...createDefaultGenericItemSelectors(state => state.todoList),
    createSelectRelatedTasks: () => createSelector(
        (state: RootState) => state.todoList.items,
        (_: RootState, args: { taskIds: string[] }) => args.taskIds,
        (items, taskIds) => items.filter(x => taskIds.includes(x.id))
    ),
    createSelectSubTasks: () => createSelector(
        (state: RootState) => state.todoList.items,
        (_: RootState, args: { taskId: string | undefined }) => args.taskId,
        (items, taskId) => taskId ? items.filter(x => x.parentTaskId === taskId) : []
    )
};