import { createSlice } from "@reduxjs/toolkit";
import { HealthRecordEntryType } from "../../../localComponents/types/enums";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { PatientDocumentFilter } from "../../types/frontendTypes";
import { PersonDataRemoteState } from "../../types/reduxInterfaces";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { areEquivalent } from "../../../sharedCommonComponents/helpers/CollectionHelpers";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";

interface DocumentsState extends PersonDataRemoteState<ViewModels.HealthRecordEntries.PatientDocumentViewModel,PatientDocumentFilter> {
}
const initialState: DocumentsState = {
    ...defaultRemoteInitialState(),
};

export const documentsSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState),
    }
});

const documentFilterComparer = (f1?: PatientDocumentFilter, f2?: PatientDocumentFilter) => {
    if(!areHealthRecordEntryFiltersEqual(f1, f2)) {
        return false;
    }
    return areEquivalent(f1!.tags, f2!.tags);
}
const documentQueryBuilder = (state: RootState, sliceState: DocumentsState) => {
    const queryParams = healthRecordEntryQueryBuilder(state, sliceState, documentFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.tags && filter.tags.length > 0) {
            for (const tag of filter.tags) {
                queryParams.push({ key: 'tags', value: tag});
            }
        }
    }
    return queryParams;
}

export const documentsActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.Document,
        'documents', 
        documentsSlice.actions, 
        state => state.documents, 
        documentQueryBuilder,
        documentFilterComparer
    ),
}
export const documentsSelectors = {
    ...createHealthRecordEntrySelectors(state => state.documents),
};