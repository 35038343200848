import { AccountType, LegalEntityType } from "../types/enums"
import { Models } from "../types/models";
import { ViewModels } from "../types/viewModels";

export const isPersonAccount = (accountType: string) => {
    return [ 
        AccountType.Sharer, 
        AccountType.HealthProfessional, 
        AccountType.Researcher, 
        AccountType.Pharmacy, 
        AccountType.Admin 
    ].map(x => x as string).includes(accountType);
}
export const isProfessionalAccount = (accountType: string) => {
    return [ 
        AccountType.HealthProfessional, 
        AccountType.Researcher, 
        AccountType.Pharmacy,
        AccountType.Admin
    ].map(x => x as string).includes(accountType);
}
export const isLegalEntityReferenceMatchingUser = (legalEntityReference: Models.LegalEntityReference, user: ViewModels.IUserViewModel) => {
    if(!user) {
        return false;
    }
    switch(legalEntityReference.type) {
        case LegalEntityType.Person:
            const personUserProfile = user as ViewModels.IPersonUserViewModel;
            if(!personUserProfile?.profileData?.personId) {
                return false;
            }
            return legalEntityReference.entityId === personUserProfile.profileData.personId;
        case LegalEntityType.Practitioner:
            const professionalUser = user as ViewModels.IProfessionalUserViewModel;
            if(!professionalUser.professionalProfile?.id) {
                return false;
            }
            return legalEntityReference.entityId === professionalUser.professionalProfile.id;
        case LegalEntityType.Institution:
        case LegalEntityType.Guest:
        case LegalEntityType.Unknown:
        default:
            return false;
    }
}