import { createSlice } from "@reduxjs/toolkit";
import { Models } from "../../../localComponents/types/models";
import { RemoteState } from "../../types/reduxInterfaces";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../helpers/DefaultReducers";
import { createRestApiActions } from "../helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../helpers/QueryBuilders";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { areFiltersEqual } from "../../helpers/FilterHelpers";
import { PractitionerFilter } from "../../types/frontendTypes";
import { createDefaultGenericItemSelectors } from "../helpers/GenericSliceSelectors";

interface PractitionersState extends RemoteState<Models.Individuals.Practitioner, PractitionerFilter> {}
const initialState: PractitionersState = {
    ...defaultRemoteInitialState(),
};
export const practitionersSlice = createSlice({
    name: 'practitioners',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

const practitionerQueryBuilder = (state: RootState, sliceState: PractitionersState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, practitionerFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.searchText) {
            queryParams.push({ key: 'searchText', value: filter.searchText });
        }
    }
    return queryParams;
}
const practitionerFilterComparer = (f1?: PractitionerFilter, f2?: PractitionerFilter) => {
    if(!areFiltersEqual(f1,f2)) {
        return false;
    }
    return f1!.searchText === f2!.searchText;
}

export const practitionersActions = {
    ...createRestApiActions(
        'practitioners', 
        practitionersSlice.actions,
        state => state.practitioners,
        practitionerQueryBuilder,
        practitionerFilterComparer
    ),
};
export const practitionersSelectors = {
    ...createDefaultGenericItemSelectors(state => state.practitioners),
};