import { NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";

interface AboutMenuProps {}

export const AboutMenu = (props: AboutMenuProps) => {

    const navigate = useNavigate();

    return (<NavDropdown title={resolveText("About")}>
        <NavDropdown.Item onClick={() => navigate("/faq")}>{resolveText("FAQ")}</NavDropdown.Item>
        <NavDropdown.Item onClick={() => navigate("/about/ehds")}>European Health Data Space</NavDropdown.Item>
        <NavDropdown.Item onClick={() => navigate("/privacy")}>{resolveText("Privacy")}</NavDropdown.Item>
        <NavDropdown.Item onClick={() => navigate("/terms-of-service")}>{resolveText("TermsAndConditions")}</NavDropdown.Item>
        <NavDropdown.Item onClick={() => navigate("/contact")}>{resolveText("Contact")}</NavDropdown.Item>
    </NavDropdown>);

}