import { createSlice } from "@reduxjs/toolkit";
import { HealthRecordEntryType } from "../../../localComponents/types/enums";
import { Models } from "../../../localComponents/types/models";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { HealthRecordEventFilter } from "../../types/frontendTypes";
import { PersonDataRemoteState } from "../../types/reduxInterfaces";
import { postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";

interface DiagnosesState extends PersonDataRemoteState<ViewModels.HealthRecordEntries.DiagnosisViewModel,HealthRecordEventFilter> {
}

const initialState: DiagnosesState = {
    ...defaultRemoteInitialState(),
}

export const diagnosesSlice = createSlice({
    name: 'diagnoses',
    initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState),
    }
});

export interface ChangeDiagnosisStatusArgs {
    diagnosisId: string;
    status: Models.Diagnoses.ConditionStatusChange;
}
export const diagnosesActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.Diagnosis,
        'diagnoses', 
        diagnosesSlice.actions, 
        state => state.diagnoses,
        healthRecordEntryQueryBuilder,
        areHealthRecordEntryFiltersEqual
    ),
    changeStatus: postActionBuilder<string,Models.Diagnoses.ConditionStatusChange>(
        diagnosisId => `api/diagnoses/${diagnosisId}/status`,
        () => resolveText("Diagnosis_CouldNotChangeStatus"),
        diagnosesSlice.actions.setIsSubmitting,
        (dispatch,response) => dispatch(diagnosesSlice.actions.addOrUpdateItem(response))
    )
};
export const diagnosesSelectors = {
    ...createHealthRecordEntrySelectors(state => state.diagnoses),
};