import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Session } from "../../types/frontendTypes";
import { ViewModels } from "../../types/viewModels";
import { Language } from "../../types/enums";
import { Models } from "../../types/models";

const initialState: Session = {
    isLoggedIn: false
};
export const sessionSlice = createSlice({
    name: 'session',
    initialState: initialState,
    reducers: {
        setSession: (_, action: PayloadAction<Session>) => {
            return action.payload;
        },
        setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload;
        },
        setIsPasswordChangeRequired: (state, action: PayloadAction<boolean>) => {
            state.isPasswordChangeRequired = action.payload;
        },
        setLoggedInUser: (state, action: PayloadAction<ViewModels.IUserViewModel>) => {
            state.loggedInUser = action.payload;
        },
        setPreferredLanguage: (state, action: PayloadAction<Language>) => {
            if(state.loggedInUser) {
                state.loggedInUser.userSettings.preferredLanguage = action.payload;
            }
        },
        setPractitioner: (state, action: PayloadAction<Models.Individuals.Practitioner>) => {
            if(!state.loggedInUser) {
                return;
            }
            const professionalUserProfile = state.loggedInUser as ViewModels.IProfessionalUserViewModel;
            professionalUserProfile.professionalProfile = action.payload;
        },
        setLoginId: (state, action: PayloadAction<string | undefined>) => {
            state.loginId = action.payload;
        },
        setExpirationTime: (state, action: PayloadAction<Date | undefined>) => {
            state.expires = action.payload;
        },
        setMultiFactorAuthenticationStatus: (state, action: PayloadAction<boolean>) => {
            if(state.loggedInUser) {
                state.loggedInUser.requiresMultiFactorAuthentication = action.payload;
            }
        }
    }
});