import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Models } from "../../../localComponents/types/models";
import { groupIntoDictionary } from "../../../sharedCommonComponents/helpers/CollectionHelpers";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { getAbbreviationCode } from "../../helpers/MedicalTextEditor/AbbreviationHelpers";
import { AbbreviationLookup, SelectedAbbreviations } from "../../types/medicalTextEditorTypes";
import { SliceStateBase } from "../../types/reduxInterfaces";
import { getActionBuilder, postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { createDefaultReducers } from "../helpers/DefaultReducers";
import { Language } from "../../../localComponents/types/enums";

interface AbbreviationsState extends SliceStateBase {
    items: AbbreviationLookup;
    selectedItems: SelectedAbbreviations;
}
const initialState: AbbreviationsState = {
    isLoading: false,
    isSubmitting: false,
    items: {},
    selectedItems: {}
};

export const abbreviationsSlice = createSlice({
    name: 'abbreviations',
    initialState: initialState,
    reducers: {
        ...createDefaultReducers(initialState),
        setAbbreviations: (state, action: PayloadAction<Models.MedicalTextEditor.PersonalizedAbbreviation[]>) => {
            state.items = groupIntoDictionary(action.payload, x => x.abbreviation);
            state.selectedItems = {};
            for(const abbrevationCode in state.items) {
                const matchingAbbreviations = state.items[abbrevationCode];
                const selectedAbbreviation = matchingAbbreviations.find(x => x.isDefault) ?? matchingAbbreviations[0];
                state.selectedItems[abbrevationCode] = selectedAbbreviation;
            }
        },
        addOrUpdateAbbreviation: (state, action: PayloadAction<Models.MedicalTextEditor.PersonalizedAbbreviation>) => {
            const item = action.payload;
            addOrUpdateAbbreviation(state, item);
        },
        addOrUpdateAbbreviations: (state, action: PayloadAction<Models.MedicalTextEditor.PersonalizedAbbreviation[]>) => {
            const items = action.payload;
            for(const item of items) {
                addOrUpdateAbbreviation(state, item);
            }
        },
        removeAbbreviation: (state, action: PayloadAction<string>) => {
            delete state.items[action.payload];
        },
        setSelectAbbreviation: (state, action: PayloadAction<Models.MedicalTextEditor.PersonalizedAbbreviation>) => {
            const abbreviation = action.payload;
            const abbrevationCode = getAbbreviationCode(abbreviation);
            state.selectedItems[abbrevationCode] = abbreviation;
            addOrUpdateAbbreviation(state, abbreviation);
        },
        makeDefault: (state, action: PayloadAction<MakeAbbreviationDefaultArgs>) => {
            const { abbreviationId, abbreviationCode }  = action.payload;
            for(const item of state.items[abbreviationCode]) {
                item.isDefault = item.id === abbreviationId;
            }
            if(state.selectedItems[abbreviationCode]) {
                state.selectedItems[abbreviationCode].isDefault = state.selectedItems[abbreviationCode].id === abbreviationId;
            }
        }
    }
});

const addOrUpdateAbbreviation = (state: AbbreviationsState, item: Models.MedicalTextEditor.PersonalizedAbbreviation) => {
    const abbreviationCode = item.isCaseSensitive
        ? item.abbreviation
        : item.abbreviation.toLowerCase();
    if(state.items[abbreviationCode]) {
        if(state.items[abbreviationCode].some(x => x.id === item.id)) {
            state.items[abbreviationCode] = state.items[abbreviationCode].map(x => x.id === item.id ? item : x);
        } else {
            state.items[abbreviationCode].push(item);
        }
    } else {
        state.items[abbreviationCode] = [ item ];
        state.selectedItems[abbreviationCode] = item;
    }
}

export interface FindAbbreviationArgs {
    abbreviation: string;
    language: Language;
}
export interface MakeAbbreviationDefaultArgs {
    abbreviationId: string;
    abbreviationCode: string;
}
export const abbreviationsActions = {
    loadForCode: getActionBuilder<FindAbbreviationArgs,Models.MedicalTextEditor.PersonalizedAbbreviation[]>(
        (args?: FindAbbreviationArgs) => `api/abbreviations/${args!.language}/exact-match/${args!.abbreviation}`,
        () => resolveText("PersonalizedAbbreviation_CouldNotLoad"),
        abbreviationsSlice.actions.setIsLoading,
        (dispatch,response,_) => dispatch(abbreviationsSlice.actions.addOrUpdateAbbreviations(response))
    ),
    addItem: postActionBuilder(
        _ => 'api/abbreviations',
        () => resolveText("PersonalizedAbbreviation_CouldNotStore"),
        abbreviationsSlice.actions.setIsSubmitting,
        (dispatch,response) => dispatch(abbreviationsSlice.actions.addOrUpdateAbbreviation(response))
    ),
    setSelectedAbbreviation: (abbreviation: Models.MedicalTextEditor.PersonalizedAbbreviation) => abbreviationsSlice.actions.setSelectAbbreviation(abbreviation),
    makeAbbreviationDefault: postActionBuilder(
        (_: MakeAbbreviationDefaultArgs) => `api/abbreviations/make-default`,
        () => resolveText("PersonalizedAbbreviation_CouldNotMakeDefault"),
        abbreviationsSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(abbreviationsSlice.actions.makeDefault(args))
    )
}
export const abbreviationsSelectors = {

};