import { createSlice } from "@reduxjs/toolkit";
import { deleteObject } from "../../../sharedCommonComponents/helpers/DeleteHelpers";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { postActionBuilder, loadItemActionBuilder, loadItemIfNotYetLoadedActionBuilder, loadItemsActionBuilder } from "../../../sharedHealthComponents/redux/helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { LoadItemArgs, RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { ApiDeleteActionCreator } from "../../../sharedHealthComponents/types/reduxTypes";
import { ViewModels } from "../../types/viewModels";

interface InstitutionMembersState extends RemoteState<ViewModels.InstitutionMemberViewModel, {}> {
}
const initialState: InstitutionMembersState = {
    ...defaultRemoteInitialState(),
};
export const institutionMembersSlice = createSlice({
    name: 'institutionMembersSlice',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

export interface LoadInstitutionMembersArgs {
    institutionId: string;
}
export interface LoadInstitutionMemberArgs extends LoadItemArgs {
    institutionId: string;
}
export const institutionMembersActions = {
    loadItems: loadItemsActionBuilder(
        (args: LoadInstitutionMembersArgs) => `api/institutions/${args.institutionId}/members`,
        defaultQueryBuilder,
        _ => true,
        () => resolveText("GenericItems_CouldNotLoad"),
        institutionMembersSlice.actions.setIsLoading,
        institutionMembersSlice.actions.setItems,
        institutionMembersSlice.actions.addOrUpdateItems,
        institutionMembersSlice.actions.setHasMoreItems,
        institutionMembersSlice.actions.setLastUsedFilter,
        state => state.institutionMembers
    ),
    loadItem: loadItemActionBuilder(
        (args: LoadInstitutionMemberArgs) => `api/institutions/${args.institutionId}/members/${args.itemId}`,
        _ => ({}),
        () => resolveText("GenericItem_CouldNotLoad"),
        institutionMembersSlice.actions.setIsLoading,
        institutionMembersSlice.actions.addOrUpdateItem
    ),
    loadItemIfNotLoadedYet: loadItemIfNotYetLoadedActionBuilder(
        (args: LoadInstitutionMemberArgs) => `api/institutions/${args.institutionId}/members/${args.itemId}`,
        _ => ({}),
        () => resolveText("GenericItem_CouldNotLoad"),
        institutionMembersSlice.actions.setIsLoading,
        institutionMembersSlice.actions.addOrUpdateItem,
        state => state.institutionMembers
    ),
    addItem: postActionBuilder(
        (args: LoadInstitutionMembersArgs) => `api/institutions/${args.institutionId}/members`, 
        () => resolveText("GenericItem_CouldNotStore"),
        institutionMembersSlice.actions.setIsSubmitting,
        (dispatch,response) => dispatch(institutionMembersSlice.actions.addOrUpdateItem(response))
    ),
    deleteItem: (({ args, onSuccess, onFailure, onFinally }) => {
        return async (dispatch) => {
            await deleteObject(
                `api/institutions/${args.institutionId}/members/${args.itemId}`, {},
                resolveText("GenericItem_SuccessfullyDeleted"),
                resolveText("GenericItem_CouldNotDelete"),
                () => {
                    dispatch(institutionMembersSlice.actions.removeItem(args.itemId));
                    if(onSuccess) {
                        onSuccess();
                    }
                },
                onFailure,
                onFinally
            );
        }
    }) as ApiDeleteActionCreator<LoadInstitutionMemberArgs>
};
export const institutionMembersSelectors = {

};