import { createSlice } from "@reduxjs/toolkit";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { RemoteState } from "../../types/reduxInterfaces";
import { Models } from "../../../localComponents/types/models";
import { createDefaultGenericItemReducers } from "../helpers/DefaultReducers";
import { createRestApiActions } from "../helpers/GenericSliceActions";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { PharmacyOrderFilter } from "../../types/frontendTypes";
import { defaultQueryBuilder } from "../helpers/QueryBuilders";
import { areFiltersEqual } from "../../helpers/FilterHelpers";
import { createDefaultGenericItemSelectors } from "../helpers/GenericSliceSelectors";

export interface PharmacyOrdersState extends RemoteState<Models.Medication.PharmacyOrder, PharmacyOrderFilter> {
}

const initialState: PharmacyOrdersState = {
    ...defaultRemoteInitialState(),
}
export const pharmacyOrdersSlice = createSlice({
    name: 'medicationRequestFills',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

const pharmacyOrderQueryBuilder = (state: RootState, sliceState: PharmacyOrdersState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, pharmacyOrderFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.searchText) {
            queryParams.push({ key: 'searchText', value: filter.searchText });
        }
        if(filter.institutionId) {
            queryParams.push({ key: 'institutionId', value: filter.institutionId });
        }
    }
    return queryParams;
};
const pharmacyOrderFilterComparer = (f1?: PharmacyOrderFilter, f2?: PharmacyOrderFilter) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.searchText === f2!.searchText
        && f1!.institutionId === f2!.institutionId;
};
export const pharmacyOrdersActions = ({
    ...createRestApiActions(
        'pharmacyOrders',
        pharmacyOrdersSlice.actions,
        state => state.pharmacyOrders,
        pharmacyOrderQueryBuilder,
        pharmacyOrderFilterComparer
    ),
});
export const pharmacyOrdersSelectors = {
    ...createDefaultGenericItemSelectors(state => state.pharmacyOrders),
};