import { createSelector, createSlice } from "@reduxjs/toolkit";
import { createDefaultGenericItemReducers } from "../helpers/DefaultReducers";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { RemoteState } from "../../types/reduxInterfaces";
import { Models } from "../../../localComponents/types/models";
import { createRestApiActions } from "../helpers/GenericSliceActions";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { defaultQueryBuilder } from "../helpers/QueryBuilders";
import { areFiltersEqual } from "../../helpers/FilterHelpers";
import { EquipmentMeasurementFilter } from "../../types/frontendTypes";
import { createDefaultGenericItemSelectors } from "../helpers/GenericSliceSelectors";
import { areClassificationReferencesEqual } from "../../helpers/ClassificationReferenceHelpers";

export interface EquipmentMeasurementsState extends RemoteState<Models.Equipments.EquipmentMeasurement, EquipmentMeasurementFilter> {}
const initialState: EquipmentMeasurementsState = {
    ...defaultRemoteInitialState(),
}
export const equipmentMeasurementsSlice = createSlice({
    name: 'equipmentMeasurements',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState)
    }
});

const equipmentMeasurementQueryBuilder = (state: RootState, sliceState: EquipmentMeasurementsState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, equipmentMeasurementFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.equipmentType) {
            queryParams.push({ key: 'equipmentType', value: `${filter.equipmentType.classification}|${filter.equipmentType.conceptId}`});
        }
        if(filter.measurement) {
            queryParams.push({ key: 'measurement', value: `${filter.measurement.classification}|${filter.measurement.conceptId}`});
        }
    }
    return queryParams;
}
const equipmentMeasurementFilterComparer = (f1?: EquipmentMeasurementFilter, f2?: EquipmentMeasurementFilter) => {
    if(!areFiltersEqual(f1,f2)) {
        return false;
    }
    return f1!.equipmentType?.classification === f2!.equipmentType?.classification
        && f1!.equipmentType?.conceptId === f2!.equipmentType?.conceptId
        && f1!.measurement?.classification === f2!.measurement?.classification
        && f1!.measurement?.conceptId === f2!.measurement?.conceptId;
}

export const equipmentMeasurementsActions = {
    ...createRestApiActions(
        'equipmentMeasurements',
        equipmentMeasurementsSlice.actions,
        state => state.equipmentMeasurements,
        equipmentMeasurementQueryBuilder,
        equipmentMeasurementFilterComparer
    ),
};
export interface SelectEquipmentMeasurementByEquipmentTypeArgs {
    equipmentType: Models.ClassificationReference;
}
export const equipmentMeasurementsSelectors = {
    ...createDefaultGenericItemSelectors(state => state.equipmentMeasurements),
    createSelectByEquipmentType: () => createSelector(
        (state: RootState) => state.equipmentMeasurements.items,
        (_: RootState, args: SelectEquipmentMeasurementByEquipmentTypeArgs) => args.equipmentType,
        (items, equipmentType) => items.filter(x => areClassificationReferencesEqual(x.equipmentType, equipmentType))
    ),
};