import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { AccountFilter } from "../../types/frontendTypes";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { RootState } from "../store/healthRecordStore";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { ViewModels } from "../../types/viewModels";
import { postActionBuilder } from "../../../sharedHealthComponents/redux/helpers/ActionCreatorBuilder";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { Models } from "../../types/models";
import { isProfessionalAccount } from "../../helpers/AccessControlHelpers";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

interface AccountsState extends RemoteState<ViewModels.IAccountViewModel, AccountFilter> {}

const initialState: AccountsState = {
    ...defaultRemoteInitialState(),
}
export const accountsSlice = createSlice({
    name: 'accounts',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
        activateAccount: (state, action: PayloadAction<ActivateAccountArgs>) => {
            const item = state.items.find(x => x.id === action.payload.accountId);
            if(!item || !isProfessionalAccount(item.accountType))  {
                return;
            }
            (item as ViewModels.ProfessionalAccountViewModel).isActivated = true;
        },
        deactivateAccount: (state, action: PayloadAction<DeactivateAccountArgs>) => {
            const item = state.items.find(x => x.id === action.payload.accountId);
            if(!item || !isProfessionalAccount(item.accountType))  {
                return;
            }
            (item as ViewModels.ProfessionalAccountViewModel).isActivated = false;
        },
    }
});

const accountQueryBuilder = (state: RootState, sliceState: AccountsState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, accountFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.searchText) {
            queryParams.push({ key: 'searchText', value: filter.searchText });
        }
        if(filter.accountType) {
            queryParams.push({ key: 'accountType', value: filter.accountType });
        }
    }
    return queryParams;
}
const accountFilterComparer = (f1?: AccountFilter, f2?: AccountFilter) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.accountType === f2!.accountType
        && f1!.searchText === f2!.searchText;
}

export interface ActivateAccountArgs {
    accountId: string;
}
export interface DeactivateAccountArgs {
    accountId: string;
}
export const accountsActions = {
    ...createRestApiActions(
        'accounts',
        accountsSlice.actions,
        state => state.accounts,
        accountQueryBuilder,
        accountFilterComparer
    ),
    activateAccount: postActionBuilder<ActivateAccountArgs,Models.RequestBodies.AccountActivationRequestBody>(
        args => `api/accounts/${args.accountId}/activate`, 
        () => resolveText("Account_CouldNotActivate"),
        accountsSlice.actions.setIsSubmitting,
        (dispatch,response,_) => dispatch(accountsSlice.actions.addOrUpdateItem(response))
    ),
    deactivateAccount: postActionBuilder<DeactivateAccountArgs,never>(
        args => `api/accounts/${args.accountId}/deactivate`, 
        () => resolveText("Account_CouldNotDeactivate"),
        accountsSlice.actions.setIsSubmitting,
        (dispatch,response,args) => dispatch(accountsSlice.actions.addOrUpdateItem(response))
    ),
};
export const accountsSelectors = {
    ...createDefaultGenericItemSelectors(state => state.accounts),
};