import { createSlice } from "@reduxjs/toolkit";
import { PersonDataRemoteState } from "../../types/reduxInterfaces";
import { HealthRecordEventFilter } from "../../types/frontendTypes";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { HealthRecordEntryType } from "../../../localComponents/types/enums";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";

interface MedicalAlertsState extends PersonDataRemoteState<ViewModels.HealthRecordEntries.MedicalAlertViewModel, HealthRecordEventFilter> {

}
const initialState: MedicalAlertsState = {
    ...defaultRemoteInitialState(),
}

export const medicalAlertsSlice = createSlice({
    name: 'medicalAlerts',
    initialState: initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState),
    }
});

export const medicalAlertsActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.MedicalAlert,
        'medicalAlerts',
        medicalAlertsSlice.actions,
        state => state.medicalAlerts,
        healthRecordEntryQueryBuilder,
        areHealthRecordEntryFiltersEqual
    ),
};
export const medicalAlertsSelectors = {
    ...createHealthRecordEntrySelectors(state => state.medicalAlerts),
};