import { useEffect, useMemo } from 'react';
import { Button, Nav, Navbar } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../localComponents/redux/store/healthRecordStore';
import { Sex } from '../../../localComponents/types/enums';
import { Autocomplete } from '../../../sharedCommonComponents/components/Autocompletes/Autocomplete';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import { formatPerson } from '../../helpers/Formatters';
import { relatedEntriesSlice } from '../../redux/slices/relatedHealthRecordEntriesSlice';
import { Models } from '../../../localComponents/types/models';
import { useMemorizedSelector } from '../../redux/helpers/ReduxHooks';
import { personsSelectors } from '../../redux/slices/personsSlice';

interface HealthRecordSearchProps {
    personId: string;
}
export interface HealthRecordSearchTarget {
    keywords: string[];
    tab?: string;
    target: string;
}

export const getHealthRecordNavigationTargets = (person?: Models.Individuals.Person): HealthRecordSearchTarget[] => {
    const targets = [
        { keywords: [ resolveText("Person_ID") ], target: 'basic-information-id'},
        { keywords: [ resolveText("Person_Name") ], target: 'basic-information-name'},
        { keywords: [ resolveText("Person_BirthDate") ], target: 'basic-information-birthdate'},
        { keywords: [ resolveText("Person_HealthInsurance") ], target: 'basic-information-healthinsurance'},
        { keywords: [ resolveText("Person_Addresses") ], target: 'basic-information-addresses'},
        { keywords: [ resolveText("Person_PhoneNumber") ], target: 'basic-information-phone'},
        { keywords: [ resolveText("Person_Email") ], target: 'basic-information-email'},
        { keywords: [ resolveText("Person_ContactPersons") ], target: 'basic-information-contactpersons'},
        { keywords: [ resolveText("Person_AdditionalInformations") ], target: 'basic-information-additionalinformation'},
        { keywords: [ resolveText("Notes") ], tab: 'notes', target: 'healthrecord-tabs-tab-notes' },
        { keywords: [ resolveText("Diagnoses") ], tab: 'diagnoses', target: 'healthrecord-tabs-tab-diagnoses' },
        { keywords: [ resolveText("Allergies") ], tab: 'allergies', target: 'healthrecord-tabs-tab-allergies' },
        { keywords: [ resolveText("Observations") ], tab: 'observations', target: 'healthrecord-tabs-tab-observations' },
        { keywords: [ resolveText("Medications") ], tab: 'medications', target: 'healthrecord-tabs-tab-medications' },
        { keywords: [ resolveText("Prescriptions") ], tab: 'medications', target: 'prescriptions' },
        { keywords: [ resolveText("Immunizations") ], tab: 'medications', target: 'immunizations' },
        { keywords: [ resolveText("Equipment") ], tab: 'equipment', target: 'healthrecord-tabs-tab-equipment' },
        { keywords: [ resolveText("Questionnaires") ], tab: 'interview', target: 'questionnaires' },
        { keywords: [ resolveText("Questions") ], tab: 'interview', target: 'questions' },
        { keywords: [ resolveText("TestResults") ], tab: 'testResults', target: 'healthrecord-tabs-tab-testResults' },
        { keywords: [ resolveText("MedicalProcedures") ], tab: 'procedures', target: 'healthrecord-tabs-tab-procedures' },
        { keywords: [ resolveText("Documents") ], tab: 'documents', target: 'healthrecord-tabs-tab-documents' },
        { keywords: [ resolveText("Tasks") ], tab: 'tasks', target: 'tasks' },
        { keywords: [ resolveText("Checklists") ], tab: 'tasks', target: 'checklists' },
    ];
    if(person && [ Sex.Female, Sex.Other ].includes(person.sex)) {
        targets.push(
            { keywords: [ resolveText("Pregnancies") ], tab: 'pregnancies', target: 'healthrecord-tabs-tab-pregnancies' }
        );
    }
    return targets;
}

export const HealthRecordSearch = (props: HealthRecordSearchProps) => {

    const { personId } = props;
    const profileData = useMemorizedSelector(personsSelectors.getById, { id: personId });
    const isDeceased = !!profileData?.deceasedDate;
    const activeLinkingSession = useAppSelector(state => state.relatedEntries.linkingSession);
    const healthRecordNavigationTargets = useMemo(() => getHealthRecordNavigationTargets(profileData), [ profileData ]);
    const [ q ] = useSearchParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const target = q.get("target");
        if(target) {
            const targetElement = document.getElementById(target);
            targetElement?.scrollIntoView({ block: 'center'});
            unhighlightAll();
            highlightElement(target);
        }
    }, [ q ]);

    const search = async (searchText: string) => {
        const lowerSearchText = searchText.toLowerCase();
        const matchingItems = healthRecordNavigationTargets.filter(item => item.keywords.some(keyword => keyword.toLowerCase().startsWith(lowerSearchText)));
        return matchingItems;
    }

    const navigateToTarget = (target: string, tab?: string) => {
        const params = [ `target=${target}` ];
        if(tab) {
            params.push(`tab=${tab}`);
        }
        const url = `/healthrecord/${personId}?${params.join('&')}`;
        navigate(url);
    }

    const onTargetSelected = (item: HealthRecordSearchTarget) => {
        navigateToTarget(item.target, item.tab);
    }
    const unhighlightAll = () => {
        const highlightedElements = document.getElementsByClassName("highlighted");
        for (let index = highlightedElements.length-1; index >= 0; index--) { // Iterate backwards because 'highlightedElements' will change in for-loop
            const element = highlightedElements[index];
            element.classList.remove("highlighted");
        }
    }
    const highlightElement = (elementId: string) => {
        document.getElementById(elementId)?.classList.add("highlighted");
    }

    const stopLinkingSession = () => {
        dispatch(relatedEntriesSlice.actions.endLinkingSession());
    }

    return (
        <Navbar 
            sticky='top' 
            bg={isDeceased ? 'secondary' : 'success'}
            variant='dark'
            id='healthrecord-search-navbar'
        >
            <Navbar.Brand className='ms-3'>{formatPerson(profileData!)}</Navbar.Brand>
            <Nav className='ms-2 me-auto'>
                <Autocomplete<HealthRecordSearchTarget>
                    search={search}
                    displayNameSelector={x => x.keywords[0]}
                    onItemSelected={onTargetSelected}
                    minSearchTextLength={2}
                    resetOnSelect
                />
            </Nav>
            {activeLinkingSession
            ? <Nav>
                <strong className='text-light align-self-center'>
                    Linking to: {resolveText(`HealthRecordEntryType_${activeLinkingSession.sourceEntryType}`)}
                </strong>
                <Button 
                    variant='danger'
                    size='sm'
                    className='mx-2'
                    onClick={stopLinkingSession}
                >
                    {resolveText("RelatedEntries_StopLinkingSession")}
                </Button>
            </Nav> : null}
        </Navbar>
    );

}