import { createSlice } from "@reduxjs/toolkit";
import { HealthRecordEntryType } from "../../../localComponents/types/enums";
import { Models } from "../../../localComponents/types/models";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { HealthRecordEventFilter } from "../../types/frontendTypes";
import { PersonDataRemoteState } from "../../types/reduxInterfaces";
import { postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";

interface AllergiesState extends PersonDataRemoteState<ViewModels.HealthRecordEntries.AllergyViewModel,HealthRecordEventFilter> {
}

const initialState: AllergiesState = {
    ...defaultRemoteInitialState(),
}

export const allergiesSlice = createSlice({
    name: 'allergies',
    initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState),
    }
});

export const allergiesActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.Allergy,
        'allergies', 
        allergiesSlice.actions, 
        state => state.allergies,
        healthRecordEntryQueryBuilder,
        areHealthRecordEntryFiltersEqual
    ),
    changeStatus: postActionBuilder<string,Models.Diagnoses.ConditionStatusChange>(
        allergyId => `api/allergies/${allergyId}/status`, 
        () => resolveText("Allergy_CouldNotMarkAsResolved"),
        allergiesSlice.actions.setIsSubmitting,
        (dispatch,response) => dispatch(allergiesSlice.actions.addOrUpdateItem(response))
    )
};
export const allergiesSelectors = {
    ...createHealthRecordEntrySelectors(state => state.allergies),
};