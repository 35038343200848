import { Action, PayloadAction, ThunkAction, createSlice } from "@reduxjs/toolkit";
import { Models } from "../../../localComponents/types/models";
import { postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { sendPostRequest } from "../../../sharedCommonComponents/helpers/StoringHelpers";

interface ScreenLockState {
    isSubmitting: boolean;
    lockResult?: Models.AccessControl.LockResult;
}

const initialState: ScreenLockState = {
    isSubmitting: false
};
export const screenLockSlice = createSlice({
    name: 'screenLock',
    initialState: initialState,
    reducers: {
        setIsSubmitting: (state, action: PayloadAction<boolean>) => {
            state.isSubmitting = action.payload;
        },
        setLockResult: (state, action: PayloadAction<Models.AccessControl.LockResult | undefined>) => {
            state.lockResult = action.payload;
        }
    }
});

interface UnlockActionParameters {
    pin: string;
    onSuccess?: (unlockResult: Models.AccessControl.AuthenticationResult) => void;
    onFailure?: () => void;
    onFinally?: () => void;
}
export const screenLockActions = {
    lock: postActionBuilder(
        () => 'api/accounts/me/screen-lock',
        () => resolveText("Account_CouldNotLock"),
        screenLockSlice.actions.setIsSubmitting,
        (dispatch,response,_) => dispatch(screenLockSlice.actions.setLockResult(response))
    ),
    unlock: ({ pin, onSuccess, onFailure, onFinally }: UnlockActionParameters): ThunkAction<Promise<void>, RootState, void, Action> => {
        return async (dispatch, getState) => {
            const state = getState();
            const lockResult = state.screenLock.lockResult;
            if(!lockResult) {
                if(onFailure) {
                    onFailure();
                }
                return;
            }
            const unlockRequest: Models.AccessControl.UnlockRequest = {
                accountId: lockResult.accountId,
                lockResult: lockResult,
                pin: pin,
            };
            dispatch(screenLockSlice.actions.setIsSubmitting(true));
            await sendPostRequest(
                `api/accounts/${lockResult.accountId}/screen-unlock`, {},
                resolveText("Account_CouldNotUnlock"),
                unlockRequest,
                async response => {
                    const unlockResult = await response.json() as Models.AccessControl.AuthenticationResult;
                    if(onSuccess) {
                        onSuccess(unlockResult);
                    }
                    dispatch(screenLockSlice.actions.setLockResult(undefined));
                },
                async () => {
                    if(onFailure) {
                        onFailure();
                    }
                },
                () => {
                    if(onFinally) {
                        onFinally();
                    }
                    dispatch(screenLockSlice.actions.setIsSubmitting(false));
                }
            );
        }
    }
};
export const screenLockSelectors = {

};