import { AccountType } from "../types/enums";
import { ViewModels } from "../types/viewModels";

export const getPractitioner = (user: ViewModels.IUserViewModel | undefined) => {
    if(!user) {
        return undefined;
    }
    switch(user.accountType) {
        case AccountType.HealthProfessional:
        case AccountType.Researcher:
        case AccountType.Pharmacy:
        case AccountType.PublicHealthAgency:
        case AccountType.Admin:
            const professionalUserViewModel = user as ViewModels.ProfessionalUserViewModel;
            return professionalUserViewModel.professionalProfile.id;
        default:
            return undefined;
    }
}
export const getPerson = (user: ViewModels.IUserViewModel | undefined) => {
    if(!user) {
        return undefined;
    }
    switch(user.accountType) {
        case AccountType.Sharer:
        case AccountType.HealthProfessional:
        case AccountType.Researcher:
        case AccountType.Pharmacy:
        case AccountType.Admin:
            const personUserViewModel = user as ViewModels.PersonUserViewModel;
            return personUserViewModel.profileData;
        default:
            return undefined;
    }
}