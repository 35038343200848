import { Nav, NavDropdown} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import ToolsDropdownMenu from './ToolsDropdownMenu';

interface ResearcherMenuProps {}

export const ResearcherMenu = (props: ResearcherMenuProps) => {

    const navigate = useNavigate();
    return (
        <Nav>
            <NavDropdown title={resolveText("Menu_Patients")}>
                <NavDropdown.Item onClick={() => navigate("/patients")}>{resolveText("Menu_MyPatients")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/emergency")}>{resolveText("Menu_Emergency")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/appointments")}>{resolveText("Menu_Appointments")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={resolveText("Menu_HealthServices")}>
                <NavDropdown.Item onClick={() => navigate("/institutions")}>{resolveText("Menu_Institutions")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/services")}>{resolveText("Menu_FindServices")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/create/service")}>{resolveText("Menu_CreateService")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/servicerequests")}>{resolveText("Menu_ServiceRequests")}</NavDropdown.Item>
            </NavDropdown>
            <ToolsDropdownMenu />
            <NavDropdown title={resolveText("Menu_Studies")}>
                <NavDropdown.Item onClick={() => navigate(`/studies`)}>{resolveText("Menu_Studies")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/export`)}>{resolveText("Menu_ExportPersonData")}</NavDropdown.Item>
            </NavDropdown>
            <ToolsDropdownMenu />
            <NavDropdown title={resolveText("Menu_Config")}>
                <NavDropdown.Item onClick={() => navigate("/questionnaires")}>{resolveText("Menu_Questionnaires")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/testpackages")}>{resolveText("Menu_TestPackages")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/taskpackages")}>{resolveText("Menu_TaskPackages")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/equipmentmeasurements`)}>{resolveText("Menu_EquipmentMeasurements")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/anonymousforms")}>{resolveText("Menu_AnonymousForms")}</NavDropdown.Item>
            </NavDropdown>
        </Nav>
    );

}