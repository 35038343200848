import { lazy } from 'react';
import { AccountType } from '../types/enums';
import { Models } from '../types/models';
import { ViewModels } from '../types/viewModels';
import ShehrdHealthRecordRoutes from './ShehrdHealthRecordRoutes';
import { ShehrdRouteDefinition } from '../types/frontendTypes';
import ChecklistRoutes from '../../checklist/navigation/ChecklistRoutes';
import { ToolsRoutes } from './ToolsRoutes';


const HealthRecordEnvironment = lazy(() => import('../../sharedHealthComponents/pages/Patients/HealthRecordEnvironment'));
const EditPersonPage = lazy(() => import('../../sharedHealthComponents/pages/Patients/EditPersonPage'));
const ConnectCloudStoragePage = lazy(() => import('../pages/Sharer/CloudStorage/ConnectCloudStoragePage'));
const DropboxAuthorizedPage = lazy(() => import('../pages/Sharer/CloudStorage/DropboxAuthorizedPage'));
const PublicHealthAgencyAssociationPage = lazy(() => import('../pages/Sharer/PublicHealthAgencyAssociationPage'));
const PublicHealthAgencySettingsPage = lazy(() => import('../pages/PublicHealthAgency/PublicHealthAgencySettingsPage'));
const EmergencyCardQrCodeGeneratorPage = lazy(() => import('../pages/Admin/EmergencyCardQrCodeGeneratorPage'));
const TermsAndConditionsPage = lazy(() => import('../pages/Public/TermsAndConditionsPage'));
const EuropeanHealthDataSpacePage = lazy(() => import('../pages/Public/EuropeanHealthDataSpacePage'));
const FrequentlyAskedQuestionsPage = lazy(() => import('../pages/Public/FrequentlyAskedQuestionsPage'));
const FeedbackListPage = lazy(() => import('../pages/Admin/FeedbackListPage'));
const AnonymousFormAnswerPage = lazy(() => import('../pages/AnonymousForms/AnonymousFormAnswerPage'));
const AnonymousFormListPage = lazy(() => import('../pages/AnonymousForms/AnonymousFormListPage'));
const CreateEditAnonymousFormPage = lazy(() => import('../pages/AnonymousForms/CreateEditAnonymousFormPage'));
const EpidemiologyDashboardPage = lazy(() => import('../pages/Epidemiology/EpidemiologyDashboardPage'));
const PublicDataApprovalPage = lazy(() => import('../pages/Admin/PublicDataApprovalPage'));
const PublicHealthAgencyHomePage = lazy(() => import('../pages/PublicHealthAgency/PublicHealthAgencyHomePage'));
const PrintTemplateListPage = lazy(() => import('../../sharedHealthComponents/pages/PrintTemplateListPage'));
const PrintTemplateEditorPage = lazy(() => import('../../sharedHealthComponents/pages/PrintTemplateEditorPage'));
const AccessLogPage = lazy(() => import('../pages/Sharer/AccessLogPage'));
const MyRepresentationsPage = lazy(() => import('../pages/Sharer/MyRepresentationsPage'));
const PractitionersListPage = lazy(() => import('../../sharedHealthComponents/pages/Organization/PractitionersListPage'));
const MyEncoutnersPage = lazy(() => import('../../sharedHealthComponents/pages/Scheduling/MyEncoutnersPage'));
const AppointmentsListPage = lazy(() => import('../../sharedHealthComponents/pages/Scheduling/AppointmentsListPage'));
const PrintAppointmentPage = lazy(() => import('../../sharedHealthComponents/pages/Scheduling/PrintAppointmentPage'));
const AccountsPage = lazy(() => import('../pages/UserManagement/AccountsPage'));
const AccountReviewPage = lazy(() => import('../pages/UserManagement/AccountReviewPage'));
const AccountContactEmailVerificationPage = lazy(() => import('../pages/UserManagement/AccountContactEmailVerificationPage'));
const PatientsListPage = lazy(() => import('../pages/HealthProfessional/PatientsListPage'));
const StudiesPage = lazy(() => import('../pages/Researcher/StudiesPage'));
const StudyPage = lazy(() => import('../pages/Researcher/StudyPage'));
const GiveHealthProfesionalAccessPage = lazy(() => import('../pages/Sharer/GiveHealthProfesionalAccessPage'));
const ReceiveHealthProfessionalAccessPage = lazy(() => import('../pages/HealthProfessional/ReceiveHealthProfessionalAccessPage'));
const SharedAccessListPage = lazy(() => import('../pages/Sharer/SharedAccessListPage'));
const OfferStudyParticipationPage = lazy(() => import('../pages/Sharer/OfferStudyParticipationPage'));
const CreateEditStudyPage = lazy(() => import('../pages/Researcher/CreateEditStudyPage'));
const StudyEnrollmentReviewPage = lazy(() => import('../pages/Researcher/StudyEnrollmentReviewPage'));
const RequestEmergencyAccessPage = lazy(() => import('../pages/HealthProfessional/RequestEmergencyAccessPage'));
const CreateEmergencyAccessTokenPage = lazy(() => import('../pages/Sharer/CreateEmergencyAccessTokenPage'));
const AccountPage = lazy(() => import('../pages/UserManagement/AccountPage'));
const CreateEditAppointmentPage = lazy(() => import('../../sharedHealthComponents/pages/Scheduling/CreateEditAppointmentPage'));
const CreateEditInstitutionPage = lazy(() => import('../pages/Organizations/CreateEditInstitutionPage'));
const EditInstitutionSchedulePage = lazy(() => import('../../sharedHealthComponents/pages/Scheduling/EditInstitutionSchedulePage'));
const ManageInstitutionPage = lazy(() => import('../pages/Organizations/ManageInstitutionPage'));
const CreateEditDepartmentPage = lazy(() => import('../pages/Organizations/CreateEditDepartmentPage'));
const CreateEditPractitionerPage = lazy(() => import('../../sharedHealthComponents/pages/Organization/CreateEditPractitionerPage'));
const MyAppointmentsPage = lazy(() => import('../pages/Sharer/MyAppointmentsPage'));
const DiagnosticTestsPackagesExplorerPage = lazy(() => import('../../sharedHealthComponents/pages/TestResults/DiagnosticTestsPackagesExplorerPage'));
const CreateUploadCodePage = lazy(() => import('../pages/Sharer/CreateUploadCodePage'));
const InstitutionsListPage = lazy(() => import('../pages/Organizations/InstitutionsListPage'));
const EditInstitutionSettingsPage = lazy(() => import('../pages/Organizations/EditInstitutionSettingsPage'));
const GitBranchComparisonPage = lazy(() => import('../pages/Sharer/GitBranchComparisonPage'));
const ExportPatientDataPage = lazy(() => import('../../sharedHealthComponents/pages/DataImportExport/ExportPatientDataPage'));
const CheckoutPage = lazy(() => import('../pages/Billing/CheckoutPage'));
const ShippingMethodsPage = lazy(() => import('../pages/Billing/ShippingMethodsPage'));
const CreateEditShippingMethodPage = lazy(() => import('../pages/Billing/CreateEditShippingMethodPage'));
const EmergencyCardOrderPage = lazy(() => import('../pages/Billing/EmergencyCardOrderPage'));
const OrderProgressPage = lazy(() => import('../pages/Billing/OrderProgressPage'));
const MyOrdersPage = lazy(() => import('../pages/Billing/MyOrdersPage'));
const ShopItemsAdminPage = lazy(() => import('../pages/Billing/ShopItemsAdminPage'));
const VouchersListPage = lazy(() => import('../pages/Billing/VouchersListPage'));
const CreateEditVoucherPage = lazy(() => import('../pages/Billing/CreateEditVoucherPage'));
const CreateEditShopItemPage = lazy(() => import('../pages/Billing/CreateEditShopItemPage'));
const EquipmentMeasurementsListPage = lazy(() => import('../../sharedHealthComponents/pages/Equipments/EquipmentMeasurementsListPage'));
const CreateEditEquipmentMeasurement = lazy(() => import('../../sharedHealthComponents/pages/Equipments/CreateEditEquipmentMeasurement'));
const DrugsListPage = lazy(() => import('../../sharedHealthComponents/pages/Medication/DrugsListPage'));
const CreateEditDrugPage = lazy(() => import('../../sharedHealthComponents/pages/Medication/CreateEditDrugPage'));
const PharmacyOrdersListPage = lazy(() => import('../../sharedHealthComponents/pages/Pharmacy/PharmacyOrdersListPage'));
const CreateEditPharmacyOrder = lazy(() => import('../../sharedHealthComponents/pages/Pharmacy/CreateEditPharmacyOrderPage'));
const PharmacyOrderPage = lazy(() => import('../../sharedHealthComponents/pages/Pharmacy/PharmacyOrderPage'));
const QuestionnairesListPage = lazy(() => import('../../sharedHealthComponents/pages/Questionnaires/QuestionnairesListPage'));
const CreateEditQuestionnairePage = lazy(() => import('../../sharedHealthComponents/pages/Questionnaires/CreateEditQuestionnairePage'));
const CreateEditTasksPackagePage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/CreateEditTasksPackagePage'));
const CreateEditTodoListItemPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/CreateEditTodoListItemPage'));
const DeployTasksPackagePage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/DeployTasksPackagePage'));
const PrintTaskListPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/PrintTaskListPage'));
const TaskPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/TaskPage'));
const TasksPackagesPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/TasksPackagesPage'));
const IsItBrokenConfigurationPage = lazy(() => import('../pages/IsItBroken/IsItBrokenConfigurationPage'));
const AdminHomePage = lazy(() => import('../pages/Admin/AdminHomePage'));
const ResearcherHomePage = lazy(() => import('../pages/Researcher/ResearcherHomePage'));
const PharmacyHomePage = lazy(() => import('../../sharedHealthComponents/pages/Pharmacy/PharmacyHomePage'));
const SharerHomePage = lazy(() => import('../pages/Sharer/SharerHomePage'));
const HealthProfessionalHomePage = lazy(() => import('../pages/HealthProfessional/HealthProfessionalHomePage'));
const HomePage = lazy(() => import('../pages/Public/HomePage'));
const ContactPage = lazy(() => import('../pages/Public/ContactPage'));
const PrivacyPage = lazy(() => import('../pages/Public/PrivacyPage'));
const NotFoundPage = lazy(() => import('../pages/NotFoundPage'));

interface RegisteredUserRoutesProps {
    user: ViewModels.IUserViewModel;
    features: Models.Configuration.FeatureSettings;
    onNewAuthenticationResult: (authenticationResult: Models.AccessControl.AuthenticationResult) => void;
    onLogOut: () => Promise<void>;
}
export const RegisteredUserRoutes = (props: RegisteredUserRoutesProps) => {

    const { user, features, onNewAuthenticationResult, onLogOut } = props;

    let userTypeHomePage = (<HomePage />);
    switch(user.accountType) {
        case AccountType.Admin:
            userTypeHomePage = (<AdminHomePage />);
            break;
        case AccountType.HealthProfessional:
            userTypeHomePage = (<HealthProfessionalHomePage />);
            break;
        case AccountType.Researcher:
            userTypeHomePage = (<ResearcherHomePage />);
            break;
        case AccountType.Pharmacy:
            userTypeHomePage = (<PharmacyHomePage />);
            break;
        case AccountType.Sharer:
            userTypeHomePage = (<SharerHomePage />);
            break;
        case AccountType.PublicHealthAgency:
            userTypeHomePage = (<PublicHealthAgencyHomePage />);
            break;
    }

    const routes: ShehrdRouteDefinition[] = [
        { path: '/healthrecord/:personId/*', element: <HealthRecordEnvironment routes={ShehrdHealthRecordRoutes(features)} />, usesCustomLayout: true },
        
        { path: "/faq", element: <FrequentlyAskedQuestionsPage />, audience: [] },
        { path: "/about/ehds", element: <EuropeanHealthDataSpacePage />, audience: [] },
        { path: "/privacy", element: <PrivacyPage />, audience: [] },
        { path: "/terms-of-service", element: <TermsAndConditionsPage />, audience: [] },
        { path: "/contact", element: <ContactPage />, audience: [] },
        { path: '/', element: userTypeHomePage, audience: [] },
        { path: '*', element: <NotFoundPage />, audience: [] },

        { path: '/create/study', element: <CreateEditStudyPage />, audience: [ AccountType.Researcher ]},
        { path: '/edit/study/:id', element: <CreateEditStudyPage />, audience: [ AccountType.Researcher ]},
        { path: '/study/:studyId', element: <StudyPage />, audience: [ AccountType.Researcher, AccountType.Sharer, AccountType.Admin ]},
        { path: '/study/:studyId/offerparticipation', element: <OfferStudyParticipationPage />, audience: [ AccountType.Sharer ]},
        { path: '/study/:studyId/enrollment/:enrollmentId/review', element: <StudyEnrollmentReviewPage />, audience: [ AccountType.Researcher ]},
        { path: '/studies', element: <StudiesPage />, audience: [ AccountType.Researcher, AccountType.Sharer, AccountType.Admin ]},
        { path: '/giveaccess/healthprofessional/:accessInviteId', element: <GiveHealthProfesionalAccessPage />, audience: [ AccountType.Sharer ]},
        { path: '/giveaccess/healthprofessional', element: <GiveHealthProfesionalAccessPage />, audience: [ AccountType.Sharer ]},
        { 
            path: '/accessinvites/:accessInviteId', 
            element: user.accountType === AccountType.Sharer ? <GiveHealthProfesionalAccessPage /> : <ReceiveHealthProfessionalAccessPage />, 
            audience: [ AccountType.Sharer, AccountType.HealthProfessional ]
        },
        { path: '/sharedaccess', element: <SharedAccessListPage />, audience: [ AccountType.Sharer ]},
        { path: '/create/emergency', element: <CreateEmergencyAccessTokenPage />, audience: [ AccountType.Sharer ]},
        { path: '/create/emergency', element: <RequestEmergencyAccessPage />, audience: [ AccountType.HealthProfessional ]},
        { path: '/emergency/:emergencyToken', element: <RequestEmergencyAccessPage />, audience: [ AccountType.HealthProfessional ]},
        { path: '/show/emergencytoken/:accessId', element: <CreateEmergencyAccessTokenPage />, audience: [ AccountType.Sharer ]},
        { path: '/representations', element: <MyRepresentationsPage />, audience: [ AccountType.Sharer ]},
        { path: '/accesslog', element: <AccessLogPage />, audience: [ AccountType.Sharer ]},

        { path: '/account', element: <AccountPage onAccountDeleted={onLogOut} />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher, AccountType.PublicHealthAgency, AccountType.Admin ]},
        { path: '/edit/person/:personId', element: <EditPersonPage />, audience: [ AccountType.Sharer ] },
        { path: '/git/branch/:branchName', element: <GitBranchComparisonPage />, audience: [ AccountType.Sharer ]},
        { path: '/accounts', element: <AccountsPage />, audience: [ AccountType.Admin ]},
        { path: '/account/:accountId', element: <AccountReviewPage />, audience: [ AccountType.Admin ]},
        { path: "/verify-contact-email", element: <AccountContactEmailVerificationPage />, audience: [] },
        { path: '/institutions', element: <InstitutionsListPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher, AccountType.Pharmacy, AccountType.Admin ]},
        { path: '/create/institution', element: <CreateEditInstitutionPage />, audience: [ AccountType.Admin ]},
        { path: '/edit/institution/:id', element: <CreateEditInstitutionPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
        { path: '/institution/:institutionId/schedule/edit', element: <EditInstitutionSchedulePage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: '/institution/:institutionId/department/:departmentId/schedule/edit', element: <EditInstitutionSchedulePage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: '/institution/:institutionId/manage', element: <ManageInstitutionPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
        { path: '/institution/:institutionId/settings', element: <EditInstitutionSettingsPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
        { path: '/institution/:institutionId/create/department', element: <CreateEditDepartmentPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
        { path: '/institution/:institutionId/edit/department/:id', element: <CreateEditDepartmentPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
        { path: '/practioners', element: <PractitionersListPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher, AccountType.Pharmacy, AccountType.Admin ]},
        { path: '/create/practitioner', element: <CreateEditPractitionerPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
        { path: '/edit/practitioner/:id', element: <CreateEditPractitionerPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},

        { path: '/anonymousforms/:formId/answer', element: <AnonymousFormAnswerPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: '/anonymousforms', element: <AnonymousFormListPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: '/create/anonymousform', element: <CreateEditAnonymousFormPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: '/edit/anonymousform/:formId', element: <CreateEditAnonymousFormPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},

        { path: '/patients', element: <PatientsListPage />, audience: [ AccountType.HealthProfessional ]},

        { path: '/testpackages', element: <DiagnosticTestsPackagesExplorerPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},

        { path: '/create/uploadcode', element: <CreateUploadCodePage />, audience: [ AccountType.Sharer ]},
        { path: '/export', element: <ExportPatientDataPage />, audience: [ AccountType.Researcher ]},

        { path: '/create/appointment', element: <CreateEditAppointmentPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: '/edit/appointment/:id', element: <CreateEditAppointmentPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: '/institution/:institutionId/department/:departmentId/create/appointment', element: <CreateEditAppointmentPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: '/appointments', element: <MyAppointmentsPage />, audience: [ AccountType.Sharer ]},
        { path: '/appointments', element: <AppointmentsListPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: '/print/appointment/:id', element: <PrintAppointmentPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: '/encounters', element: <MyEncoutnersPage />, audience: [ AccountType.Sharer ]},

        { path: "/print-templates", element: <PrintTemplateListPage />, audience: [ AccountType.Admin ] },
        { path: "/create/print-template", element: <PrintTemplateEditorPage />, audience: [ AccountType.Admin ] },
        { path: "/edit/print-template/:id", element: <PrintTemplateEditorPage />, audience: [ AccountType.Admin ] },

        { path: '/shop/checkout', element: <CheckoutPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: '/shop/emergency-card', element: <EmergencyCardOrderPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: '/shop/order/:orderId', element: <OrderProgressPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
        { path: '/shop/orders', element: <MyOrdersPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
        { path: '/shop/vouchers', element: <VouchersListPage />, audience: [ AccountType.Admin ]},
        { path: '/shop/create/voucher', element: <CreateEditVoucherPage />, audience: [ AccountType.Admin ]},
        { path: '/shop/edit/voucher/:id', element: <CreateEditVoucherPage />, audience: [ AccountType.Admin ]},
        { path: '/shop/items', element: <ShopItemsAdminPage />, audience: [ AccountType.Admin ]},
        { path: '/shop/create/item', element: <CreateEditShopItemPage />, audience: [ AccountType.Admin ]},
        { path: '/shop/edit/item/:id', element: <CreateEditShopItemPage />, audience: [ AccountType.Admin ]},
        { path: '/shop/shippingmethods', element: <ShippingMethodsPage />, audience: [ AccountType.Admin ]},
        { path: '/shop/create/shippingmethod', element: <CreateEditShippingMethodPage />, audience: [ AccountType.Admin ]},
        { path: '/shop/edit/shippingmethod/:id', element: <CreateEditShippingMethodPage />, audience: [ AccountType.Admin ]},
        { path: '/emergencycard-qr-generator', element: <EmergencyCardQrCodeGeneratorPage />, audience: [ AccountType.Admin ]},

        { path: '/equipmentmeasurements' , element: <EquipmentMeasurementsListPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
        { path: '/create/equipmentmeasurement', element: <CreateEditEquipmentMeasurement />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
        { path: '/edit/equipmentmeasurement/:id', element: <CreateEditEquipmentMeasurement />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},

        { path: '/drugs', element: <DrugsListPage />, audience: [ AccountType.Pharmacy, AccountType.Admin ]}, // Other account types have access to route through HealthRecordRoutes
        { path: '/create/drug', element: <CreateEditDrugPage />, audience: [ AccountType.Pharmacy, AccountType.Admin ]}, // Other account types have access to route through HealthRecordRoutes
        { path: '/drugs/:drugId/edit', element: <CreateEditDrugPage />, audience: [ AccountType.Pharmacy, AccountType.Admin ]}, // Other account types have access to route through HealthRecordRoutes
        { path: '/pharmacyOrders', element: <PharmacyOrdersListPage />, audience: [ AccountType.Pharmacy ]},
        { path: '/pharmacyOrder/:id', element: <PharmacyOrderPage />, audience: [ AccountType.Pharmacy ]},
        { path: '/create/pharmacyOrder', element: <CreateEditPharmacyOrder />, audience: [ AccountType.Pharmacy ]},
        { path: '/edit/pharmacyOrder/:id', element: <CreateEditPharmacyOrder />, audience: [ AccountType.Pharmacy ]},

        { path: "/epidemiology/dashboard", element: <EpidemiologyDashboardPage />, audience: [ AccountType.PublicHealthAgency] },
        { path: "/agency/settings", element: <PublicHealthAgencySettingsPage />, audience: [ AccountType.PublicHealthAgency] },
        { path: '/publicHealthAgencies', element: <PublicHealthAgencyAssociationPage />, audience: [ AccountType.Sharer ] },

        { path: '/publicdataapproval', element: <PublicDataApprovalPage />, audience: [ AccountType.Admin] },
        { path: '/isitbroken/configuration', element: <IsItBrokenConfigurationPage />, audience: [ AccountType.Admin] },
        { path: '/feedback', element: <FeedbackListPage />, audience: [ AccountType.Admin ]},

        { path: '/storage/cloud', element: <ConnectCloudStoragePage />, audience: [ AccountType.Sharer ]},
        { path: '/storage/dropbox/connected', element: <DropboxAuthorizedPage />, audience: [ AccountType.Sharer ]},

        ...ToolsRoutes(features, onNewAuthenticationResult, onLogOut),
    ];
    if(features.enableTaskList) {
        const taskListRoutes: ShehrdRouteDefinition[] = [
            { path: "/create/todolistitem", element: <CreateEditTodoListItemPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
            { path: "/edit/todolistitem/:id", element: <CreateEditTodoListItemPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
            { path: "/task/:taskId", element: <TaskPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
            { path: "/taskpackages", element: <TasksPackagesPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
            { path: "/create/taskpackage", element: <CreateEditTasksPackagePage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
            { path: "/edit/taskpackage/:id", element: <CreateEditTasksPackagePage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
            { path: "/taskpackages/deploy", element: <DeployTasksPackagePage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
            { path: "/taskpackages/deploy/:tasksPackageId", element: <DeployTasksPackagePage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},

            { path: "/print/tasks", element: <PrintTaskListPage />, usesCustomLayout: true, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},

            ...ChecklistRoutes().map(route => ({
                ...route,
                audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Pharmacy, AccountType.Admin ]
            }) as ShehrdRouteDefinition)
        ];
        routes.push(...taskListRoutes);
    }
    if(features.healthRecord.enableQuestionnaires) {
        const questionnaireRoutes: ShehrdRouteDefinition[] = [
            { path: '/questionnaires', element: <QuestionnairesListPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
            { path: '/create/questionnaire', element: <CreateEditQuestionnairePage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ] },
            { path: '/edit/questionnaire/:id', element: <CreateEditQuestionnairePage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ] },
        ];
        routes.push(...questionnaireRoutes);
    }
    if(features.enableServices) {
        // const serviceRoutes: ShehrdRouteDefinition[] = [
        //     { path: '/services', element: <ServicesListPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Pharmacy ]},
        //     { path: '/create/service', element: <CreateEditServicePage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Pharmacy ]},
        //     { path: '/edit/service/:id', element: <CreateEditServicePage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Pharmacy ]},
        //     { path: '/servicerequests', element: <ServiceRequestsListPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Pharmacy ]},
        //     { path: '/create/servicerequest', element: <CreateEditServiceRequestPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Pharmacy ]},
        //     { path: '/edit/servicerequest/:id', element: <CreateEditServiceRequestPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Pharmacy ]},
        // ];
        // routes.push(...serviceRoutes);
    }

    return routes;
}
export default RegisteredUserRoutes;