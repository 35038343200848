import { createSlice } from "@reduxjs/toolkit";
import { Models } from "../../../localComponents/types/models";
import { DrugsFilter } from "../../types/frontendTypes";
import { RemoteState } from "../../types/reduxInterfaces";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../helpers/DefaultReducers";
import { createRestApiActions } from "../helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../helpers/QueryBuilders";
import { postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { areFiltersEqual } from "../../helpers/FilterHelpers";

export interface DrugsState extends RemoteState<Models.Medication.Drug, DrugsFilter> {
}
const initialState: DrugsState = {
    ...defaultRemoteInitialState(),
}
export const drugsSlice = createSlice({
    name: 'drugs',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});
const drugFilterComparer = (f1?: DrugsFilter, f2?: DrugsFilter) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.searchText === f2!.searchText;
}
const drugQueryBuilder = (state: RootState, sliceState: DrugsState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, drugFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.searchText) {
            queryParams.push({ key: 'searchText', value: filter.searchText });
        }
    }
    return queryParams;
}
export const drugsActions = {
    ...createRestApiActions(
        'drugs',
        drugsSlice.actions,
        state => state.drugs,
        drugQueryBuilder,
        drugFilterComparer
    ),
    storeInstruction: postActionBuilder(
        (args: { itemId: string}) => `api/drugs/${args.itemId}/instructions`,
        () => resolveText("MedicationAdministrationInstructions_CouldNotStore"),
        drugsSlice.actions.setIsSubmitting,
        (dispatch,response,_) => dispatch(drugsSlice.actions.addOrUpdateItem(response))
    ),
};
export const drugsSelectors = {

};