import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DiagnosticTestScaleType, HealthRecordEntryType } from "../../../localComponents/types/enums";
import { Models } from "../../../localComponents/types/models";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { loadObject } from "../../../sharedCommonComponents/helpers/LoadingHelpers";
import { areClassificationReferencesEqual } from "../../helpers/ClassificationReferenceHelpers";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { HealthRecordEventFilter } from "../../types/frontendTypes";
import { PersonDataRemoteState, PersonDataArgs } from "../../types/reduxInterfaces";
import { ApiGetActionCreator } from "../../types/reduxTypes";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";


interface TestResultsState extends PersonDataRemoteState<
    ViewModels.HealthRecordEntries.IDiagnosticTestResultViewModel,
    HealthRecordEventFilter
> {
    summary?: ViewModels.HealthRecordEntries.TestResultsSummaryViewModel
}
const initialState: TestResultsState = {
    ...defaultRemoteInitialState(),
};

export const testResultsSlice = createSlice({
    name: "testResults",
    initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState),
        setTestResultsSummary: (state, action: PayloadAction<ViewModels.HealthRecordEntries.TestResultsSummaryViewModel>) => {
            state.summary = action.payload;
        },
        removeItem: (state, action: PayloadAction<string>) => {
            const deletedId = action.payload;
            const removedItem = state.items.find(x => x.id === deletedId);
            if(removedItem) {
                state.items = state.items.filter(x => x.id !== deletedId);
            }
            if(state.summary)
            {
                const matchingGroup = removedItem
                    ? state.summary.codeGroups.find(x => areClassificationReferencesEqual(x.code, removedItem.code))
                    : state.summary.codeGroups.find(x => x.lastTestResult.id === deletedId);
                if(matchingGroup) {
                    matchingGroup.valueCount--;
                    matchingGroup.lastTestResult = {
                        ...matchingGroup.lastTestResult,
                        scaleType: DiagnosticTestScaleType.Freetext,
                        text: resolveText("DiagnosticTestResult_Deleted_LoadForValue")
                    } as ViewModels.HealthRecordEntries.FreetextDiagnosticTestResultViewModel;
                    if(matchingGroup.valueCount === 0) {
                        state.summary.codeGroups = state.summary.codeGroups.filter(x => !areClassificationReferencesEqual(x.code, matchingGroup.code));
                    }
                }
            }
        },
        markItemAsSeen: (state, action: PayloadAction<string>) => {
            const id = action.payload
            const matchingItem = state.items.find(x => x.id === id);
            if(matchingItem) {
                matchingItem.hasBeenSeenBySharer = true;
            }
            if(state.summary) {
                const testResult = state.summary.codeGroups.find(x => x.lastTestResult.id === id)?.lastTestResult;
                if(testResult) {
                    testResult.hasBeenSeenBySharer = true;
                }
            }
        }
    }
});

export interface LoadTestResultHistoryArgs {
    personId: string;
    code: Models.ClassificationReference;
}
export const testResultsActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.TestResult,
        'testResults', 
        testResultsSlice.actions, 
        state => state.testResults,
        healthRecordEntryQueryBuilder,
        areHealthRecordEntryFiltersEqual
    ),
    loadTestResultsSummary: (({ args, onSuccess, onFailure }) => {
        return async (dispatch) => {
            dispatch(testResultsSlice.actions.setIsLoading(true));
            await loadObject<ViewModels.HealthRecordEntries.TestResultsSummaryViewModel>(
                `api/persons/${args!.personId}/testResults/summary`, {},
                resolveText("TestResultsSummary_CouldNotLoad"),
                summary => {
                    dispatch(testResultsSlice.actions.setTestResultsSummary(summary))
                    if(onSuccess) {
                        onSuccess(summary);
                    }
                },
                async () => {
                    if(onFailure) {
                        onFailure();
                    }
                },
                () => dispatch(testResultsSlice.actions.setIsLoading(false))
            );
        }
    }) as ApiGetActionCreator<PersonDataArgs, ViewModels.HealthRecordEntries.TestResultsSummaryViewModel>,
    loadTestResultHistory: (({ args, onSuccess, onFailure }) => {
        return async (dispatch) => {
            dispatch(testResultsSlice.actions.setIsLoading(true));
            await loadObject(
                `api/persons/${args!.personId}/testResults/history/${args!.code.classification}/${args!.code.conceptId}`, 
                {},
                resolveText("TestResults_CouldNotLoad"),
                (items: ViewModels.HealthRecordEntries.IDiagnosticTestResultViewModel[]) => {
                    dispatch(testResultsSlice.actions.addOrUpdateItems(items));
                    if(onSuccess) {
                        onSuccess(items);
                    }
                },
                async () => {
                    if(onFailure) {
                        onFailure();
                    }
                },
                () => {
                    dispatch(testResultsSlice.actions.setIsLoading(false))
                }
            );
        }
    }) as ApiGetActionCreator<LoadTestResultHistoryArgs, Models.DiagnosticTests.DiagnosticTestResult[]>,
    markItemAsSeen: postActionBuilder(
        (itemId: string) => `api/testResults/${itemId}/seen`, 
        () => resolveText("HealthRecordEntry_CouldNotMarkAsSeen"),
        testResultsSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(testResultsSlice.actions.markItemAsSeen(args))
    )
};
export const testResultsSelectors = {
    ...createHealthRecordEntrySelectors(state => state.testResults),
    createSelectSummaryForPersonIfAvailable: () => createSelector(
        (state: RootState) => state.testResults.summary,
        (_: RootState, args: { personId: string }) => args.personId,
        (summary, personId) => summary?.personId === personId ? summary : undefined
    )
};
