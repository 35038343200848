import { HealthRecordEntryType } from "../../localComponents/types/enums";
import { Models } from "../../localComponents/types/models";
import { ViewModels } from "../../localComponents/types/viewModels";
import { resolveText } from "../../sharedCommonComponents/helpers/Globalizer";
import { loadObject } from "../../sharedCommonComponents/helpers/LoadingHelpers";
import { getControllerName } from "./HealthRecordEntryHelpers";

export const loadRelatedEntries = async (
    entryType: HealthRecordEntryType, 
    entryId: string,
    setRelatedEntries: (relatedEntries: ViewModels.HealthRecordEntries.IHealthRecordEntryViewModelUnknownType[]) => void,
    setIsLoading: (isLoading: boolean) => void) => {
    const controllerName = getControllerName(entryType);
    setIsLoading(true);
    await loadObject(
        `api/${controllerName}/${entryId}/related`, {},
        resolveText("RelatedEntries_CouldNotLoad"),
        setRelatedEntries,
        undefined,
        () => setIsLoading(false)
    );
}
export const isHealthRecordEntryReferenceEqual = (r1: Models.HealthRecordEntryReference, r2: Models.HealthRecordEntryReference) => {
    return r1?.entryType === r2?.entryType && r1?.entryId === r2?.entryId;
}