import { Modal } from "react-bootstrap";
import { ChangePasswordForm } from "../components/UserManagement/ChangePasswordForm";

interface PasswordChangeModalProps {
    loginId: string;
    onPasswordChanged: () => void;
}

export const PasswordChangeModal = (props: PasswordChangeModalProps) => {

    const { loginId, onPasswordChanged } = props;

    return (
    <Modal show={true} backdrop="static">
        <Modal.Header>
            <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ChangePasswordForm
                loginId={loginId}
                onPasswordChanged={onPasswordChanged}
            />
        </Modal.Body>
    </Modal>);

}