import { createSlice } from "@reduxjs/toolkit";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { postActionBuilder } from "../../../sharedHealthComponents/redux/helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { Models } from "../../../localComponents/types/models";

export interface TasksPackagesState extends RemoteState<Models.Collaboration.TasksPackage,{}> {

}
const initialState: TasksPackagesState = {
    ...defaultRemoteInitialState(),
}
export const taskPackagesSlice = createSlice({
    name: 'tasksPackages',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

export interface DeployTasksPackageArguments {
    packageId: string;
}
export const tasksPackagesActions = {
    ...createRestApiActions(
        'tasksPackages',
        taskPackagesSlice.actions,
        state => state.tasksPackages,
        defaultQueryBuilder,
        _ => true
    ),
    deployForPatient: postActionBuilder(
        (args: DeployTasksPackageArguments) => `api/tasksPackages/${args.packageId}/deploy`,
        () => resolveText("TasksPackage_CouldNotDeploy"),
        taskPackagesSlice.actions.setIsSubmitting,
        _ => {}
    ),
};
export const tasksPackagesSelectors = {

};