import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "flatpickr/dist/themes/material_green.css";

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import healthRecordStore from './localComponents/redux/store/healthRecordStore';
import { initializeApp } from './localComponents/helpers/Startup';
import { App } from './App';


initializeApp();

const root = createRoot(document.getElementById('root')!);
root.render(
    <React.StrictMode>
        <Provider store={healthRecordStore}>
            <Router>
                <App />
            </Router>
        </Provider>
    </React.StrictMode>
);
