import { createSlice } from "@reduxjs/toolkit";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { Models } from "../../types/models";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";

interface ShippingMethodsState extends RemoteState<Models.Billing.ShippingMethod, {}> {}
const initialState: ShippingMethodsState = {
    ...defaultRemoteInitialState(),
}
export const shippingMethodsSlice = createSlice({
    name: 'shippingMethods',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

export const shippingMethodsActions = {
    ...createRestApiActions(
        'shippingMethods',
        shippingMethodsSlice.actions,
        state => state.shipppingMethods,
        defaultQueryBuilder,
        areFiltersEqual
    ),
};
export const shippingMethodsSelectors = {

};