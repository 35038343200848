import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RemoteState } from "../../types/reduxInterfaces";
import { AutocompleteCacheItem, AutocompleteItemsFilter } from "../../types/frontendTypes";
import { createDefaultGenericItemReducers } from "../helpers/DefaultReducers";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createRestApiActions } from "../helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../helpers/QueryBuilders";
import { areFiltersEqual } from "../../helpers/FilterHelpers";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";

interface AutocompleteItemsState extends RemoteState<AutocompleteCacheItem,AutocompleteItemsFilter> {
}
const initialState: AutocompleteItemsState = {
    ...defaultRemoteInitialState()
}
export const autocompleteItemsSlice = createSlice({
    name: 'autocompleteItems',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
        approveAll: (state) => {
            for (const item of state.items) {
                item.isApproved = true;
            }
        },
        unapproveAll: (state) => {
            for (const item of state.items) {
                item.isApproved = false;
            }
        },
        approve: (state, action: PayloadAction<string>) => {
            const matchingItem = state.items.find(x => x.id === action.payload);
            if(matchingItem) {
                matchingItem.isApproved = true;
            }
        },
        unapprove: (state, action: PayloadAction<string>) => {
            const matchingItem = state.items.find(x => x.id === action.payload);
            if(matchingItem) {
                matchingItem.isApproved = false;
            }
        },
    }
});

export const autocompleteItemQueryBuilder = (state: RootState, sliceState: AutocompleteItemsState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, autocompleteItemFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.searchText) {
            queryParams.push({ key: 'searchText', value: filter.searchText });
        }
        if(filter.isApproved !== undefined) {
            queryParams.push({ key: 'isApproved', value: filter.isApproved + '' });
        }
    }
    return queryParams;
}
export const autocompleteItemFilterComparer = (f1?: AutocompleteItemsFilter, f2?: AutocompleteItemsFilter) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.searchText === f2!.searchText
        && f1!.isApproved === f2!.isApproved;
}

export const autocompleteItemsActions = {
    ...createRestApiActions(
        'autocomplete',
        autocompleteItemsSlice.actions,
        state => state.autocompleteItems,
        autocompleteItemQueryBuilder,
        autocompleteItemFilterComparer
    ),
    approveAll: postActionBuilder(
        _ => 'api/autocomplete/approve-all',
        () => resolveText("AutocompleteCacheItems_CouldNotApproveAll"),
        autocompleteItemsSlice.actions.setIsSubmitting,
        (dispatch,response,args) => dispatch(autocompleteItemsSlice.actions.approveAll())
    ),
    unapproveAll: postActionBuilder(
        _ => 'api/autocomplete/unapprove-all',
        () => resolveText("AutocompleteCacheItems_CouldNotApproveAll"),
        autocompleteItemsSlice.actions.setIsSubmitting,
        (dispatch,response,args) => dispatch(autocompleteItemsSlice.actions.approveAll())
    ),
    approve: postActionBuilder(
        (args: string) => `api/autocomplete/${args}/approve`,
        () => resolveText("AutocompleteCacheItem_CouldNotApprove"),
        autocompleteItemsSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(autocompleteItemsSlice.actions.approve(args))
    ),
    unapprove: postActionBuilder(
        (args: string) => `api/autocomplete/${args}/unapprove`,
        () => resolveText("AutocompleteCacheItem_CouldNotUnapprove"),
        autocompleteItemsSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(autocompleteItemsSlice.actions.unapprove(args))
    ),
};
export const autocompleteItemsSelectors = {

};