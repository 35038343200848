import { useContext } from 'react';
import { Nav, NavDropdown, NavItem, NavLink } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import UserContext from '../../contexts/UserContext';
import { getPerson } from '../../helpers/UserHelpers';

interface SharerMenuProps {}

export const SharerMenu = (props: SharerMenuProps) => {

    const user = useContext(UserContext)!;
    const personId = getPerson(user)!.id;
    const navigate = useNavigate();
    return (
        <Nav>
            <NavItem>
                <NavLink onClick={() => navigate(`/healthrecord/${personId}`)}>{resolveText("Menu_MyData")}</NavLink>
            </NavItem>
            <NavDropdown title={resolveText("Menu_HealthServices")}>
                <NavDropdown.Item onClick={() => navigate(`/appointments`)}>{resolveText("Menu_Appointments")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/create/appointment`)}>{resolveText("Menu_NewAppointment")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/encounters`)}>{resolveText("Menu_EncounterHistory")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/institutions`)}>{resolveText("Menu_FindInstitution")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/practioners`)}>{resolveText("Menu_FindPractitioner")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/publicHealthAgencies`)}>{resolveText("Menu_MyPublicHealthAgencies")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/tools/chat`)}>{resolveText("TranslatedChat")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={resolveText("Menu_Permissions")}>
                <NavDropdown.Item onClick={() => navigate(`/sharedaccess`)}>{resolveText("Menu_SharedAccess")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/representations`)}>{resolveText("Menu_MyRepresentations")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/accesslog`)}>{resolveText("Menu_AccessLog")}</NavDropdown.Item>
            </NavDropdown>
            <NavItem>
                <NavLink onClick={() => navigate("/studies")}>{resolveText("Menu_Studies")}</NavLink>
            </NavItem>
            <NavDropdown title={resolveText("Menu_Config")}>
                <NavDropdown.Item onClick={() => navigate('/testpackages')}>{resolveText("DiagnosticTestsPackages")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/questionnaires')}>{resolveText("Questionnaires")}</NavDropdown.Item>
            </NavDropdown>
        </Nav>
    );

}