import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";

interface PublicHealthAgencyMenuProps {}

export const PublicHealthAgencyMenu = (props: PublicHealthAgencyMenuProps) => {

    const navigate = useNavigate();

    return (
    <Nav>
        <Nav.Item>
            <Nav.Link onClick={() => navigate("/agency/settings")}>{resolveText("PublicHealthAgency")}</Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link onClick={() => navigate("/epidemiology/dashboard")}>{resolveText("Epidemiology")}</Nav.Link>
        </Nav.Item>
    </Nav>);

}