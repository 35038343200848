import { createSlice } from "@reduxjs/toolkit";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { Models } from "../../types/models";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";

interface VouchersState extends RemoteState<Models.Billing.Voucher, {}> {}
const initialState: VouchersState = {
    ...defaultRemoteInitialState(),
}
export const vouchersSlice = createSlice({
    name: 'vouchers',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

export const vouchersActions = {
    ...createRestApiActions(
        'vouchers',
        vouchersSlice.actions,
        state => state.vouchers,
        defaultQueryBuilder,
        areFiltersEqual
    )
};
export const vouchersSelectors = {

};