import { HealthRecordEventFilter } from "../types/frontendTypes";

export const areFiltersEqual = (f1: any, f2: any) => {
    if(!f1 || !f2) {
        if(!f1 && !f2) {
            return true;
        }
        return false;
    }
    return true;
}
export const areHealthRecordEntryFiltersEqual = (f1: HealthRecordEventFilter | undefined, f2: HealthRecordEventFilter | undefined) => {
    if(!areFiltersEqual(f1,f2)) {
        return false;
    }
    if((!f1!.timeRange && f2!.timeRange) || (f1!.timeRange && !f2!.timeRange)) {
        return false;
    }
    if(f1!.timeRange && f2!.timeRange) {
        if(f1!.timeRange.start !== f2!.timeRange.start) {
            return false;
        }
        if(f1!.timeRange.end !== f2!.timeRange.end) {
            return false;
        }
    }
    return true;
}