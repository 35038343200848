import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HealthRecordEntryType, MedicationDispensionState } from "../../../localComponents/types/enums";
import { Models } from "../../../localComponents/types/models";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { uuid } from "../../../sharedCommonComponents/helpers/uuid";
import { PersonDataRemoteState } from "../../types/reduxInterfaces";
import { AsyncActionCreator } from "../../types/reduxTypes";
import { postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { createDefaultPersonDataReducers } from "../helpers/DefaultReducers";
import { createPersonDataApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { HealthRecordEventFilter } from "../../types/frontendTypes";
import { showInfoAlert, showSuccessAlert } from "../../../sharedCommonComponents/helpers/AlertHelpers";
import { createPersonDataSelectors } from "../helpers/HealthRecordEntrySliceSelectors";

interface MedicationSchedulesState extends PersonDataRemoteState<Models.Medication.MedicationSchedule,HealthRecordEventFilter> {
}
export interface RemoveDispensionPayload {
    scheduleId: string;
    scheduleItemId: string;
    dispensionId: string;
}
export interface AddMedicationScheduleItemPayload {
    scheduleId?: string;
    scheduleItem: Models.Medication.MedicationScheduleItem;
}
export interface AddDispensionToMedicationSchedulePayload {
    scheduleId: string;
    scheduleItemId: string;
    dispension: Models.Medication.MedicationDispension;
}
export interface PauseMedicationPayload {
    scheduleId?: string;
    itemId: string;
}
export interface DispenseMedicationPayload {
    scheduleId: string;
    scheduleItemId: string;
    dispensionId: string;
    dispensionState: MedicationDispensionState;
}
export interface MoveDispensionBackToSchedulePayload {
    dispension: Models.Medication.MedicationDispension;
}

const initialState: MedicationSchedulesState = {
    ...defaultRemoteInitialState(),
}

export const medicationSchedulesSlice = createSlice({
    name: 'medicationSchedules',
    initialState,
    reducers: {
        ...createDefaultPersonDataReducers(initialState),
        setMedicationScheduleIsActive: (state, action: PayloadAction<string>) => {
            for (const item of state.items) {
                item.isActive = item.id === action.payload;
            }
        },
        addOrUpdateScheduleItem: (state, action: PayloadAction<AddMedicationScheduleItemPayload>) => {
            const matchingSchedule = action.payload.scheduleId
                ? state.items.find(x => x.id === action.payload.scheduleId)
                : state.items.find(x => x.isActive) 
                    ?? (state.items.length > 0 ? state.items[0] : undefined);
            if(matchingSchedule) {
                const newOrUpdatedScheduleItem = action.payload.scheduleItem;
                matchingSchedule.items = matchingSchedule.items.map(item => item.id === newOrUpdatedScheduleItem.id ? newOrUpdatedScheduleItem : item);
            }
        },
        pauseMedication: (state, action: PayloadAction<PauseMedicationPayload>) => {
            const matchingSchedule = action.payload.scheduleId 
                ? state.items.find(x => x.id === action.payload.scheduleId)
                : state.items.find(x => x.isActive);
            if(matchingSchedule) {
                const matchingItem = matchingSchedule.items.find(x => x.id === action.payload.itemId);
                if(matchingItem) {
                    matchingItem.isPaused = true;
                }
            }
        }
    }
});

export const medicationSchedulesActions = {
    ...createPersonDataApiActions(
        'medicationSchedules', 
        medicationSchedulesSlice.actions,
        state => state.medicationSchedules,
        _ => ({}),
        _ => true
    ),
    createNewMedicationSchedule: ((personId: string) => {
        return async (dispatch) => {
            showInfoAlert(resolveText('MedicationSchedule_Creating...'));
            const medicationSchedule: Models.Medication.MedicationSchedule = {
                id: uuid(),
                type: HealthRecordEntryType.MedicationSchedule,
                personId: personId,
                timestamp: new Date().toISOString() as any,
                hasBeenSeenBySharer: false,
                note: '',
                isPaused: false,
                isDispendedByPatient: false,
                isActive: true,
                items: []
            };
            dispatch(medicationSchedulesActions.addItem({
                args: medicationSchedule,
                body: medicationSchedule,
                onSuccess: () => {
                    showSuccessAlert(resolveText('MedicationSchedule_SuccessfullyStored'));
                }
            }));
        }
    }) as AsyncActionCreator,
    setMedicationScheduleIsActive: postActionBuilder(
        (scheduleId: string) => `api/medicationSchedules/${scheduleId}/active`,
        () => resolveText("MedicationSchedule_CouldNotSetActive"),
        medicationSchedulesSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(medicationSchedulesSlice.actions.setMedicationScheduleIsActive(args))
    ),
    pauseMedication: postActionBuilder(
        (args: PauseMedicationPayload) => `api/medicationSchedules/${args.scheduleId}/items/${args.itemId}/pause`,
        () => resolveText("Medication_CouldNotPause"),
        medicationSchedulesSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(medicationSchedulesSlice.actions.pauseMedication(args))
    )
};
export const medicationSchedulesSelectors = {
    ...createPersonDataSelectors(state => state.medicationSchedules),
};
