import { addDays, differenceInDays } from "date-fns";
import { Models } from "../../localComponents/types/models";

export const isPregnancyPersonId = (personId: string) => {
    return personId.includes('|');
}

export const getGestationAge = (pregnancy: Models.ObGyn.Pregnancy) => {
    if(!pregnancy.lastPeriod && !(pregnancy.conceptionDate && pregnancy.averageCycleLengthInDaysBeforeConception)) {
        return null;
    }
    const lastMenstruationDate = pregnancy.lastPeriod 
        ? new Date(pregnancy.lastPeriod) 
        : addDays(new Date(pregnancy.conceptionDate!), pregnancy.averageCycleLengthInDaysBeforeConception! / 2);
    const endDateOrNow = pregnancy.endDate ? new Date(pregnancy.endDate) : new Date();
    const gestationAgeInDays = differenceInDays(endDateOrNow, lastMenstruationDate);
    const weeks = Math.floor(gestationAgeInDays / 7);
    const days = gestationAgeInDays - weeks * 7;

    return `${weeks}+${days}`;
}