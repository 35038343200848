import { createSlice } from "@reduxjs/toolkit";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { InstitutionFilter } from "../../types/frontendTypes";
import { ViewModels } from "../../types/viewModels";
import { RootState } from "../store/healthRecordStore";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

interface InstitutionsState extends RemoteState<ViewModels.InstitutionViewModel, InstitutionFilter> {}
const initialState: InstitutionsState = {
    ...defaultRemoteInitialState(),
};
export const institutionsSlice = createSlice({
    name: 'institutions',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

export const institutionQueryBuilder = (state: RootState, sliceState: InstitutionsState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, institutionFilterComparer);
    const filter = sliceState.filter;
    if(filter.showOnlyMine) {
        queryParams.push({ key: 'showOnlyMine', value: 'true'});
    }
    return queryParams;
}
export const institutionFilterComparer = (f1?: InstitutionFilter, f2?: InstitutionFilter) => {
    if(!f1 && !f2) {
        return true;
    }
    if(!f1 || !f2) {
        return false;
    }
    return f1.showOnlyMine === f2.showOnlyMine;
}
export const institutionsActions = {
    ...createRestApiActions(
        'institutions',
        institutionsSlice.actions,
        state => state.institutions,
        institutionQueryBuilder,
        institutionFilterComparer
    ),
};
export const institutionsSelectors = {
    ...createDefaultGenericItemSelectors(state => state.institutions),
};