import { createSlice } from "@reduxjs/toolkit";
import { HealthRecordEntryType } from "../../../localComponents/types/enums";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { HealthRecordEventFilter } from "../../types/frontendTypes";
import { PersonDataRemoteState } from "../../types/reduxInterfaces";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";

export interface ImmunizationsState extends PersonDataRemoteState<ViewModels.HealthRecordEntries.ImmunizationViewModel,HealthRecordEventFilter> {
}

const initialState: ImmunizationsState = {
    ...defaultRemoteInitialState(),
}

export const immunizationsSlice = createSlice({
    name: 'immunizations',
    initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState),
    }
});

export const immunizationsActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.Immunization,
        'immunizations', 
        immunizationsSlice.actions, 
        state => state.immunizations,
        healthRecordEntryQueryBuilder,
        areHealthRecordEntryFiltersEqual
    ),
};
export const immunizationsSelectors = {
    ...createHealthRecordEntrySelectors(state => state.immunizations),
};