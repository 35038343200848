import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RemoteState } from "../../types/reduxInterfaces";
import { SpecimenMeasurementOrderFilter } from "../../types/frontendTypes";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../helpers/DefaultReducers";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { loadItemsActionBuilder, postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { areFiltersEqual } from "../../helpers/FilterHelpers";
import { defaultQueryBuilder } from "../helpers/QueryBuilders";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { createRestApiActions } from "../helpers/GenericSliceActions";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { Models } from "../../../localComponents/types/models";

interface SpecimenMeasurementOrdersState extends RemoteState<ViewModels.SpecimenMeasurementOrderViewModel, SpecimenMeasurementOrderFilter> {

}
const initialState: SpecimenMeasurementOrdersState = {
    ...defaultRemoteInitialState(),
}
export interface UpdateSpecimenMeasurementPayload {
    measurementOrderId: string;
    measurement?: Models.DiagnosticTests.DiagnosticTestResult;
}
export const specimenMeasurementOrdersSlice = createSlice({
    name: 'specimenMeasurementOrders',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
        updateMeasurement: (state, action: PayloadAction<UpdateSpecimenMeasurementPayload>) => {
            const matchingItem = state.items.find(x => x.id === action.payload.measurementOrderId);
            if(matchingItem) {
                matchingItem.measurement = action.payload.measurement;
            }
        },
    }
});
const specimenMeasurementOrderFilterComparer = (f1?: SpecimenMeasurementOrderFilter, f2?: SpecimenMeasurementOrderFilter) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.specimenId === f2!.specimenId;
};
const specimenMeasurementOrderQueryBuilder = (state: RootState, sliceState: SpecimenMeasurementOrdersState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, specimenMeasurementOrderFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.specimenId) {
            queryParams.push({ key: 'specimenId', value: filter.specimenId });
        }
    }
    return queryParams;
};
export interface DeployTestPacakgeForSpecimenArgs {
    testPackageId: string;
    specimenId: string;
}
export const specimenMeasurementOrdersActions = {
    ...createRestApiActions(
        'specimenMeasurementOrders', 
        specimenMeasurementOrdersSlice.actions, 
        state => state.specimenMeasurementOrders,
        specimenMeasurementOrderQueryBuilder,
        specimenMeasurementOrderFilterComparer
    ),
    loadForSpecimen: loadItemsActionBuilder(
        (args: { specimenId: string}) => `api/specimens/${args.specimenId}/measurements`,
        specimenMeasurementOrderQueryBuilder,
        specimenMeasurementOrderFilterComparer,
        () => resolveText("SpecimenMeasurementOrders_CouldNotLoad"),
        specimenMeasurementOrdersSlice.actions.setIsLoading,
        specimenMeasurementOrdersSlice.actions.addOrUpdateItems,
        specimenMeasurementOrdersSlice.actions.addOrUpdateItems,
        specimenMeasurementOrdersSlice.actions.setHasMoreItems,
        specimenMeasurementOrdersSlice.actions.setLastUsedFilter,
        state => state.specimenMeasurementOrders
    ),
    deployTestPackage: postActionBuilder<DeployTestPacakgeForSpecimenArgs,unknown>(
        args => `api/specimens/${args.specimenId}/deploy-testpackage/${args.testPackageId}`,
        () => resolveText("Specimen_CouldNotDeployTestPackage"),
        specimenMeasurementOrdersSlice.actions.setIsSubmitting,
        (dispatch, response, _) => dispatch(specimenMeasurementOrdersSlice.actions.addOrUpdateItems(response))
    ),
    changeStatus: postActionBuilder(
        (args: { itemId: string }) => `api/specimenMeasurementOrders/${args.itemId}/changestate`,
        () => resolveText("SpecimenMeasurementOrder_CouldNotChangeStatus"),
        specimenMeasurementOrdersSlice.actions.setIsSubmitting,
        (dispatch, response, _) => dispatch(specimenMeasurementOrdersSlice.actions.addOrUpdateItem(response))
    ),
};
export const specimenMeasurementOrdersSelectors = {
    createSelectForSpecimen: () => createSelector(
        (state: RootState) => state.specimenMeasurementOrders.items,
        (_: RootState, args: { specimenId: string | undefined }) => args.specimenId,
        (orders, specimenId) => specimenId 
            ? orders.filter(x => x.specimenId === specimenId)
            : []
    ),
};