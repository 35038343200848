import { createSlice } from "@reduxjs/toolkit";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { Models } from "../../types/models";

export interface UploadCodesState extends RemoteState<Models.UploadCode,{}> {
}
const initialState: UploadCodesState = {
    ...defaultRemoteInitialState(),
}
export const uploadCodesSlice = createSlice({
    name: 'uploadCodes',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
})
export const uploadCodesActions = {
    ...createRestApiActions(
        'uploadCodes',
        uploadCodesSlice.actions,
        state => state.uploadCodes,
        defaultQueryBuilder,
        _ => true
    ),
};
export const uploadCodesSelectors = {

};