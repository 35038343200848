import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouteDefinition } from "../../sharedCommonComponents/types/frontendTypes";
import { Models } from "../types/models";

const IsItBrokenHomePage = lazy(() => import("../pages/IsItBroken/IsItBrokenHomePage"));
const IsItBrokenCaseListPage = lazy(() => import("../pages/IsItBroken/IsItBrokenCaseListPage"));
const IsItBrokenAnswerPage = lazy(() => import("../pages/IsItBroken/IsItBrokenAnswerPage"));
const IsItBrokenActivationPage = lazy(() => import("../pages/IsItBroken/IsItBrokenActivationPage"));
const IsItBrokenGenerationPage = lazy(() => import("../pages/IsItBroken/IsItBrokenGenerationPage"));
const CprPage = lazy(() => import('../../sharedHealthComponents/pages/Patients/CprPage'));
const CreateTranslatedChatPage = lazy(() => import("../../sharedHealthComponents/pages/Chat/CreateTranslatedChatPage"));
const TranslatedChatPage = lazy(() => import("../../sharedHealthComponents/pages/Chat/TranslatedChatPage"));
const JoinTranslatedChatPage = lazy(() => import("../../sharedHealthComponents/pages/Chat/JoinTranslatedChatPage"));

export const ToolsRoutes = (
    features: Models.Configuration.FeatureSettings, 
    onNewAuthenticationResult: (authenticationResult: Models.AccessControl.AuthenticationResult) => void,
    onLogOut: () => Promise<void>) => {

    const routes: RouteDefinition[] = [
        { path: '/tools/cpr', element: <CprPage />, usesCustomLayout: true },
        { path: '/tools/chat', element: <CreateTranslatedChatPage onNewAuthenticationResult={onNewAuthenticationResult} />},
        { path: '/tools/chat/:chatId', element: <TranslatedChatPage onLogOut={onLogOut} />},
        { path: '/tools/chat/:chatId/join', element: <JoinTranslatedChatPage onNewAuthenticationResult={onNewAuthenticationResult} />},

        { path: '/isitbroken', element: <IsItBrokenHomePage /> },
        { path: '/isitbroken/cases', element: <IsItBrokenCaseListPage /> },
        { path: '/isitbroken/cases/:id/answer', element: <IsItBrokenAnswerPage /> },
        { path: '/isitbroken/cases/:id/activate', element: <IsItBrokenActivationPage /> },
        { path: '/isitbroken/generate', element: <IsItBrokenGenerationPage /> },
    ];
    if(features.enableTaskList) {
        routes.push({ path: '/tools/checklists', element: <Navigate to='/checklists' /> });
    }
    return routes;

}
export default ToolsRoutes;