import { Nav, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";

interface PharmacyMenuProps {}

export const PharmacyMenu = (props: PharmacyMenuProps) => {

    const navigate = useNavigate();
    return (
        <Nav>
            <NavDropdown title={resolveText("Menu_Pharmacy")}>
                <NavDropdown.Item onClick={() => navigate(`/create/pharmacyOrder`)}>{resolveText("Menu_NewPharmacyOrder")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/pharmacyOrders`)}>{resolveText("Menu_PharmacyOrders")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/drugs`)}>{resolveText("Menu_Drugs")}</NavDropdown.Item>
            </NavDropdown>
        </Nav>
    );

}
export default PharmacyMenu;