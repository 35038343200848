import { createSlice } from "@reduxjs/toolkit";
import { Models } from "../../../localComponents/types/models";
import { RemoteState } from "../../types/reduxInterfaces";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../helpers/DefaultReducers";
import { createRestApiActions } from "../helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../helpers/QueryBuilders";
import { createDefaultGenericItemSelectors } from "../helpers/GenericSliceSelectors";

export interface QuestionnairesState extends RemoteState<Models.Interview.Questionnaire, {}> {
}

const initialState: QuestionnairesState = {
    ...defaultRemoteInitialState(),
}
export const questionnairesSlice = createSlice({
    name: 'questionnaires',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

export const questionnairesActions = {
    ...createRestApiActions(
        'questionnaires',
        questionnairesSlice.actions,
        state => state.questionnaires,
        defaultQueryBuilder,
        _ => true
    ),
};
export const questionnaireSelectors = {
    ...createDefaultGenericItemSelectors(state => state.questionnaires),
};