import { createSlice } from "@reduxjs/toolkit";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { ViewModels } from "../../types/viewModels";
import { OrderFilter } from "../../types/frontendTypes";
import { RootState } from "../store/healthRecordStore";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { postActionBuilder } from "../../../sharedHealthComponents/redux/helpers/ActionCreatorBuilder";
import { OrderStatus } from "../../types/enums";
import { Models } from "../../types/models";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

export interface OrdersState extends RemoteState<ViewModels.OrderViewModel, OrderFilter> {}

const initialState: OrdersState = {
    ...defaultRemoteInitialState(),
}
export const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

const ordersQueryBuilder = (state: RootState, sliceState: OrdersState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, orderFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.status) {
            queryParams.push({ key: 'orderStatus', value: filter.status });
        }
        if(filter.accountId) {
            queryParams.push({ key: 'accountId', value: filter.accountId });
        }
    }
    return queryParams;
}
const orderFilterComparer = (f1: OrderFilter | undefined, f2: OrderFilter | undefined) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.status === f2!.status
        && f1!.accountId === f2!.accountId;
}

export interface SetOrderStatusArgs {
    orderId: string;
    newStatus: OrderStatus;
    note?: string;
}
export const ordersActions = {
    ...createRestApiActions(
        'orders',
        ordersSlice.actions,
        state => state.orders,
        ordersQueryBuilder,
        orderFilterComparer
    ),
    setStatus: postActionBuilder<SetOrderStatusArgs,Models.RequestBodies.OrderStatusChangeBody>(
        args => `api/orders/${args.orderId}/status`,
        () => resolveText("Order_CouldNotChangeStatus"),
        ordersSlice.actions.setIsSubmitting,
        (dispatch,response,_) => dispatch(ordersSlice.actions.addOrUpdateItem(response))
    )
};
export const ordersSelectors = {
    ...createDefaultGenericItemSelectors(state => state.orders),
};