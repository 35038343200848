import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { uuid } from "../../../sharedCommonComponents/helpers/uuid";
import { Cpr } from "../../types/cprTypes";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";

export const initialState: Cpr.CprState = {
    cprEventId: uuid(),
    isCprInProgress: false,
    startTime: undefined,
    startOfCurrentNoPulseInterval: undefined,
    startOfCurrentCprInterval: undefined,
    intervalsWithoutPulse: [],
    cprCycles: [],
    shocks: [],
    events: [],
    suggestions: [],
    completedItems: []
};
export const cprSlice = createSlice({
    name: 'cpr',
    initialState: initialState,
    reducers: {
        reset: () => {
            return initialState;
        },
        setState: (state, action: PayloadAction<Cpr.CprState>) => {
            return action.payload;
        }
    }
});
export const cprActions = {

};
export interface SelectCprByProcedureAndPersonArgs {
    medicalProcedureId?: string;
    personId?: string;
};
export const cprSelectors = {
    createSelectByProcedureAndPersonOrDefault: () => createSelector(
        (state: RootState) => state.cpr,
        (_: RootState, args: SelectCprByProcedureAndPersonArgs) => args.medicalProcedureId,
        (_: RootState, args: SelectCprByProcedureAndPersonArgs) => args.personId,
        (cpr, medicalProcedureId, personId) => cpr.medicalProcedureId === medicalProcedureId && cpr.personId === personId
            ? cpr
            : initialState
    ),
};