import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { Models } from "../../types/models";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { RootState } from "../store/healthRecordStore";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { IsItBrokenCaseFilter } from "../../types/frontendTypes";
import { OrderDirection } from "../../types/enums";
import { postActionBuilder } from "../../../sharedHealthComponents/redux/helpers/ActionCreatorBuilder";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";

export interface IsItBrokenCaseState extends RemoteState<Models.IsItBroken.IsItBrokenCase, IsItBrokenCaseFilter> {}
const initialState: IsItBrokenCaseState = {
    ...defaultRemoteInitialState(),
}
export const isItBrokenCasesSlice = createSlice({
    name: 'isItBrokenCases',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
        markAsSeen: (state, action: PayloadAction<string>) => {
            const matchingItem = state.items.find(x => x.id === action.payload);
            if(matchingItem) {
                matchingItem.hasBeenSeen = true;
            }
        }
    }
});

const isItBrokenFilterComparer = (f1?: IsItBrokenCaseFilter, f2?: IsItBrokenCaseFilter) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return f1?.timeRange?.start === f2?.timeRange?.start
        && f1?.timeRange?.end === f2?.timeRange?.end;
}
const isItBrokenQueryBuilder = (state: RootState, sliceState: IsItBrokenCaseState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, isItBrokenFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.timeRange) {
            if(filter.timeRange.start) {
                queryParams.push({ key: 'timeRangeStart', value: filter.timeRange.start as any });
            }
            if(filter.timeRange.end) {
                queryParams.push({ key: 'timeRangeEnd', value: filter.timeRange.end as any });
            }
        }
        if(filter.hasBeenSeen !== undefined) {
            queryParams.push({ key: 'hasBeenSeen', value: filter.hasBeenSeen + '' });
        }
    }

    // Sorting
    queryParams.push({ key: 'orderBy', value: 'time' });
    queryParams.push({ key: 'orderDirection', value: OrderDirection.Descending });

    return queryParams;
}
export const isItBrokenCasesActions = {
    ...createRestApiActions(
        'isItBroken/cases',
        isItBrokenCasesSlice.actions,
        state => state.isItBrokenCases,
        isItBrokenQueryBuilder,
        isItBrokenFilterComparer
    ),
    markAsSeen: postActionBuilder(
        (args?: { caseId: string }) => `api/isitbroken/cases/${args!.caseId}/seen`,
        () => resolveText("IsItBrokenCase_CouldNotMarkAsSeen"),
        isItBrokenCasesSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(isItBrokenCasesSlice.actions.markAsSeen(args!.caseId))
    ),
};

export const isItBrokenCasesSelectors = {
    ...createDefaultGenericItemSelectors(state => state.isItBrokenCases),
};