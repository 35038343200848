import { createSlice } from "@reduxjs/toolkit";
import { HealthRecordEntryType } from "../../../localComponents/types/enums";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { HealthRecordEventFilter } from "../../types/frontendTypes";
import { PersonDataRemoteState } from "../../types/reduxInterfaces";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";

interface SpecimenState extends PersonDataRemoteState<ViewModels.HealthRecordEntries.SpecimenViewModel,HealthRecordEventFilter> {}
const initialState: SpecimenState = {
    ...defaultRemoteInitialState(),
};
export const specimensSlice = createSlice({
    name: 'specimen',
    initialState: initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState),
    }
});
export const specimensActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.Specimen,
        'specimens', 
        specimensSlice.actions, 
        state => state.specimens,
        healthRecordEntryQueryBuilder,
        areHealthRecordEntryFiltersEqual
    ),
};
export const specimensSelectors = {
    ...createHealthRecordEntrySelectors(state => state.specimens),
};