import { createSlice } from "@reduxjs/toolkit";
import { Models } from "../../../localComponents/types/models";
import { RemoteState } from "../../types/reduxInterfaces";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../helpers/DefaultReducers";
import { createRestApiActions } from "../helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../helpers/QueryBuilders";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { areFiltersEqual } from "../../helpers/FilterHelpers";

export interface TagsState extends RemoteState<Models.Tag, {}> {}
const initialState: TagsState = {
    ...defaultRemoteInitialState(),
}
export const tagsSlice = createSlice({
    name: 'tags',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});
export const tagsFilterComparer = (f1?: {}, f2?: {}) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return true;
};
export const tagsQueryBuilder = (state: RootState, sliceState: TagsState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, tagsFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        // TODO
    }
    return queryParams;
}
export const tagsActions = {
    ...createRestApiActions(
        'tags',
        tagsSlice.actions,
        state => state.tags,
        tagsQueryBuilder,
        tagsFilterComparer
    ),
};
export const tagsSelectors = {

};