import { createSlice } from "@reduxjs/toolkit";
import { createDefaultGenericItemReducers } from "../helpers/DefaultReducers";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { RemoteState } from "../../types/reduxInterfaces";
import { Models } from "../../../localComponents/types/models";
import { createRestApiActions } from "../helpers/GenericSliceActions";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { defaultQueryBuilder } from "../helpers/QueryBuilders";
import { areFiltersEqual } from "../../helpers/FilterHelpers";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { loadObject } from "../../../sharedCommonComponents/helpers/LoadingHelpers";
import { ApiGetActionCreator } from "../../types/reduxTypes";
import { PrintTemplateFilter } from "../../types/frontendTypes";
import { createDefaultGenericItemSelectors } from "../helpers/GenericSliceSelectors";

export interface PrintTemplatesState extends RemoteState<Models.PrintTemplate,PrintTemplateFilter> {}

const initialState: PrintTemplatesState = {
    ...defaultRemoteInitialState(),
}
export const printTemplatesSlice = createSlice({
    name: 'printTemplates',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

const printTemplatesQueryBuilder = (state: RootState, sliceState: PrintTemplatesState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, printTemplatesFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.searchText) {
            queryParams.push({ key: 'searchText', value: filter.searchText });
        }
    }
    return queryParams;
}
const printTemplatesFilterComparer = (f1?: PrintTemplateFilter, f2?: PrintTemplateFilter) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.searchText === f2!.searchText;
}
export const printTemplatesActions = {
    ...createRestApiActions(
        'printTemplates',
        printTemplatesSlice.actions,
        state => state.printTemplates,
        printTemplatesQueryBuilder,
        printTemplatesFilterComparer
    ),
    loadAll: (({ args, onSuccess, onFailure, onFinally }) => {
        return async (dispatch, getState) => {
            dispatch(printTemplatesSlice.actions.setIsLoading(true));
            await loadObject<Models.PrintTemplate[]>(
                'api/printTemplates', {},
                resolveText("PrintTemplates_CouldNotLoad"),
                items => {
                    dispatch(printTemplatesSlice.actions.setItems(items));
                    if(onSuccess) {
                        onSuccess(items);
                    }
                },
                async _ => {
                    if(onFailure) {
                        onFailure();
                    }
                },
                () => {
                    dispatch(printTemplatesSlice.actions.setIsLoading(false));
                    if(onFinally) {
                        onFinally();
                    }
                }
            );
        }
    }) as ApiGetActionCreator<any,Models.PrintTemplate[]>
};
export const printTemplatesSelectors = {
    ...createDefaultGenericItemSelectors(state => state.printTemplates),
};