import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { HealthRecordEntryType, PregnancyStatus } from "../../../localComponents/types/enums";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { HealthRecordEventFilter } from "../../types/frontendTypes";
import { PersonDataRemoteState } from "../../types/reduxInterfaces";
import { postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";

export interface PregnanciesFilter extends HealthRecordEventFilter {
    status?: PregnancyStatus;
    fatherPersonId?: string;
}
export interface PregnanciesState extends PersonDataRemoteState<ViewModels.HealthRecordEntries.PregnancyViewModel, PregnanciesFilter> {
}
const initialState: PregnanciesState = {
    ...defaultRemoteInitialState(),
}
export const pregnanciesSlice = createSlice({
    name: 'pregnancies',
    initialState: initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState),
    }
})

export const pregnanciesQueryBuilder = (state: RootState, sliceState: PregnanciesState) => {
    const queryParams = healthRecordEntryQueryBuilder(state, sliceState, pregnanciesFilterComparer);
    const filter = sliceState.filter;
    if(filter.status) {
        queryParams.push({ key: 'status', value: filter.status });
    }
    if(filter.fatherPersonId) {
        queryParams.push({ key: 'fatherPersonId', value: filter.fatherPersonId });
    }
    return queryParams;
}
const pregnanciesFilterComparer = (f1: PregnanciesFilter | undefined, f2: PregnanciesFilter | undefined) => {
    if(!areHealthRecordEntryFiltersEqual(f1,f2)) {
        return false;
    }
    return f1?.status === f2?.status
        && f1?.fatherPersonId === f2?.fatherPersonId;
}

export const pregnanciesActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.Pregnancy,
        'pregnancies',
        pregnanciesSlice.actions,
        state => state.pregnancies,
        pregnanciesQueryBuilder,
        pregnanciesFilterComparer
    ),
    markAsBorn: postActionBuilder(
        (pregnancyId: string) => `api/pregnancies/${pregnancyId}/born`,
        () => resolveText("Pregnancy_CouldNotMarkAsBorn"),
        pregnanciesSlice.actions.setIsSubmitting,
        (dispatch,response,_) => dispatch(pregnanciesSlice.actions.addOrUpdateItem(response))
    ),
    markAsLost: postActionBuilder(
        (pregnancyId: string) => `api/pregnancies/${pregnancyId}/lost`,
        () => resolveText("Pregnancy_CouldNotMarkAsLost"),
        pregnanciesSlice.actions.setIsSubmitting,
        (dispatch,response,_) => dispatch(pregnanciesSlice.actions.addOrUpdateItem(response))
    ),
};
export const pregnanciesSelectors = {
    ...createHealthRecordEntrySelectors(state => state.pregnancies),
};