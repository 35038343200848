import { PayloadAction } from "@reduxjs/toolkit";
import { Models } from "../../../localComponents/types/models";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { PersonDataArgs, PersonDataRemoteState, RemoteState, SliceStateBase } from "../../types/reduxInterfaces";

export const createDefaultReducers =  (initialState: SliceStateBase) => ({
    setIsLoading: (state: SliceStateBase, action: PayloadAction<boolean>) => {
        state.isLoading = action.payload;
    },
    setIsSubmitting: (state: SliceStateBase, action: PayloadAction<boolean>) => {
        state.isSubmitting = action.payload;
    },
    clearState: (state: SliceStateBase) => {
        state = initialState;
    }
});
export const createDefaultGenericItemReducers = <ItemType extends Models.IId, FilterType>(initialState: RemoteState<ItemType,FilterType>) => ({
    ...createDefaultReducers(initialState),
    setItems: (state: RemoteState<ItemType,FilterType>, action: PayloadAction<ItemType[]>) => {
        state.items = action.payload;
    },
    setHasMoreItems: (state: RemoteState<ItemType,FilterType>, action: PayloadAction<boolean>) => {
        state.hasMoreItems = action.payload;
    },
    patchFilter: (state: RemoteState<ItemType,FilterType>, action: PayloadAction<Partial<FilterType>>) => {
        state.filter = {
            ...state.filter,
            ...action.payload
        };
    },
    setLastUsedFilter: (state: RemoteState<ItemType,FilterType>, action: PayloadAction<FilterType | undefined>) => {
        state.lastUsedFilter = action.payload;
    },
    addOrUpdateItem: (state: RemoteState<ItemType,FilterType>, action: PayloadAction<ItemType>) => {
        const item = action.payload;
        if(state.items.some(x => x.id === item.id)) {
            state.items = state.items.map(x => x.id === item.id ? item : x);
        } else {
            state.items.push(item);
        }
    },
    addOrUpdateItems: (state: RemoteState<ItemType,FilterType>, action: PayloadAction<ItemType[]>) => {
        const items = action.payload;
        const newItems = items.filter(item => !state.items.some(existingItem => existingItem.id === item.id));
        const existingUpdatedItems = state.items.map(existingItem => {
            const updatedItem = items.find(x => x.id === existingItem.id);
            return updatedItem ?? existingItem;
        });
        state.items = existingUpdatedItems.concat(newItems);
    },
    removeItem: (state: RemoteState<ItemType,FilterType>, action: PayloadAction<string>) => {
        state.items = state.items.filter(x => x.id !== action.payload);
    }
});
export const createDefaultPersonDataReducers = <T extends Models.IPersonData,FilterType>(initialState: PersonDataRemoteState<T,FilterType>) => ({
    ...createDefaultGenericItemReducers(initialState),
    setCurrentPersonId: (state: PersonDataRemoteState<T,FilterType>, action: PayloadAction<string>) => {
        if(state.currentPersonId === action.payload) {
            return;
        }
        state.currentPersonId = action.payload;
        state.items = state.items.filter(x => x.personId === action.payload);
    },
    setItems: (state: PersonDataRemoteState<T,FilterType>, action: PayloadAction<T[]>) => {
        if(!state.currentPersonId) {
            if(action.payload.length === 0) {
                state.items = action.payload;
                return;
            }
            state.currentPersonId = action.payload[0].personId;
        }
        state.items = action.payload.filter(x => x.personId === state.currentPersonId);
    },
    addOrUpdateItem: (state: PersonDataRemoteState<T,FilterType>, action: PayloadAction<T>) => {
        const item = action.payload;
        if(state.currentPersonId && item.personId !== state.currentPersonId) {
            return;
        }
        if(!state.currentPersonId) {
            state.currentPersonId = item.personId;
            state.items = state.items.filter(x => x.personId === state.currentPersonId);
        }
        if(state.items.some(x => x.id === item.id)) {
            state.items = state.items.map(x => x.id === item.id ? item : x);
        } else {
            state.items.push(item);
        }
    },
    addOrUpdateItems: (state: PersonDataRemoteState<T,FilterType>, action: PayloadAction<T[]>) => {
        const items = action.payload;
        if(!state.currentPersonId) {
            if(items.length === 0) {
                state.items = items;
                return;
            }
            state.currentPersonId = items[0].personId;
        }
        const personItems = items.filter(item => item.personId === state.currentPersonId);
        const newItems = personItems.filter(item => !state.items.some(existingItem => existingItem.id === item.id));
        const existingUpdatedItems = state.items.map(existingItem => {
            const updatedItem = personItems.find(x => x.id === existingItem.id);
            return updatedItem ?? existingItem;
        });
        state.items = existingUpdatedItems.concat(newItems);
    }
});
export const createDefaultHealthRecordEntryReducers = <
    T extends ViewModels.HealthRecordEntries.IHealthRecordEntryViewModelUnknownType,
    FilterType
>(
    initialState: PersonDataRemoteState<T,FilterType>
) => ({
    ...createDefaultPersonDataReducers(initialState),
    markItemAsSeen: (state: PersonDataRemoteState<T,FilterType>, action: PayloadAction<string>) => {
        const matchingItem = state.items.find(x => x.id === action.payload);
        if(matchingItem) {
            matchingItem.hasBeenSeenBySharer = true;
        }
    },
    markAllAsSeen: (state: PersonDataRemoteState<T,FilterType>, action: PayloadAction<PersonDataArgs>) => {
        for (const item of state.items) {
            if(item.personId === action.payload.personId) {
                item.hasBeenSeenBySharer = true;
            }
        }
    }
});
