import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClassificationType } from "../../../localComponents/types/enums";
import { Models } from "../../../localComponents/types/models";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { LoincCodes, UmlsCodes } from "../../helpers/ClassificationReferenceHelpers";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { HealthRecordEventFilter } from "../../types/frontendTypes";
import { PersonDataArgs, PersonDataRemoteState } from "../../types/reduxInterfaces";
import { loadItemActionBuilder, loadPersonDataActionBuilder, postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";

interface HealthRecordsState extends PersonDataRemoteState<ViewModels.HealthRecordEntries.IHealthRecordEntryViewModelUnknownType,HealthRecordEventFilter> {
    viewSettings: Models.Settings.PersonalHealthRecordViewSettings;
    selectedEntry?: Models.HealthRecordEntryReference;
};
const initialState: HealthRecordsState = {
    ...defaultRemoteInitialState(),
    filter: {
        timeRange: {}
    },
    viewSettings: {
        id: '',
        accountId: '',
        observationLoincCodes: [ 
            LoincCodes.HeartRate, 
            LoincCodes.BloodPressureSystolic, 
            LoincCodes.BloodPressureDiastolic,
            LoincCodes.OxygenSaturation,
            LoincCodes.Temperature
        ].map(conceptId => ({
            classification: ClassificationType.Loinc,
            conceptId: conceptId,
            displayName: '',
        })),
        additionalInformationCodes: [
            UmlsCodes.Occupation
        ].map(conceptId => ({
            classification: ClassificationType.Umls,
            conceptId: conceptId,
            displayName: ''
        }))
    }
};

export const healthRecordsSlice = createSlice({
    name: 'healthRecords',
    initialState: initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState),
        setViewSettings: (state, action: PayloadAction<Models.Settings.PersonalHealthRecordViewSettings>) => {
            state.viewSettings = action.payload;
        },
        setSelectedEntry: (state, action: PayloadAction<Models.HealthRecordEntryReference | undefined>) => {
            state.selectedEntry = action.payload;
        },
        removeItemOfType: (state, action: PayloadAction<Models.HealthRecordEntryReference>) => {
            state.items = state.items.filter(x => !(x.type === action.payload.entryType && x.id === action.payload.entryId));
        }
    }
});

export interface LoadHealthRecordEntriesArgs {
    personId: string;
    before: Date;
    count: number;
}
export const healthRecordEntrySliceActions = {
    loadItems: loadPersonDataActionBuilder(
        args => `api/persons/${args!.personId}/healthrecordentries`,
        healthRecordEntryQueryBuilder,
        areHealthRecordEntryFiltersEqual,
        () => resolveText("HealthRecordEntries_CouldNotLoad"),
        healthRecordsSlice.actions.setCurrentPersonId,
        healthRecordsSlice.actions.setIsLoading,
        healthRecordsSlice.actions.setItems,
        healthRecordsSlice.actions.addOrUpdateItems,
        healthRecordsSlice.actions.setHasMoreItems,
        healthRecordsSlice.actions.setLastUsedFilter,
        state => state.healthRecords
    ),
    loadHealthRecordViewSettings: loadItemActionBuilder(
        _ => `api/settings/healthrecord`,
        _ => ({}),
        () => resolveText("Settings_CouldNotLoad"),
        healthRecordsSlice.actions.setIsLoading,
        healthRecordsSlice.actions.setViewSettings
    ),
    storeHealthRecordViewSettings: postActionBuilder(
        _ => `api/settings/healthrecord`,
        () => resolveText("Settings_CouldNotStore"),
        healthRecordsSlice.actions.setIsSubmitting,
        (dispatch,response) => dispatch(healthRecordsSlice.actions.setViewSettings(response))
    ),
    markAllAsSeen: postActionBuilder<PersonDataArgs,unknown>(
        args => `api/persons/${args.personId}/healthrecord/mark-all-as-seen`,
        () => resolveText("HealthRecordEntries_CouldNotMarkAsSeen"),
        healthRecordsSlice.actions.setIsSubmitting,
        (dispatch,_,args) => {
            // Cannot call xxxxSlice.actions.markAllAsSeenHere because of circular reference. 
            // Currently handling the dispatch calls in MarkAllEntriesAsSeenButton.tsx
        }
    ),
};
export interface GetHealthRecordEntriesForPersonArgs {
    personId: string;
    now?: Date;
}
export const healthRecordEntrySliceSelectors = {
    getForPerson: () => createSelector(
        (state: RootState) => state.healthRecords.items,
        (_: RootState, args: GetHealthRecordEntriesForPersonArgs) => args.personId,
        (_: RootState, args: GetHealthRecordEntriesForPersonArgs) => args.now,
        (items, personId, now) => {
            const personEvents = items.filter(x => x.personId === personId);
            if(now) {
                return personEvents.filter(x => new Date(x.timestamp) <= now);
            }
            return personEvents;
        }
    ),
    
};